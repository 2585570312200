import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';

import { CommonModule } from '@angular/common';
import { ClientListComponent } from './client-list.component';
import { ClientListFilterComponent } from './client-list-filter.component';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [ClientListComponent, ClientListFilterComponent],
  imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      /* Custom modules */
      AngularMaterialModule,
      /* Otros */
      FlexLayoutModule,
      RouterModule,
      SharedModule
  ],
  exports: [
      ClientListComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})
export class ClientListModule { }
