import * as countries from 'i18n-iso-countries';

import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user.service';

declare const require: (arg0: string) => countries.LocaleData;

export class LangFlag {

  flag: string;
  lang: string;
  fullLang: string;
  locale: string;

  constructor(flag: string, lang: string, fullLang: string, locale: string) {
    this.flag = flag;
    this.lang = lang;
    this.fullLang = fullLang;
    this.locale = locale;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  readonly defaultLang = 'es';
  readonly defaultFlag = 'es';
  readonly defaultLocale = 'es-ES';

  configuredLangs: LangFlag[] = [];

  currentLang: string;
  currentLocale = 'es';

  private langSubject = new BehaviorSubject<string>(this.defaultLang);
  private flagSubject = new BehaviorSubject<string>(this.defaultFlag);
  private localeSubject = new BehaviorSubject<string>(this.currentLocale);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public lang$ = this.langSubject.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public flag$ = this.flagSubject.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public locale$ = this.localeSubject.asObservable();

  constructor(private translate: TranslateService, private userService: UserService) {
    translate.setDefaultLang('none');

    const lang = localStorage.getItem('lang') || this.defaultLang;

    this.updateLangs();

    translate.use(lang);
    this.changeLang(lang);

    this.configureCountries();
  }

  public changeLang(lang: string): void {
    if (lang == null) {
      return;
    }

    const configuredLang: LangFlag = this.configuredLangs.find(l => l.lang === lang);

    this.langSubject.next(lang);
    this.translate.use(lang);
    this.currentLang = lang;
    this.userService.currentLang = lang;
    localStorage.setItem('lang', lang);

    if (configuredLang != null) {
      this.flagSubject.next(configuredLang.flag);
      this.localeSubject.next(configuredLang.locale);
      this.currentLocale = configuredLang.locale;
    }
  }

  public translateCountry(iso: string, lang = this.currentLang): string {
    if (lang == null) {
      lang = this.currentLang;
    }

    return countries.getName(iso, lang);
  }

  public updateLangs(): void {
    this.configuredLangs = [];

    this.configuredLangs.push(new LangFlag('es', 'es', 'Español', 'es-ES'));
   // this.configuredLangs.push(new LangFlag('gb', 'en', 'English', 'en-GB'));
  }

  private configureCountries() {
    this.configuredLangs.map(l => l.lang).forEach(l => countries.registerLocale(require(`i18n-iso-countries/langs/${l}.json`)));
  }

}
