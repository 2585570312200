import { GenericFilter } from "./genericClass";

export class Provider {
    id: number;
    name: string;
}
export class SupplierFilter extends GenericFilter{
    id: number;
    name: string;

}