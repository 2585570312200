<mat-toolbar [color]="isLoggedIn ? 'accent' : ''">

  <mat-toolbar-row>
    <div fxFlex="40%" fxLayout fxLayoutGap="20px" fxLayoutAlign="flex-start">
      <button mat-stroked-button (click)="toggleDrawer()" *ngIf="isLoggedIn" class="btn-sidebar-toggle">
        <mat-icon class="icon">menu</mat-icon>
        <span class="text mayusculas">{{'common.menu' | translate}}</span>
      </button>
    </div>

    <a mat-list-item routerLink="/" fxFlex="20%" fxLayout fxLayoutGap="20px" fxLayoutAlign="center"
      class="logo-header-container">
    </a>

    <div fxFlex="40%" fxLayout fxLayoutAlign="flex-end" class="main-menu">

      <ul fxLayout>

        <li *ngIf="isLoggedIn">
          <button mat-menu-item [ngClass]="!isLoggedIn ? 'login' : ''" [routerLink]="'/notifications'"
            [ngStyle]="{'margin-right':getUnreadNotifications() ? '12px' : '0' }">
            <mat-icon fxHide.gt-sm [matBadge]="getUnreadNotifications()" matBadgeColor="accent">
              notifications
            </mat-icon>
            <div fxHide.lt-md [matBadge]="getUnreadNotifications()" matBadgeColor="accent">
              {{'menu.notifications' | translate}}</div>
          </button>
        </li>

        <li *ngIf="isLoggedIn">
          <button mat-menu-item name="myMenu" [ngClass]="!isLoggedIn ? 'login' : ''" [matMenuTriggerFor]="auth">
            <mat-icon fxHide.gt-sm [ngStyle]="{'margin-left':getUnreadNotifications() ? '8px' : '16px' }">perm_identity
            </mat-icon>
            <div fxHide.lt-md>{{ currentUser.fullName }}
              <mat-icon role="img" class="mat-icon notranslate material-icons" aria-hidden="true">arrow_drop_down
              </mat-icon>
            </div>
          </button>

          <mat-menu #auth="matMenu">
            <button mat-menu-item [routerLink]="'/perfil'">
              <mat-icon>account_circle</mat-icon>
              <span>{{'menu.profile' | translate}}</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item name="logoutButton" (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>{{'signout' | translate}}</span>
            </button>
          </mat-menu>
        </li>

        <li>
          <button mat-menu-item [ngClass]="!isLoggedIn ? 'login' : ''" [matMenuTriggerFor]="lang"
            *ngIf="showChangeLangIcon()">
            <mat-icon class="mat-icon" fxHide.gt-sm>language</mat-icon>
            <div fxHide.lt-md>
              LANGUAGE
              <mat-icon role="img" class="mat-icon notranslate material-icons" aria-hidden="true">arrow_drop_down
              </mat-icon>
            </div>
          </button>
          <mat-menu #lang="matMenu">
            <div *ngFor="let item of langs">
              <button mat-menu-item *ngIf="(translationService.lang$ | async) != item.lang"
                (click)="changeLang(item.lang)">
                <mat-icon svgIcon="{{item.flag}}"></mat-icon>
                <span>{{item.fullLang}}</span>
              </button>
            </div>
          </mat-menu>
        </li>

      </ul>

    </div>

  </mat-toolbar-row>
</mat-toolbar>