<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'quoteEdit.general.dietTravel.label' | translate }}</mat-label>
          <mat-select required name="idDiet"
              [(ngModel)]="data.dietTravel">
              <mat-option *ngFor="let diet of dietTravels" [value]="diet">
                  {{ diet.traduction | translate }}
              </mat-option>
          </mat-select>
          <mat-error>
              {{ 'quoteEdit.dialog.area.name.error' | translate }}
          </mat-error>
      </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%" >
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.diet.amount" name="amount" type="number"
            placeholder="{{ 'quoteEdit.dialog.dietTravel.amount.label' | translate }}">
        </mat-form-field>
      </div>
    </div>



  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>