import { GenericFilter } from "./genericClass";
import { ProviderQuote } from "./providerQuote";
import { StandarConsultancy } from "./standarConsultancy";
import { Test } from "./test";

export class Equipment {

    id: number;
    idQuote: number;
    idApplication: number;
    idCountEssayCriteria: number;
    // Contaje Rest
    countTestRest: Test;
    // Contaje Working
    countTestWorking: Test;

    idType: number;

    inventoryNumber: string;
    maker: string;
    model: string;
    
    serialNumber: string;
    location: string;

    totalDiffusers: number;
    totalFilters: number;
    totalMicro: number;
    
    outsourced: boolean = false;
    cdContaje: string;

    tests: Test[] = [];
    qualifications: StandarConsultancy[] = [];

    provider: ProviderQuote;

    idCountEssayCriteriaWorking: number;
}

export class EquipmentType {

    id: number;
    translate: string;
    idApplication: number;
    
} 
export class EquipmentTypeFilter extends GenericFilter{
    id: number;
    translate: string;
    

}
export enum EquipmentTypeEnum {

    CLIMATIZADOR = 1,
    CPP_FLUJOS = 2,
    CABINAS = 3,
    EXTRAC_TALLADO = 4,
    AISLADORES = 5
} 