import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CalibratesRange } from '../model/calibratesRange';

@Injectable()
export class CalibratesRangeService {

    currentLang: string;

    private calibratesSubject = new BehaviorSubject<CalibratesRange>(new CalibratesRange());
    private unreadSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public course$ = this.calibratesSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public unread$ = this.unreadSubject.asObservable();

    constructor(private http: HttpClient) {
    }


   
    findAll(): Observable<any> {
        const url = environment.quotit + '/calibratesRanges/';

        return this.http.get<any>(url).pipe(
            map(httpRes => httpRes)
        );
    }

    findOne(id: number): Observable<any> {
        const url = environment.quotit + `/calibratesRanges/${id}`;

        return this.http.get<any>(url).pipe(
            map(httpRes => httpRes)
        );
    }

    findAllByVariable(id: number): Observable<any> {
        const url = environment.quotit + `/calibratesRanges/variable/${id}`;

        return this.http.get<any>(url).pipe(
            map(httpRes => httpRes)
        );
    }

    findAllByVariableAndEquipment(id: number, idEq: number, idUnitVar): Observable<any> {
        const url = environment.quotit + `/calibratesRanges/variable/${id}/${idEq}/${idUnitVar}`;

        return this.http.get<any>(url).pipe(
            map(httpRes => httpRes)
        );
    }
}