import { GenericFilter } from "./genericClass";

export class commercial {
    id: number;
    name: string;
}

export class ComercialFilter extends GenericFilter {
    name: string;
}
