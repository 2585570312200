import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { UserListComponent } from './user-list.component';
import { UserListFilterComponent } from './user-list-filter.component';

@NgModule({
    declarations: [UserListComponent, UserListFilterComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        /* Custom modules */
        AngularMaterialModule,
        /* Otros */
        FlexLayoutModule,
        RouterModule,
        SharedModule
    ],
    exports: [
        UserListComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})
export class UserListModule { }
