import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';

import { CourseDataSource } from 'src/app/model/courseDataSource';
import { Course, CourseFilter } from 'src/app/model/course';
import { CourseListFilterComponent } from './course-list-filter.component';
import { CourseService } from 'src/app/service/course.service';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller, Location } from '@angular/common';
import { UserService } from 'src/app/service/user.service';
import { User } from 'src/app/model/user';

export interface DialogDataCourseListConfig {
  isList: boolean;
}

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html'
})
export class CourseListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('filterComponent', { static: true }) filterComponent: CourseListFilterComponent;
  currentUser: User;
  dataSource: CourseDataSource;
  filter: CourseFilter = new CourseFilter();
  hasFiltered = false;
  displayedColumns = ['name', 'edit', 'delete', 'download'];
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private courseService: CourseService,
    private location: Location,
    private translate: TranslateService,
    private userService: UserService,
    private viewportScroller: ViewportScroller,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCourseListConfig,
    public dialogRef: MatDialogRef<CourseListComponent>   
  ) {
    this.router.events.pipe(filter(e => e instanceof Scroll)).pipe(takeUntil(this.destroy$)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  get pageTitle(): string {
    return this.translate.instant('protocolList.title') as string;
  }
  
  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    if (this.data.isList) {
      this.displayedColumns.push('download', 'edit', 'delete');
    } else {
      this.filterComponent.isList = this.data.isList;
    }
    this.loadCourseList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadCourseList(): void {
    this.dataSource = new CourseDataSource(this.courseService);

    this.filter.sortBy = this.sort.active || 'name';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator?.pageIndex || 0;
    this.filter.pageSize = this.paginator?.pageSize || 10;

    if (this.hasFiltered) {
      this.dataSource.loadProtocols(this.filter);
    }
  }

  new(): void {
    void this.router.navigateByUrl('/course?id=0');
  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`/course?id=${id}`);
  }

  deleteRow(id: number): void {}

  doFilter(event: CourseFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadCourseList();
  }

  canDelete(course: Course): boolean {
    return true;
  }

  cancel(): void {
    this.location.back();
  }
}
