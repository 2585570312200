import { ProviderQuote } from "./providerQuote";
import { StandarConsultancy } from "./standarConsultancy";
import { Test } from "./test";

export class CompresedGas {
    id: number;
    name: string;
    location: string;
    idArea: number;
    gasPoints: string;
    order: number;

    outsourced: boolean;
    provider: ProviderQuote;
    tests: Test[] = [];
    qualifications: StandarConsultancy[] = [];
    
    // Contaje Rest
    countTestRest: Test;
     // Contaje Working
    countTestWorking: Test;
    idCountEssayCriteria: number;
    idCountEssayCriteriaWorking: number;
}