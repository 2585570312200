import { Injectable } from '@angular/core';

@Injectable()
export class Constants {

    public static FIELD_ID = 'id';

    public static FIELD_USERNAME = 'username';

    public static QUOTE_ID = 'id';  
    public static PROFILE_TYPE_CLOSED_PRICE = 13;
    public static ID_ROOM = 1;
    public static ID_GAS = 1;
    public static ID_TYPE_REST = 26;

    public static ID_TYPE_WORKING = 27;
    public static ID_USER = 'id';
    public static USER_USERNAME = 'username';

    // Quote types 
    public static QUOTE_TYPE_AIR = 1;
    public static QUOTE_TYPE_THERMAL = 2;
    public static QUOTE_TYPE_CALIBRATES = 3;
    public static QUOTE_TYPE_CONSULTANCY = 4;
    public static QUOTE_TYPE_MIXED = 5;
    public static QUOTE_TYPE_FORMATION = 6;

    // Quote id status 
    public static QUOTE_ID_STATUS_PREPARATION = 1;
    public static QUOTE_ID_STATUS_SIGNED = 2;
    public static QUOTE_ID_STATUS_SENT = 3;
    public static QUOTE_ID_STATUS_WON = 4;
    public static QUOTE_ID_STATUS_LOST = 5;
    public static QUOTE_ID_STATUS_ARCHIVED = 6;

    // Quote status 
    public static QUOTE_STATUS_PREPARATION = 'preparation';
    public static QUOTE_STATUS_SIGNED = 'signed';
    public static QUOTE_STATUS_SENT = 'sent';
    public static QUOTE_STATUS_WON = 'won';
    public static QUOTE_STATUS_LOST = 'lost';
    public static QUOTE_STATUS_ARCHIVED = 'archived';

    //Quote services
    public static SERVICE_TYPE_AIR = 'air';
    public static SERVICE_TYPE_THERMAL = 'thermal';
    public static SERVICE_TYPE_CALIBRATES = 'calibrates';
    public static SERVICE_TYPE_CONSULTANCY = 'consultancy';
    public static SERVICE_TYPE_MIXED = 'mixed';
    public static SERVICE_TYPE_CONSULTORY = 'consultory';
    public static SERVICE_TYPE_FORMATION = 'formation';

    //Test Thermal
    public static TEST_THERMAL_TYPE_PROTOCOL = 1;
    public static TEST_THERMAL_TYPE_VACUUM = 14;
    public static TEST_THERMAL_TYPE_MAPPING_OF_T_IN_VACUUM = 3;
    public static TEST_THERMAL_TYPE_MAPPING_OF_T_AND_HR_IN_VACUUM = 4;
    public static TEST_THERMAL_TYPE_MAPPING_OF_T_HR_AND_CO2_IN_VACUUM = 5;
    public static TEST_THERMAL_TYPE_VACUUM_DISTRIBUTION_TEST=17;


    // Equipment Other
    public static EQUIPMENT_TYPE_OTHER = 78;
    // Equipment Texture_analyzer
    public static EQUIPMENT_TYPE_TEXTURE_ANALYZER = 84;

    public static EQUIPMENT_TYPE_PARTICLE_COUNTER = 46;
    public static DIET_TYPE_HOURS=8;

    public static GRADE_A='gradea';
    public static GRADE_B='gradeb';
    public static GRADE_C='gradec';
    public static GRADE_D='graded';
    public static ISO_5='iso5';
    public static ISO_6='iso6';
    public static ISO_7='iso7';
    public static ISO_8='iso8';
    public static ISO_9='iso9';
    public static INFORMATIVE='informative';

    public static TEST_TYPE_PARTICLE_COUNT_WORKING = 27;
    public static TEST_TYPE_PARTICLE_COUNT = 26;

    public static ID_TYPE_PARTICLE_GAS = 30;

    public static QUOTE_ALREADY_EXISTS = "Error Quote already exists";
}

export enum ROLE_USERS {
    CONSULTANCY = "consultancy",
    COMMERCIAL = "commercial",
    ADMIN = "admin",
}

