import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Diet } from 'src/app/model/diet';
import { DietTravel, DietTravelFilter } from 'src/app/model/dietTravel';
import { DietTravelService } from 'src/app/service/dietTravel.service';

export interface DialogDataArea {
  diet: Diet;
  isEdit: boolean;
  dietTravel: DietTravel;
}

@Component({
  selector: 'app-quote-edit-dialog-diet',
  templateUrl: './quote-edit-dialog-diet.component.html'
})
export class QuoteEditDialogDietComponent {

  tituloDialog: string;
  dietTravels: DietTravel[];

  constructor(
    private dietTravelService: DietTravelService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<QuoteEditDialogDietComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataArea) {

    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.diet.title.edit', { name: data.diet.idDietTravel }) as string;
    } else {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.diet.title.create') as string;
    }
    let filter = new DietTravelFilter();

    this.dietTravelService.findAll(filter).subscribe((res: DietTravel[]) => {
      this.dietTravels = res;
      if (this.data != null && this.data.dietTravel != null && this.data.dietTravel.id != null) {
        this.dietTravels.forEach(element => {
          if (element.id === this.data.dietTravel.id) {
            this.data.dietTravel = element;
          }
        });
      }
    });
  }

  onOkClick(): void {
    const errs: string[] = [];

    this.data.diet.idDietTravel = this.data.dietTravel.id;
    if (this.data.diet.idDietTravel == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
    }
    if (this.data.diet.amount == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.diet);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
