import { BehaviorSubject, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CourseDifficulty } from '../model/courseDifficulty';

@Injectable()
export class CourseDifficultyService {

    private courseSubject = new BehaviorSubject<CourseDifficulty>(new CourseDifficulty());
    private unreadSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public course$ = this.courseSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public unread$ = this.unreadSubject.asObservable();

    constructor(private http: HttpClient) {
    }

    findAll(): Observable<any> {
        const url = environment.quotit + `/courseDifficulty/`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));

    }
}
