import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { QuoteService } from 'src/app/service/quote.service';
import { Constants } from 'src/app/utils/constants';
import { ValuDataDTO } from '../../model/valueDataDTO';
import { TranslateService } from '@ngx-translate/core';
import { Router, Scroll } from "@angular/router";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  statusWon = Constants.QUOTE_ID_STATUS_WON;
  statusSent = Constants.QUOTE_ID_STATUS_SENT;
  
  valueCountOffertsByStatus: number;
  valueCommercialCountWithOffertsByStatus: ValuDataDTO;
  valueCommercialTotalAmountWithOffertsByStatus: number;
  valueQuoteCountByDays: ValuDataDTO[];
  valueQuoteCountByMonths: ValuDataDTO[];
  valueSumOffertsByStatus: number;
  valueCountOffertsByStatusSent: number;
  valueCountOffertsByStatusWon: number;
  valueSumOffertsByStatusWon: number;
  valueCommercialCountWithOffertsLost: ValuDataDTO;
  valueQuoteCountByMonthCurrent: number;
  valueCriteriaAmount: number;
  constructor(private _quoteService: QuoteService, private translate: TranslateService,
    private router: Router) { }

  ngOnInit(): void {
    this.sumOffertsByStatus();
    this.commercialCountWithOffertsByStatus();
    this.quoteCountByDays();
    this.quoteCountByMonths();
    this.countOffertsByStatusSent();
    this.quoteCountByMonthsCurrent();
  }

  commercialCountWithOffertsByStatus() {
    this._quoteService.commercialCountWithOffertsByStatus(Constants.QUOTE_ID_STATUS_WON).subscribe((res: ValuDataDTO[]) => {
      if (res != null && res.length > 0) {
        this.valueCommercialCountWithOffertsByStatus = res[0];
      } else {
        this.valueCommercialCountWithOffertsByStatus = new ValuDataDTO();
        this.valueCommercialCountWithOffertsByStatus.arg = '';
        this.valueCommercialCountWithOffertsByStatus.val = 0;
      }
    });
  }

  commercialTotalAmountWithOffertsByStatus() {
    this._quoteService.commercialTotalAmountWithOffertsByStatus(Constants.QUOTE_ID_STATUS_WON).subscribe((res: number) => {
      this.valueCommercialTotalAmountWithOffertsByStatus = res;
    });
  }

  quoteCountByDays() {
    this._quoteService.quoteCountByDays(moment().year(), moment().month() +1).subscribe((res: ValuDataDTO[]) => {
      this.valueQuoteCountByDays = [];
        res.forEach(e => {
          e.arg = this.translate.instant(`quote.day.${e.arg?.trim()}`) as string;
          this.valueQuoteCountByDays.push(e);
        });
    });
  }

  quoteCountByMonths() {
    this._quoteService.quoteCountByMonths(moment().year()).subscribe((res: ValuDataDTO[]) => {
      if (res?.length > 0) {
        this.valueQuoteCountByMonths = [];
        res.forEach(e => {
          e.arg = this.translate.instant(`quote.month.${e.arg?.trim()}`) as string;
          this.valueQuoteCountByMonths.push(e);
        });
      }
    });
  }

  sumOffertsByStatus() {
    this._quoteService.sumOffertsByStatus(Constants.QUOTE_ID_STATUS_WON).subscribe((res: number) => {
      this.valueSumOffertsByStatusWon = res;

      this._quoteService.sumOffertsByStatus(Constants.QUOTE_ID_STATUS_SENT).subscribe((res1: number) => {
        this.valueSumOffertsByStatus = res1 + this.valueSumOffertsByStatusWon;

        if (this.valueSumOffertsByStatus > 0  && this.valueSumOffertsByStatus > 0) {
          this.valueCriteriaAmount = Math.abs(Math.round((this.valueSumOffertsByStatusWon / this.valueSumOffertsByStatus) * 100));
        }  else {
          this.valueCriteriaAmount = 0;
        }

      });
    });
  }

  countOffertsByStatusSent() {
    this._quoteService.countOffertsByStatus(Constants.QUOTE_ID_STATUS_WON).subscribe((res: number) => {
      this.valueCountOffertsByStatusWon = res;

      this._quoteService.countOffertsByStatus(Constants.QUOTE_ID_STATUS_SENT).subscribe((res1: number) => {
        this.valueCountOffertsByStatusSent = res1 + this.valueCountOffertsByStatusWon;
      });
    });
  }

  commercialCountWithOffertsLost() {
    this._quoteService.commercialCountWithOffertsByStatus(Constants.QUOTE_ID_STATUS_LOST).subscribe((res: ValuDataDTO) => {
      if (res != null) {
        this.valueCommercialCountWithOffertsLost = res;
      } else {
        this.valueCommercialCountWithOffertsLost = new ValuDataDTO();
        this.valueCommercialCountWithOffertsLost.arg = '';
        this.valueCommercialCountWithOffertsLost.val = 0;
      }
    });
  }

  quoteCountByMonthsCurrent() {
    this._quoteService.quoteCountByMonthsCurrent(moment().year(), moment().month() + 1).subscribe((res: number) => {
      this.valueQuoteCountByMonthCurrent = res;
    });
  }

  pointClickHandler(e) {
    this.toggleVisibility(e.target);
  }

  legendClickHandler(e) {
    const arg = e.target;
    const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

    this.toggleVisibility(item);
  }

  toggleVisibility(item) {
    if (item.isVisible()) {
      item.hide();
    } else {
      item.show();
    }
  }

  sendStatus(status: number): void {
    void this.router.navigateByUrl(`/quotes?idStatus=${status}`);
  }
}
