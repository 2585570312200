import { Severity, SnackbarMessage } from './model/snackbar';

import { Component } from '@angular/core';
import { SnackBarService } from './service/snackBar.service';
import { StringUtils } from './utils/stringUtils';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from './service/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'quotit';
  loading$ = this.loader.loading$;
  constructor(
    public loader: LoadingService,
    private snackBarService: SnackBarService,
    private toastr: ToastrService) {

      this.snackBarService.message$.subscribe((msg: SnackbarMessage) => {
        if (msg != null && StringUtils.isNotEmpty(msg.title)) {
          this.showSnack(msg);
        }
      });
  }

  showSnack(message: SnackbarMessage): void {

    switch (message.severity) {
      case Severity.SUCCESS:
        this.toastr.success(message.title, message.msg);
        break;
      case Severity.ERROR:
        console.error(message.title);
        this.toastr.error(message.title, message.msg);
        break;
      case Severity.WARNING:
        this.toastr.warning(message.title, message.msg);
        break;
      case Severity.INFO:
        this.toastr.info(message.title, message.msg);
        break;
    }

  }

}
