<form>
  <div class="container">
    <mat-form-field class="campoFormulario">
      <mat-label> {{'quoteEdit.general.consultory.profile'|
        translate}}
      </mat-label>
      <mat-select #consultancyTypeSelect required [(ngModel)]="data.standar.idType" name="standar">
        <mat-option *ngFor="let standarType of consultancyTypes" [value]="standarType.id">
          {{'consultancy.type.'+standarType.translate
          | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>