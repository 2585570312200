import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { CourseFilter } from 'src/app/model/course';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-course-list-filter',
  templateUrl: './course-list-filter.component.html'
})
export class CourseListFilterComponent implements OnInit {

  @Output() filterEmitter = new EventEmitter<CourseFilter>();

  filter: CourseFilter;
  form: FormGroup;
  isList: boolean;
  expandedFilter = true;
  
  private destroy$ = new Subject<void>();
  
  constructor(
    fb: FormBuilder
  ) {
    this.cleanFilter();

    this.form = fb.group({
      name: [this.filter.name]
    });
  }

  ngOnInit(): void {
  }

  cleanFilter(): void {
    this.filter = new CourseFilter();
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }
  
}
