import { CommonModule } from '@angular/common';
import { FixDate } from './fixDate.pipe';
import { FixDateTime } from './fixDateTime.pipe';
import { NgModule } from '@angular/core';
import { Safe } from './safe.pipe';

@NgModule({
  declarations: [Safe, FixDate, FixDateTime],
  imports: [
    CommonModule
  ],
  providers: [Safe, FixDate, FixDateTime],
  exports: [Safe, FixDate, FixDateTime]
})
export class PipesModule { }
