import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ComercialFilter } from 'src/app/model/commercial';

@Component({
  selector: 'app-comercial-list-filter',
  templateUrl: './comercial-list-filter.component.html'
})
export class ComercialListFilterComponent implements OnInit {

  @Output() filterEmitter = new EventEmitter<ComercialFilter>();

  filter: ComercialFilter;

  expandedFilter = true;

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute) {
    this.cleanFilter();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  cleanFilter(): void {
    this.filter = new ComercialFilter();
  }

}
