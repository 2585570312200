
<mat-dialog-content>
    <ng-container *ngIf="message">
      <p>{{ message }}</p>
    </ng-container>
    <div *ngIf="showEquipments">
        <div *ngFor="let wc of data.quote.workcenters" >
            <div *ngFor="let equipment of wc.equipmentThermal; let roomIndex = index">
                <!-- Fabricante -->
                <div fxFlex="50">
                    <mat-form-field class="campoFormulario">
                        <input matInput [(ngModel)]="equipment.equipment" 
                        type="text"
                            placeholder="{{ 'quoteEdit.dialog.equipmentThermal.equipment.label' | translate }}">
                    </mat-form-field>
                </div>
        
                <!-- Modelo -->
                <div fxFlex="20">
                    <mat-form-field class="campoFormulario">
                        <input matInput [(ngModel)]="equipment.intervention"
                         type="number" min="0"
                            placeholder="{{ 'quoteEdit.dialog.equipmentThermal.intervetion.label' | translate }}">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showMaxEquipments">
              <!-- Thermal Scope -->
              <div fxFlex="50">
                  <mat-form-field class="campoFormulario">
                      <input matInput type="number" [(ngModel)]="quote.workday"
                          placeholder="{{ 'quoteEdit.dialog.equipmentThermal.intervetion.scope.message' | translate }}">
                  </mat-form-field>
              </div>
          </div>

  </mat-dialog-content>
  
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="center">
    <button mat-raised-button color="primary" (click)="onOkClick()" *ngIf="!showSave">
      {{ 'quoteEdit.dialog.yes' | translate }}
    </button>
    <button mat-raised-button color="secondary" (click)="onNoClick()" *ngIf="!showSave">
      {{ 'quoteEdit.dialog.no' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="onSaveClick()" *ngIf="showSave && !showMaxEquipments">
        {{ 'button.save' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="onSaveMaxClick()" *ngIf="showMaxEquipments">
      {{ 'button.save' | translate }}
  </button>
    <button mat-raised-button color="secondary" (click)="onCancelClick()" *ngIf="showSave">
        {{ 'button.cancel' | translate }}
    </button>
          
  </mat-dialog-actions>