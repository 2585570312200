<form fxLayout="column">
  <div mat-dialog-content class="thermal-theme">
    <div fxLayout="row" class="flexwrap">
      <!-- Tipo de Equipo-->
      <div fxFlex="35" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.thermalProbe.type" name="type" type="text"
            placeholder="{{ 'quoteEdit.dialog.thermalProbe.type.label' | translate }}" />
        </mat-form-field>
      </div>

      <!-- Nº de Puntos De Calibración-->
      <div fxFlex="35" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.thermalProbe.points" name="points" type="text"
            placeholder="{{ 'quoteEdit.dialog.thermalProbe.points.label' | translate }}" />
        </mat-form-field>
      </div>

      <!-- Valores De Calibración-->
      <div fxFlex="30" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.thermalProbe.values" name="values" type="text"
            placeholder="{{ 'quoteEdit.dialog.thermalProbe.values.label' | translate }}" />
        </mat-form-field>
      </div>
      
    </div>
     
  </div>
  <!-- Botonera -->
    <div mat-dialog-actions class="thermal-theme" fxLayout="row" fxLayoutAlign="end center">
      <div fxFlex></div>
      <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
      <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>
</form>