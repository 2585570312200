<div class="main-container">
    <form #protocolListForm="ngForm">
      <div class="toolbar-list">
  
        <div class="toolbar-list-title">
          <div fxLayout="row" fxLayoutAlign="start center">
            <h1 class="accent-color">{{ 'courseList.title' | translate }}</h1>
          </div>
  
          <div class="breadcrumbs">
            <a href="#">{{ 'common.start.label' | translate }}</a> / <span>{{ 'courseList.title' | translate }}</span>
          </div>
        </div>
  
        <div class="toolbar-list-actions">
          <button mat-raised-button (click)="new()" color="accent"
            matTooltip="{{ 'button.disabled.offline' | translate }}">
            <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.new' | translate }}
          </button>
        </div>
  
        <div class="toolbar-back-button">
          <a (click)="cancel()" mat-button color="accent">
            <mat-icon color="accent">undo</mat-icon> {{ 'button.back' | translate }}
          </a>
        </div>
      </div>
  
      <app-course-list-filter #filterComponent (filterEmitter)="doFilter($event)" class="filter-container">
      </app-course-list-filter>
  
      <div class="table-container">
        <table #protocols mat-table matSort [dataSource]="dataSource">
  
          <!-- name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'courseList.cols.name' | translate }} </th>
            <td mat-cell *matCellDef="let item"> {{ item.name }} </td>
          </ng-container>

           <!-- code Column -->
           <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'courseList.cols.code' | translate }} </th>
            <td mat-cell *matCellDef="let item"> {{ item.code }} </td>
          </ng-container>

          
           <!-- points Column -->
           <ng-container matColumnDef="points">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'courseList.cols.points' | translate }} </th>
            <td mat-cell *matCellDef="let item"> {{ item.points }} </td>
          </ng-container>

          
           <!-- price Column -->
           <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'courseList.cols.price' | translate }} </th>
            <td mat-cell *matCellDef="let item"> {{ item.price }} </td>
          </ng-container>

          
           <!-- person_price Column -->
           <ng-container matColumnDef="person_price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'courseList.cols.person_price' | translate }} </th>
            <td mat-cell *matCellDef="let item"> {{ item.person_price }} </td>
          </ng-container>
  
          <!-- Button download Column -->
          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef colspan="3" fxHide.lt-lg> {{ 'courseList.cols.actions' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
              <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
                <button mat-icon-button (click)="downloadPdf(element)" [disabled]="disableOfflineButton"
                  matTooltip="{{ 'button.download' | translate }}">
                  <mat-icon>cloud_download</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
  
          <!-- Button edit Column -->
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
            <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
              <button mat-icon-button (click)="editRow(element.id)" matTooltip="{{ 'button.edit' | translate }}">
                <mat-icon>mode_edit</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <!-- Button delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
            <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
              <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
                <button mat-icon-button (click)="deleteRow(element.id)" [disabled]="disableOfflineButton"
                  *ngIf="canDelete(element)" matTooltip="{{ 'button.delete' | translate }}">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
  
          <!-- Button more Column -->
          <ng-container matColumnDef="  " stickyEnd>
            <th mat-header-cell *matHeaderCellDef fxHide fxShow.lt-lg></th>
            <td mat-cell *matCellDef="let element" fxHide fxShow.lt-lg class="action">
  
              <mat-menu #appMenu="matMenu" class="moreActions">
                <ng-template matMenuContent let-name="element">
                  <button mat-button (click)="downloadPdf(element)" [disabled]="disableOfflineButton">
                    <mat-icon>cloud_download</mat-icon> {{ 'button.download' | translate }}
                  </button>
                  <button mat-button (click)="editRow(element.id)">
                    <mat-icon>mode_edit</mat-icon> {{ 'button.edit' | translate }}
                  </button>
                  <button mat-button (click)="deleteRow(element.id)" [disabled]="disableOfflineButton"
                    *ngIf="canDelete(element)">
                    <mat-icon>delete</mat-icon> {{ 'button.delete' | translate }}
                  </button>
                </ng-template>
              </mat-menu>
  
              <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="element">
                <mat-icon>more_horiz</mat-icon>
              </button>
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        </table>
  
        <span *ngIf="!hasFiltered" class="prefilterMessage">
          <p [innerHtml]="'common.listPlaceholder' | translate | safe: 'html'"> </p>
        </span>
  
      </div>
  
      <div class="table-footer-container">
        <div class="actions">
          <button mat-raised-button (click)="exportTable()" class="stroked-primary" *ngIf="data.isList">
            <mat-icon fxHide.lt-sm>cloud_download</mat-icon> {{ 'button.downloadXls' | translate }}
          </button>
          <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top accent-color" *ngIf="data.isList">
            {{ 'button.backToTop' | translate }} </a>
        </div>
  
        <div class="paginator">
          <mat-paginator [pageSize]="data.isList ? 10 : 5" [pageSizeOptions]="data.isList ? [5, 10, 20] : [5]"
            showFirstLastButtons [length]="dataSource.totalItems$ | async" lang="es">
          </mat-paginator>
        </div>
      </div>
    </form>
  </div>