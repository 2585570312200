import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class TimeZoneService {

    constructor(private http: HttpClient) { }

    findAllCommercial(): Observable<any> {
        const url = environment.quotit + `/timezone/findAllTimeZone`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    
}
