import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuditConfig } from '../model/auditConfig';
import { HttpUtils } from '../utils/httpUtils';
import { QueryParam, ServiceUtil } from '../utils/service.util';

@Injectable({
  providedIn: 'root'
})
export class AuditConfigService {
  private url = `${environment.quotit}/auditConfig`
  constructor(private http: HttpClient) { }

  findById(id: number) {
    return this.http.get(`${this.url}/${id}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  findAll(): Observable<AuditConfig[]> {
    return this.http.get(`${this.url}/`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  findConfigFilter(type: number, idProduct: number, idSite: number, idCountry: number):  Observable<AuditConfig> {

    const data: QueryParam[] = [
      { key: 'idType', value: type},
      { key: 'idProduct', value: idProduct},
      { key: 'idSite', value: idSite},
    ]

    if (idCountry != null) {
      data.push( { key: 'idCountry', value: idCountry},)
    }
    return this.http.get(ServiceUtil.createQueryParams(`${this.url}/filter-config`, data)).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

}
