import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject, of, takeUntil } from 'rxjs';
import { Equipment, EquipmentType } from 'src/app/model/equipment';
import { ExcelUtils } from 'src/app/utils/excelUtils';
import { MatDialogRef } from '@angular/material/dialog';
import { TestTypeService } from '../../../service/testType.service';
import { EquipmentTypeService } from 'src/app/service/equipmentType.service';
import { EquipmentAirService } from 'src/app/service/equipment-air.service';
import { Test, TestType } from 'src/app/model/test';
import { TestTypeEquipmentConfigService } from 'src/app/service/testTypeEquipmentConfig.service';
import { TestTypeEquipmentConfig } from '../../../model/testTypeEquipmentConfig';
import { LoadingService } from 'src/app/service/loading.service';

@Component({
  selector: 'app-quote-edit-dialog-masive-equipment',
  templateUrl: './quote-edit-dialog-masive-equipment.component.html'
})
export class MasiveEquipmentComponent implements OnInit {

  displayedColumns = ['nequipment', 'equipment', 'model', 'maker', 'nserie', 'location', 'essay'];

  equipments: Equipment[] = [];
  equipmentTypes: EquipmentType[];
  testTypes: TestType[] = [];
  calibrationsErrors: any[] = [];

  private destroy$ = new Subject<void>();
  constructor(private translateService: TranslateService,
    private testTypeService: TestTypeService,
    private equipmentTypeService: EquipmentTypeService,
    private equipmentAirService: EquipmentAirService,
    private testTypeEquipmentConfigService: TestTypeEquipmentConfigService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<MasiveEquipmentComponent>,
    public loader: LoadingService) { }

  ngOnInit(): void {
    this.testTypeService.findAll().subscribe((res: TestType[]) => {
      this.testTypes = res;
    });
    this.equipmentTypeService.findAllByIdAplication(1).subscribe((res: EquipmentType[]) => {
      this.equipmentTypes = res;

      if (res && res.length > 0) {
        res.forEach(es => {
          this.testTypeEquipmentConfigService.findByEquipment(es.id).subscribe((res1: TestTypeEquipmentConfig[]) => {
            let testsByEquipment =  res1.filter((item) => item.idTestType !== 26);
            testsByEquipment =  testsByEquipment.filter((item) => item.idTestType !== 27);
            testsByEquipment?.forEach(test => {
              this.testTypes.forEach(type => {
                if (test.idTestType === type.id) {
                  test.translation = this.getEssayName(type);
                }
              });
            });
          });
        });
      }
    });
  }

  uploadFile(event): void {
    this.loader.show();

    this.calibrationsErrors = [];
    const file = event.target.files[0] as File;

    const data = of(void ExcelUtils.excelToEquipmentAir(file, this.testTypeService, this.equipmentTypeService, this.translateService, this.testTypeEquipmentConfigService).then(data => {
      this.equipments = data.get('data');
      this.loader.hide();

      if (data.get('error')) {
        const resultError = data.get('error') as [];

        if (resultError?.length > 0) {
          resultError.forEach(er => {
            this.calibrationsErrors.push(er);
          })
        }
      }
    }).catch(()=> {
      this.loader.hide();
      this.calibrationsErrors.push('Error al leer el documento');
    }));
  }

  downloadFile() {
    this.loader.show();

    this.equipmentAirService.getExcelTemplate().pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = 'template-equipmentAir.xlsx';
      saveAs(res, name);
      this.loader.hide();
    }, error => {
      this.loader.hide();
      console.error(error);
    });
  }

  getNameEquipment(id: number): string {
    return this.equipmentTypes.find(v => v.id === id)?.translate;
  }


  onOkClick() {
    this.dialogRef.close(this.equipments);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  convertListToString(essays: Test[]) {
    let list: String[] = [];
    essays.forEach(a => {
      const testType = this.testTypes.find(f => f.id === a.idType);
      list.push(this.translate.instant(this.getEssayName(testType)));
    })
    return list?.join(' | ');
  }

  private getEssayName(type: TestType): string {
    return "testType.".concat(type.translate);
  }

}
