import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpUtils } from '../utils/httpUtils';
import { AuditType } from '../model/auditType';

@Injectable({
  providedIn: 'root'
})
export class AuditTypeService {
  url = `${environment.quotit}/auditType`
  constructor(private http: HttpClient) { }

  findById(id: number) {
    return this.http.get(`${this.url}/${id}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  findAll(): Observable<AuditType[]> {
    return this.http.get(`${this.url}/`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }
}
