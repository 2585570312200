import { ViewportScroller, Location } from "@angular/common";
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router, Scroll } from "@angular/router";
import { Subject, merge } from 'rxjs';
import { filter, takeUntil, tap } from "rxjs/operators";
import { Quote, QuoteFilter } from "src/app/model/quote";
import { User } from "src/app/model/user";
import { QuoteService } from "src/app/service/quote.service";
import { QuoteDataSource } from 'src/app/model/quoteDataSource';
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { SpinnerService } from "src/app/service/spinner.service";
import { ClientService } from "src/app/service/client.service";
import { Client } from "src/app/model/client";
import { LoadingService } from "src/app/service/loading.service";
import { Constants } from '../../utils/constants';
import { ActivatedRoute } from '@angular/router';
import { commercial } from "src/app/model/commercial";
import { CommercialService } from "src/app/service/commercial.service";
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html'
})

export class QuoteListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: QuoteDataSource;
  private destroy$ = new Subject<void>();
  filter: QuoteFilter = new QuoteFilter();
  hasFiltered = false;
  currentUser: User;
  clients: Client[];
  commercials: commercial[];
  displayedColumns: string[] = [
    'quoteNumber', 'version', 'projectNumber', 'typeOffer',  'client',  'commercial', 'statusId', 'dateSend', 'dateWonLost', 'import', 'actions',
  ]

  constructor(
    
    private route: ActivatedRoute,
    private clientService: ClientService,
    private commercialService: CommercialService,
    private quoteService: QuoteService,
    private router: Router,
    private viewportScroller: ViewportScroller,
    public dialog: MatDialog,
    public loader: LoadingService,
    private translate: TranslateService,
    private spinnerService: SpinnerService,
    private location: Location) {
    this.router.events.pipe(filter(e => e instanceof Scroll)).pipe(takeUntil(this.destroy$)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params =>{
      this.filter.idStatus = params['idStatus'] || null;
      if(this.filter.idStatus!= null){
     this.hasFiltered = true;
      }
    });

    this.clientService.findAll().subscribe((res: Client[]) => {
      this.clients = res;
    })

    this.commercialService.findAllCommercial().subscribe((res: commercial[]) =>{
      this.commercials = res;
    })

    this.loadQuoteList();
    
  }

  deleteRow(id: number): void {

    if (id > 0) {
      this.quoteService.delete(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.loadQuoteList();
      });
    }
  }

  loadQuoteList(): void {
    this.dataSource = new QuoteDataSource(this.quoteService);

    this.filter.sortBy = this.sort.active || 'id';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 10;

    if (this.hasFiltered) {
      this.dataSource.loadQuotes(this.filter);
    }

    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  doFilter(event: QuoteFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadQuoteList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadQuoteList())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  downloadPdf(quote: any): void {
    this.loader.show();
      this.quoteService.getPdf(quote.id).subscribe(
        (data: Blob) => {
          let file = new Blob([data], { type: 'application/pdf' })
          let fileURL = URL.createObjectURL(file);
  
          // if you want to open PDF in new tab
          let a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = quote.quoteNumber;
          document.body.appendChild(a);
          a.click();
          this.loader.hide();
        },
        (error) => {
          this.loader.hide();
        }
      );
    
  }


  new(): void {
    void this.router.navigateByUrl('/quote?id=0');
  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`/quote?id=${id}`);
  }

  detailRow(id: number): void {
    void this.router.navigateByUrl(`/detail?id=${id}`);
  }

  cancel(): void {
    this.location.back();
  }

  highlight(eq: Quote): string {
    let $res: string;
    if (!eq.active) {
      $res = 'deactivate';
    }

    return $res;
  }

  getStatus(idStatus: number): string {
    if (idStatus == 1) {
      return 'En preparación'
    } else if (idStatus == 2) {
      return 'Firmada'
    } else if (idStatus == 3) {
      return 'Enviada'
    } else if (idStatus == 4) {
      return 'Ganada'
    } else if (idStatus == 5) {
      return 'Perdida'
    } else if (idStatus == 6) {
      return 'Archivada'
    } else {
      return 'Nueva Versión'
    }
  }

  getCommercialName(idComercial: number): string {
    let commercial = this.commercials.find(c => c.id == idComercial )

    if (commercial != null ){
      return commercial.name;
    }
    
  }

  getClientName(idClient: number): string {
    let client = this.clients.find(c => c.id == idClient);

    if (client != null) {
      return client.name;
    }

  }

  getTypeOfferName(idType: number): string {
    let name = '';

    if (idType === Constants.QUOTE_TYPE_AIR) {
      name = Constants.SERVICE_TYPE_AIR;
    } else  if (idType === Constants.QUOTE_TYPE_CALIBRATES) {
      name = Constants.SERVICE_TYPE_CALIBRATES;
    } else  if (idType === Constants.QUOTE_TYPE_THERMAL) {
      name = Constants.SERVICE_TYPE_THERMAL;
    } else  if (idType === Constants.QUOTE_TYPE_CONSULTANCY) {
      name = Constants.SERVICE_TYPE_CONSULTORY;
    } else  if (idType === Constants.QUOTE_TYPE_MIXED) {
      name = Constants.SERVICE_TYPE_MIXED;
    } else  if (idType === Constants.QUOTE_TYPE_FORMATION) {
      name = Constants.SERVICE_TYPE_FORMATION;
    }

    return name;
  }

  exportTable(): void {
    this.filter.cols = this.displayedColumns;

    this.loader.show();
    this.quoteService.getExcel(this.filter).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name =  'quotes.xlsx';
      saveAs(res, name);
      this.loader.hide();
    }, error => {
      console.error(error);
      this.loader.hide();
    });
  }
}