import { Component, OnInit } from '@angular/core';

import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  constructor(private loginService: LoginService) { }

  ngOnInit(): void { }

  login() {
    this.loginService.login().then(() => this.loginService.goHome());
  }

}
