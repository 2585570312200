import { GenericFilter } from "./genericClass";

export class DietTravel {
    id: number;
    traduction: string;
    price: number;
}

export class DietTravelFilter extends GenericFilter{
    id: number;
    name: string;
}