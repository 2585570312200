import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { CourseListComponent } from './course-list.component';
import { CourseListFilterComponent } from './course-list-filter.component';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [CourseListComponent, CourseListFilterComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        /* Custom modules */
        AngularMaterialModule,
        /* Otros */
        FlexLayoutModule,
        RouterModule,
        SharedModule
    ],
    exports: [
        CourseListComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})
export class CourseListModule { }
