<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="air-theme">
    <div fxLayout="row" fxLayoutGap="16px" class="campoFormulario">
      <mat-form-field fxFlex="50%" class="campoFormulario">
        <mat-label>{{ 'quoteEdit.general.training.title' | translate }}</mat-label>
        <mat-select #courseSelect required name="curso" [(ngModel)]="courseType" (selectionChange)="calculatePrice()">
          <mat-select-filter
            [displayMember]="'name'"
            [array]="courses"
            (filteredReturn)="coursesFiltered = $event"
            [noResultsMessage]="'common.noResults' | translate"
            [placeholder]="'common.selectFilterPlaceholder' | translate"
          ></mat-select-filter>
          <mat-option *ngFor="let course of coursesFiltered" [value]="course">
            {{ course.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ 'quoteEdit.general.training.error' | translate }}
        </mat-error>
      </mat-form-field>
    
      <mat-form-field fxFlex="50%">
        <mat-label>{{ 'quoteEdit.general.modality.title' | translate }}</mat-label>
        <mat-select required name="curso" [(ngModel)]="data.course.idModality" (selectionChange)="calculatePrice()">
          <mat-option *ngFor="let modality of modalities" [value]="modality.id">
            {{ modality.translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    
      <mat-form-field fxFlex="50%">
        <mat-label>{{ 'quoteEdit.general.training.assistants' | translate }}</mat-label>
        <input matInput name="students" type="number" [(ngModel)]="data.course.students" (change)="calculatePrice()">
      </mat-form-field>
    </div>
    
    <div fxLayout="row" fxLayoutGap="16px" class="campoFormulario">
      <mat-form-field fxFlex="33%">
        <mat-label>{{ 'quoteEdit.general.training.points' | translate }}</mat-label>
        <input matInput name="points" type="number" [(ngModel)]="courseType.points" readonly>
      </mat-form-field>

      <mat-form-field fxFlex="33%">
        <mat-label>{{ 'quoteEdit.general.training.totalprice' | translate }}</mat-label>
        <input matInput name="precioTotal" type="number" [(ngModel)]="data.course.price" readonly>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button (click)="onOkClick()" color="primary">{{ 'button.save' | translate }}</button>
    <button mat-raised-button (click)="onNoClick()" color="secondary">{{ 'button.cancel' | translate }}</button>
  </div>
</form>