import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class XSRFInterceptor implements HttpInterceptor {
    constructor(private tokenExtractor: HttpXsrfTokenExtractor) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let headers = req.headers;

        const token = this.tokenExtractor.getToken();
        if (token != null) {
            headers = headers.set('x-xsrf-token', token);
        }

        const clonedReq = req.clone({ headers });

        return next.handle(clonedReq);
    }

}
