import { BehaviorSubject, Observable } from 'rxjs';
import { Course, CourseFilter } from '../model/course';

import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class CourseService {

    currentLang: string;

    private courseSubject = new BehaviorSubject<Course>(new Course());
    private unreadSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public course$ = this.courseSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public unread$ = this.unreadSubject.asObservable();

    constructor(private http: HttpClient) {
    }

    save(course: Course): Observable<any> {
        let url = '';
        
        if(course.id == null){
            url = environment.quotit + '/course/create/';
        } else {
            url = environment.quotit + `/course/${course.id}`;
        }

        return this.http.post(url, course).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllFilter(filter: CourseFilter): Observable<any> {
        const url = environment.quotit + '/course/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAll(): Observable<any> {
        const url = environment.quotit + '/course/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(id: number): Observable<any> {
        const url = environment.quotit + `/course/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
