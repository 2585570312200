import { Severity, SnackbarMessage } from '../model/snackbar';

import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SnackBarService {

    private messageSubject = new BehaviorSubject<SnackbarMessage>(null);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public message$ = this.messageSubject.asObservable();

    sendSuccess(str: string): void {
        this.messageSubject.next(new SnackbarMessage(str, Severity.SUCCESS));
    }

    sendInfo(str: string): void {
        this.messageSubject.next(new SnackbarMessage(str, Severity.INFO));
    }

    sendWarn(str: string): void {
        this.messageSubject.next(new SnackbarMessage(str, Severity.WARNING));
    }

    sendError(str: string): void {
        this.messageSubject.next(new SnackbarMessage(str, Severity.ERROR));
    }

}
