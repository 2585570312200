
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { FlexLayoutModule } from 'ngx-flexible-layout';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgModule } from '@angular/core';

import { PaginatorI18n } from 'src/app/utils/paginatorI18n';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CustomDatepickerHeaderComponent } from './datepicker-custom-header.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { DuplicateDialogComponent } from './duplicate-dialog/duplicate-dialog.component'
import { FormsModule } from '@angular/forms';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxRangeSelectorModule } from 'devextreme-angular/ui/range-selector';
import { DxPieChartModule } from 'devextreme-angular';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { EditAttachmentEditComponent } from './edit-attachment-edit/edit-attachment-edit.component';


@NgModule({
    declarations: [
        HeaderComponent, SidebarComponent, FooterComponent, CustomDatepickerHeaderComponent, DuplicateDialogComponent, EditAttachmentEditComponent
    ],
    imports: [
        TranslateModule,
        RouterModule,
        FormsModule,
        /* Custom modules */
        AngularMaterialModule,

        /* Otros */
        FlexLayoutModule,
        MatButtonToggleModule,

        //Dev Extrem
        DxChartModule, 
        DxSelectBoxModule,
        DxRangeSelectorModule,
        DxPieChartModule
    ],
    providers: [
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService)
        }
    ],
    exports: [
        TranslateModule, HeaderComponent, SidebarComponent, FooterComponent, CustomDatepickerHeaderComponent, 
        MatButtonToggleModule, DxChartModule, DxPieChartModule, EditAttachmentEditComponent
    ]
})
export class SharedModule { }
