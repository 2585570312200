import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

const ITEMS_PER_PAGE = 'paginator.items_per_page_label';
const NEXT_PAGE = 'paginator.next_page_label';
const PREV_PAGE = 'paginator.previous_page_label';
const FIRST_PAGE = 'paginator.first_page_label';
const LAST_PAGE = 'paginator.last_page_label';

export class PaginatorI18n extends MatPaginatorIntl {

    public constructor(private translate: TranslateService) {
        super();

        this.translate.onLangChange.subscribe(() => {
            this.getAndInitTranslations();
        });

        this.getAndInitTranslations();
    }

    public getAndInitTranslations(): void {

        this.translate.get([
            ITEMS_PER_PAGE,
            NEXT_PAGE,
            PREV_PAGE,
            FIRST_PAGE,
            LAST_PAGE
        ]).subscribe((translation: any) => {

            this.itemsPerPageLabel = translation[ITEMS_PER_PAGE];
            this.nextPageLabel = translation[NEXT_PAGE];
            this.previousPageLabel = translation[PREV_PAGE];
            this.firstPageLabel = translation[FIRST_PAGE];
            this.lastPageLabel = translation[LAST_PAGE];

            this.changes.next();
        });
    }

    public override getRangeLabel = (page: number, pageSize: number, length: number): string => {
        let msg = '';

        if (length === 0 || pageSize === 0) {
            msg = this.translate.instant('paginator.range_page_label_1', { length }) as string;
        } else {
            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            // If the start index exceeds the list length, do not try and fix the end index to the end.
            const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            msg = this.translate.instant('paginator.range_page_label_2', { startIndex: startIndex + 1, endIndex, length }) as string;
        }

        return msg;
    }
}
