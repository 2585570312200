<!-------- ---   - ---  ---- -->
<div class="main-container small-layout">
    <form>
  
      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'courseEdit.general.title' | translate }}
          </mat-toolbar>
  
          <div fxLayout="row" class="flexwrap">
  
            <div fxFlex="100" fxFlex.lt-md="100%">
              <div fxLayout="column">
                <div fxLayout="row" class="flexwrap">
                  <div fxFlex="100" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput [(ngModel)]="course.name" name="name" required
                        placeholder="{{ 'courseEdit.general.name.label' | translate }}">
                      <mat-error>
                        {{ 'courseEdit.general.name.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row" class="flexwrap">
                  <div fxFlex="100" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput [(ngModel)]="course.description" name="description" required
                        placeholder="{{ 'courseEdit.general.description.label' | translate }}">
                      <mat-error>
                        {{ 'courseEdit.general.description.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row" class="flexwrap">
                  <div fxFlex="100" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput [(ngModel)]="course.objetive" name="objetive" 
                        placeholder="{{ 'courseEdit.general.objetive.label' | translate }}">
                      <mat-error>
                        {{ 'courseEdit.general.objetive.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row" class="flexwrap">
                  <div fxFlex="100" fxFlex.lt-md="100%">
                    <div fxLayout="column" style="height: 200px;"> 
                      <div fxLayout="column" fxFlex.lt-md="90%" style="height: 100%;">
                        <div class="form-outline w-75 mb-4" > 
                            <textarea matInput name="messaInfo" #message='ngModel'
                            placeholder="{{ 'courseEdit.general.diary.label' | translate }}"
                            [(ngModel)]="course.diary" class="text-rich-scope" style="font-size: 12px;"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div fxLayout="row" class="flexwrap">
                  <div fxFlex="30" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput [(ngModel)]="course.code" name="code" required
                        placeholder="{{ 'courseEdit.general.code.label' | translate }}">
                      <mat-error>
                        {{ 'courseEdit.general.name.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="20" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput [(ngModel)]="course.points" name="points" required
                      type="number"
                        placeholder="{{ 'courseEdit.general.points.label' | translate }}">
                      <mat-error>
                        {{ 'courseEdit.general.points.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="20" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput [(ngModel)]="course.duration" name="duration" required
                      type="number"
                        placeholder="{{ 'courseEdit.general.duration.label' | translate }}">
                      <mat-error>
                        {{ 'courseEdit.general.duration.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                    
                  </div>
                  <div fxFlex="20" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <mat-label>{{ 'userEdit.general.role.label' | translate }}</mat-label>
                      <mat-select required [(ngModel)]="course.difficultyId" name="difficultyId">
                        <mat-option [value]="null"> --- </mat-option>
                          <mat-option *ngFor="let diff of difficulties"
                              [value]="diff.id">
                              {{ diff.translate }}
                          </mat-option>
                      </mat-select>
                      <mat-error>
                        {{ 'userEdit.general.diff.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row" class="flexwrap" >
                  
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
  
      <mat-card class="card">
        <mat-card-actions>
          <div fxFlex></div>
          <button mat-raised-button color="primary" (click)="save()">{{
            'button.save' | translate }}</button>
          <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel'
            | translate }}</button>
  
        </mat-card-actions>
      </mat-card>
  
    </form>
  
  </div>