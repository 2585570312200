import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface DialogDataRoleUser {
  message: string;
  canOk: boolean;
  canCancel: boolean;
  messageOk: string;
  messageCancel: string;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataRoleUser) { }

  ngOnInit(): void {
    this.data.canOk = this.data.canOk != null ? this.data.canOk : true;
    this.data.canCancel = this.data.canCancel != null ? this.data.canCancel : true;

    this.data.messageOk = this.data.messageOk != null ? this.data.messageOk : this.translate.instant('common.yes') as string;
    this.data.messageCancel = this.data.messageCancel != null ? this.data.messageCancel
      : (this.data.canOk ? this.translate.instant('common.no') : this.translate.instant('button.cancel')) as string;
  }

  onOkClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

}
