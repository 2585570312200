<form>
    <div fxLayout="column" style="place-items: center;">
        <div fxFlex="100" fxLayout="row" class="main-container" style="margin:0; width: 100%;">
            <div fxFlex="20" class="card-grid card-blue overview-box blue shadow-1" (click)="sendStatus(statusSent)">
                <div class="card-header">
                    <span class="card-header-title">{{'quote.dashboard.valueCountOffertsByStatus.label' | translate}}</span>
                </div>
                <div class="overview-content">
                    <span class="overview-box-label">{{valueCountOffertsByStatusSent}}</span>
                    <div class="overview-graph">
                        <span style="height: 12px;">
                            <span class="graph-tooltip">12</span>
                        </span>
                        <span style="height: 40px;">
                            <span class="graph-tooltip">40</span>
                        </span>
                        <span style="height: 26px;">
                            <span class="graph-tooltip">26</span>
                        </span>
                        <span style="height: 17px;">
                            <span class="graph-tooltip">17</span>
                        </span>
                        <span style="height: 36px;">
                            <span class="graph-tooltip">36</span>
                        </span>
                        <span style="height: 51px;">
                            <span class="graph-tooltip">51</span>
                        </span>
                    </div>
                </div>
            </div>
    
            <div fxFlex="20" class="card-grid overview-box shadow-1 card-blue-white orange">
                <div class="card-header">
                    <span class="card-header-title">{{'quote.dashboard.sumOffertsByStatus.label' | translate}}</span>
                </div>
                <div class="overview-content">
                    <span class="overview-box-label">{{valueSumOffertsByStatus | currency:'EUR':true}}</span>
                    <div class="overview-graph">
                        <span style="height: 20px;">
                            <span class="graph-tooltip">20</span>
                        </span>
                        <span style="height: 23px;">
                            <span class="graph-tooltip">23</span>
                        </span>
                        <span style="height: 52px;">
                            <span class="graph-tooltip">52</span>
                        </span>
                        <span style="height: 18px;">
                            <span class="graph-tooltip">18</span>
                        </span>
                        <span style="height: 28px;">
                            <span class="graph-tooltip">28</span>
                        </span>
                        <span style="height: 42px;">
                            <span class="graph-tooltip">42</span>
                        </span>
                    </div>
                </div>
            </div>
    
            <div fxFlex="20" class="card-grid overview-box shadow-1 card-green teal" (click)="sendStatus(statusWon)">
                <div class="card-header">
                    <span class="card-header-title">{{'quote.dashboard.sumOffertsByStatusWon.label' | translate}}</span>
                </div>
                <div class="overview-content">
                    <span class="overview-box-label">{{valueCountOffertsByStatusWon}}</span>
                    <div class="overview-graph">
                        <span style="height: 30px;">
                            <span class="graph-tooltip">30</span>
                        </span>
                        <span style="height: 43px;">
                            <span class="graph-tooltip">43</span>
                        </span>
                        <span style="height: 48px;">
                            <span class="graph-tooltip">48</span>
                        </span>
                        <span style="height: 40px;">
                            <span class="graph-tooltip">40</span>
                        </span>
                        <span style="height: 52px;">
                            <span class="graph-tooltip">52</span>
                        </span>
                        <span style="height: 18px;">
                            <span class="graph-tooltip">18</span>
                        </span>
                    </div>
                </div>
            </div>

            <div fxFlex="20" class="card-grid overview-box shadow-1 card-green blue" >
                <div class="card-header">
                    <span class="card-header-title">{{'quote.dashboard.sumOffertsWon.label' | translate}}</span>
                </div>
                <div class="overview-content">
                    <span class="overview-box-label">{{valueSumOffertsByStatusWon | currency:'EUR':true}}</span>
                    <div class="overview-graph">
                        <span style="height: 30px;">
                            <span class="graph-tooltip">12</span>
                        </span>
                        <span style="height: 43px;">
                            <span class="graph-tooltip">4</span>
                        </span>
                        <span style="height: 48px;">
                            <span class="graph-tooltip">29</span>
                        </span>
                        <span style="height: 40px;">
                            <span class="graph-tooltip">2</span>
                        </span>
                        <span style="height: 52px;">
                            <span class="graph-tooltip">40</span>
                        </span>
                        <span style="height: 18px;">
                            <span class="graph-tooltip">18</span>
                        </span>
                    </div>
                </div>
                <div fxLayout="row">
                    <span style="color:#00acac; margin-right: 5px;">{{valueCriteriaAmount}}% </span> <span> {{'quote.dashboard.sumOffertsCriteria.label' | translate}}</span>
                </div>
            </div>
    
            <div fxFlex="20" class="card-grid overview-box shadow-1 card-purple">
                <div class="card-header">
                    <span class="card-header-title">{{'quote.dashboard.commercialCountWithOffertsByStatus.label' | translate}}</span>
                </div>
                <div class="overview-content">
                    <div class="box-subcard  box-subcard-border  surface-border" fxLayout="column">
                        <span class="box-subcard-title">{{valueCommercialCountWithOffertsByStatus?.arg}}</span>
                        <span style="justify-content: center;">
                            <mat-icon class="overview-box-icon">person</mat-icon>
                            {{'role.commercial' | translate}}
                        </span>
                    </div>
                    <div fxLayout="column" class="box-subcard">
                        <span class="box-subcard-title">{{valueCommercialCountWithOffertsByStatus?.val}}</span>
                        <span>{{'quote.dashboard.offerts' | translate}}</span>
                    </div>
                </div>
            </div>

    
            <div fxFlex="20" class="card-grid card-blue-white overview-box blue shadow-1">
                <div class="card-header">
                    <span class="card-header-title">{{'quote.dashboard.quoteCountByMonthsCurrent.label' | translate}}</span>
                </div>
                <div class="overview-content">
                    <span class="overview-box-label">{{valueQuoteCountByMonthCurrent}}</span>
                    <div class="overview-graph">
                        <span style="height: 30px;">
                            <span class="graph-tooltip">50</span>
                        </span>
                        <span style="height: 43px;">
                            <span class="graph-tooltip">10</span>
                        </span>
                        <span style="height: 48px;">
                            <span class="graph-tooltip">2</span>
                        </span>
                        <span style="height: 40px;">
                            <span class="graph-tooltip">5</span>
                        </span>
                        <span style="height: 52px;">
                            <span class="graph-tooltip">60</span>
                        </span>
                        <span style="height: 18px;">
                            <span class="graph-tooltip">30</span>
                        </span>
                    </div>
                </div>
            </div>
    
        </div>
        <div fxFlex="100" style="padding: 20px; text-align: -webkit-center; width: 90%;">
            <div fxFlex="100" style="padding: 10px;">
                <dx-pie-chart
                id="pie"
                title="Ofertas mensuales"
                palette="bright"
                [dataSource]="valueQuoteCountByMonths"
                (onPointClick)="pointClickHandler($event)"
                (onLegendClick)="legendClickHandler($event)"
              >
                <dxi-series>
                  <dxo-label [visible]="true">
                    <dxo-connector [visible]="true" [width]="1"></dxo-connector>
                  </dxo-label>
                </dxi-series>
                <dxo-size [width]="500"></dxo-size>
                <dxo-export [enabled]="true"></dxo-export>
              </dx-pie-chart>
              
            </div>
            <div fxFlex="100" style="padding: 10px;">
                <dx-chart id="chart"
                title="Ofertas diarias"
                [dataSource]="valueQuoteCountByDays">
                <dxo-legend [visible]="false"></dxo-legend>
                <dxi-series type="bar"></dxi-series>
                <dxo-argument-axis [tickInterval]="10">
                    <dxo-label>
                    <dxo-format type="decimal"></dxo-format>
                    </dxo-label>
                </dxo-argument-axis>
            </dx-chart>
            </div>
        </div>
    </div>
</form>