import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Client } from "src/app/model/client";
import { ClientService } from "src/app/service/client.service";
import { SnackBarService } from "src/app/service/snackBar.service";
import * as _ from 'lodash';

export interface DialogClienListNewData {
    isEdit: boolean;
    client: Client;
  }
  
  @Component({
    selector: 'app-client-list-new',
    templateUrl: './client-list-new.component.html'
  })
  export class ClientListNewComponent implements OnInit {
  
    tituloDialog: string;
    client: Client[];
  
    constructor(
      public dialog: MatDialog,
      private router: Router,
      public snackBarService: SnackBarService,
      private translate: TranslateService,
      private clientService: ClientService,
      private dialogRef: MatDialogRef<DialogClienListNewData>,
      @Inject(MAT_DIALOG_DATA) public data: DialogClienListNewData) {
  
      if (this.data.isEdit) {
        this.tituloDialog = this.translate.instant('quoteEdit.dialog.thermal.probe.title.edit', 
        { model: data.client, serial: data.client }) as string;
      } else {
        this.tituloDialog = this.translate.instant('quoteEdit.dialog.thermal.probe.title.create') as string;
      }
    }
  
    ngOnInit(): void {
      
    }
  
    onOkClick(): void {
        const errs: string[] = [];
      
        if (this.data.client.name == null) {
          errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
        }
        if (this.data.client.address == null) {
          errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
        }
        if (this.data.client.postalCode == null) {
          errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
        }
        if (this.data.client.city == null) {
          errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
        }
        if (this.data.client.state == null) {
          errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
        }
        if (this.data.client.country == null) {
          errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
        }
        if (this.data.client.nameBusinessGroup == null) {
          // errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
        }
      
        if (errs.length === 0) {
          
          this.clientService.save(this.data.client).subscribe(result => {
            if (result) {
                this.snackBarService.sendSuccess(this.translate.instant('protocolClient.form.create.ok') as string);
                this.dialogRef.close();
            } else {
              console.error('Error al actualizar el cliente.');
            }
          });
        } 
        else {
          const error = errs.join('\n');
          errs.push(this.translate.instant('protocolEdit.form.error.ok') as string);
        }
  
      }
      
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  }
  