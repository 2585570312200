import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { EquipmentTypeListComponent } from "./equipmentType-list.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularMaterialModule } from "src/app/external-components/angular-material.module";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { EquipmentTypeFilterComponent} from "./equipmentType-list-filter.component";
import { MatPaginatorModule } from "@angular/material/paginator";

@NgModule({
    declarations: [EquipmentTypeListComponent, EquipmentTypeFilterComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        /* Custom modules */
        AngularMaterialModule,
        /* Otros */
        FlexLayoutModule,
        RouterModule,
        SharedModule,
        MatPaginatorModule
    ],
    exports: [
        EquipmentTypeListComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})
export class EquipmentTypeListModule { }