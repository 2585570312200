
export class CourseDifficulty {

    id: number;
    translate: string;
    price: number;
    minimum: number;
    
} 
export enum CourseDifficultyEnum {

    EASY = 1,
    MID = 2,
    HARD = 3
} 