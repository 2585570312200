import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { QuoteService } from '../../../service/quote.service';
import { AttachmentService } from '../../../service/attachment.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../../../service/snackBar.service';
import { SpinnerService } from '../../../service/spinner.service';
import { LoadingService } from '../../../service/loading.service';
import { Observable } from 'rxjs';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { QuoteAttachment, QuoteAttachmentNoScope } from '../../../model/quote';
import { AttachmentType } from '../../../model/attachment';

export interface DialogAttachmentsNoScope {
  idQuote: number
}

@Component({
  selector: 'app-quote-edit-attachments-no-scope',
  templateUrl: './quote-edit-attachments-no-scope.component.html',
  styleUrls: ['./quote-edit-attachments-no-scope.component.scss']
})
export class QuoteEditAttachmentsNoScopeComponent implements OnInit {

  public displayedColumns = ['filename',  'delete'];

  @ViewChild('attachmentUploadPdf') attachmentUploadPdf: ElementRef;
  @ViewChild('attachmentUploadWord') attachmentUploadWord: ElementRef;
  idQuote: number;
  quoteAttachment: QuoteAttachment[] = [];
  quoteResult: QuoteAttachmentNoScope[] = [];
  dataSource: any;
  isCheckPdf: boolean = false;
  isCheckWord: boolean = false;

  constructor(private attachmentService: AttachmentService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    public loader: LoadingService,
    private dialogRef: MatDialogRef<QuoteEditAttachmentsNoScopeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogAttachmentsNoScope) { }

  ngOnInit(): void {
  }

  showWord() {
    return this.quoteResult?.find(e => e.type === AttachmentType.OFFERT_MANUAL_WORD) ? this.isCheckWord = true : this.isCheckWord = false;
  }

  showPdf() {
    return this.quoteResult?.find(e => e.type === AttachmentType.OFFERT_MANUAL_PDF) ? this.isCheckPdf = true :  this.isCheckPdf = false;
  }

  uploadAttachment(event, typeDocument: number): void {
    this.loader.show();
    const file = event.target.files[0] as File;

    if (file) {
      const val: QuoteAttachmentNoScope = {type: typeDocument, file: file};
      this.quoteResult.push(val);
      this.dataSource = [...this.quoteResult];
      this.showPdf();
      this.showWord();
    }

    this.attachmentUploadPdf.nativeElement.value = '';
    this.attachmentUploadWord.nativeElement.value = '';
    this.loader.hide();
    

  }

  onOkClick(): void {
    const dPdf = this.quoteResult.find(e => e.type === AttachmentType.OFFERT_MANUAL_WORD);
    const dWord = this.quoteResult.find(e => e.type === AttachmentType.OFFERT_MANUAL_PDF);

    if (!dPdf || !dWord) {
      this.snackBarService.sendError(this.translate.instant('quoteEdit.noScope.offert_manual.error.generic') as string);
    } else {
      this.dialogRef.close(this.quoteResult);
    }
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  deleteDoc(index: number): void {
    this.quoteResult = this.quoteResult.filter(doc => doc.type !== index);
    this.dataSource = [...this.quoteResult];
    this.showPdf();
    this.showWord();
  }

}
