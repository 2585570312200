import * as moment from 'moment';

export class DateUtils {

    static compare(a: Date, b: Date): number {
        return this.anyToDate(a).getTime() - this.anyToDate(b).getTime();
    }

    static toDateStr(date: Date): string {
        return moment(date).format('DD/MM/YYYY');
    }

    static toTimeStr(date: Date): string {
        return moment(date).format('HH:mm:ss');
    }

    static toDateTimeStr(date: Date): string {
        return `${this.toDateStr(date)} ${this.toTimeStr(date)}`;
    }

    static strTimeToDate(date: string): Date {
        return moment(date, 'DD/MM/YYYY HH:mm:ss').toDate();
    }

    static anyToDate(into: Date | number | string): Date {
        let date: Date;

        if (typeof into === 'string' || typeof into === 'number') {
            date = new Date(into);
        } else {
            date = into;
        }

        return date;
    }

    static timeDiffCalc(dateStart: Date, dateEnd: Date): string {
        let diffInSeconds = Math.abs(dateEnd.getTime() - dateStart.getTime()) / 1000;

        // calculate days
        const days = Math.floor(diffInSeconds / 86400);
        diffInSeconds -= days * 86400;

        // calculate hours
        const hours = Math.floor(diffInSeconds / 3600) % 24;
        diffInSeconds -= hours * 3600;

        // calculate minutes
        const minutes = Math.floor(diffInSeconds / 60) % 60;
        diffInSeconds -= minutes * 60;

        // calculate seconds
        const seconds = Math.floor(diffInSeconds / 1) % 60;
        diffInSeconds -= seconds * 60;

        const difference = [];

        if (days > 0) {
            difference.push((days === 1) ? `${days} día` : `${days} días`);
        }

        if (hours > 0) {
            difference.push((hours === 1) ? `${hours} hora` : `${hours} horas`);
        }

        if (minutes > 0) {
            difference.push((minutes === 1) ? `${minutes} minuto` : `${minutes} minutos`);
        }

        if (seconds > 0) {
            difference.push((seconds === 1) ? `${seconds} segundo` : `${seconds} segundos`);
        }

        return difference.join(', ');
    }

    static isDateBetweenEq(date: Date, init: Date, end: Date): boolean {
        return date >= init && date <= end;
    }

    static isDateBetween(date: Date, init: Date, end: Date): boolean {
        return date > init && date < end;
    }

    static isInvalidDate(date: Date): boolean {
        return date == null || isNaN(date.getTime());
    }

    static getMinutesBetween(dt1: Date, dt2: Date): number {
        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    }

}
