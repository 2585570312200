import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Equipment } from '../model/equipment';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EquipmentAirService {

  private calibrationSubject = new BehaviorSubject<Equipment>(new Equipment());
  private unreadSubject = new BehaviorSubject<number>(0);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public course$ = this.calibrationSubject.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public unread$ = this.unreadSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  getExcelTemplate(): Observable<any> {
    const url = environment.quotit + '/equipmentAir/excel-template';

    return this.http.get(url, { responseType: 'blob' });
  }
}
