import { Component, OnInit, } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";


@Component({
    selector: 'app-projectNumber-dialog',
    templateUrl: './quote-edit-projectNumber.html'
})

export class ProjectNumberComponent implements OnInit {
    projectNumber: string;

    constructor(
        public dialogRef: MatDialogRef<ProjectNumberComponent>,
    ) {}
    
    ngOnInit(): void {
        this.projectNumber = "";
    }

    onOkClick(): void {
        this.dialogRef.close(this.projectNumber);
    }

    onNoClick(): void {
        this.dialogRef.close(null);
    }

}