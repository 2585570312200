import { ViewportScroller, Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import { ComercialDataSource } from 'src/app/model/comercialDataSourc';
import { ComercialFilter } from 'src/app/model/commercial';
import { SpinnerService } from 'src/app/service/spinner.service';
import { filter, takeUntil, tap } from "rxjs/operators";
import { CommercialService } from 'src/app/service/commercial.service';

@Component({
  selector: 'app-comercial-list',
  templateUrl: './comercial-list.component.html'
})
export class ComercialListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: ComercialDataSource;
  private destroy$ = new Subject<void>();
  filter: ComercialFilter = new ComercialFilter();
  hasFiltered = false;
  displayedColumns: string[] = [
    'name'
  ]

  constructor(
    private router: Router,
    private commercialService: CommercialService,
    private viewportScroller: ViewportScroller,
    public dialog: MatDialog,
    private translate: TranslateService,
    private spinnerService: SpinnerService,
    private location: Location) {
    this.router.events.pipe(filter(e => e instanceof Scroll)).pipe(takeUntil(this.destroy$)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  ngOnInit(): void {
    this.loadComercialList();
  }

  loadComercialList(): void {
    this.dataSource = new ComercialDataSource(this.commercialService);

    this.filter.sortBy = this.sort.active || 'id';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 10;

    if (this.hasFiltered) {
      this.dataSource.loadComercials(this.filter);
    }

    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  doFilter(event: ComercialFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadComercialList();
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  ngAfterViewInit(): void {
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadComercialList())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  cancel(): void {
    this.location.back();
  }
}
