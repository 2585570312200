<div class="main-container">
  <form #protocolListForm="ngForm">
    <div class="toolbar-list">

      <!-- INI TITULO Y MIGAS DE PAN-->
      <div class="toolbar-list-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h1 class="accent-color">{{ 'clientList.title' | translate }}</h1>
        </div>

        <div class="breadcrumbs">
          <a href="#">{{ 'common.start.label' | translate }}</a> / <span>{{ 'clientList.title' | translate }}</span>
        </div>
      </div>
      <!-- FIN TITULO Y MIGAS DE PAN-->

      <!-- NUEVO CLIENTE -->
      <div class="toolbar-list-actions">
        <button mat-raised-button (click)="OpenDialog()" color="accent"
          matTooltip="{{'button.disabled.offline' | translate }}">
          <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.new' | translate }}
        </button>
      </div>

      <!-- VOLER PAGINA ANTERIOR-->
      <div class="toolbar-back-button">
        <a (click)="backPreviousPage()" mat-button color="accent">
          <mat-icon color="accent">undo</mat-icon> {{ 'button.back' | translate }}
        </a>
      </div>

    </div>

    <!-- FILTROS -->
    <app-client-list-filter id="appClientListFilter" #filterComponent (filterEmitter)="doFilter($event)"
      class="filter-container">
    </app-client-list-filter>

    <!-- INI TABLA RESULTADO DE LOS FILTROS DE BUSQUEDA -->
    <div id="clientListResult" class="table-container">
      <table #protocols mat-table matSort [dataSource]="dataSource">

        <!-- COLUMN name -->
        <ng-container id="clientListResultName" matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.name' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.name }} </td>
        </ng-container>

        <!-- COLUMN address -->
        <ng-container id="clientListResultAddress" matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.address' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.address }} </td>
        </ng-container>

        <!-- COLUMN postalCode -->
        <ng-container id="clientListResultPostalCode" matColumnDef="postalCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.postalCode' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.postalCode }} </td>
        </ng-container>

        <!-- COLUMN city -->
        <ng-container id="clientListResultCity" matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.city' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.city }} </td>
        </ng-container>

        <!-- COLUMN state -->
        <ng-container id="clientListResultState" matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.state' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.state }} </td>
        </ng-container>

        <!-- COLUMN country -->
        <ng-container id="clientListResultCountry" matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.country' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.country }} </td>
        </ng-container>

        <!-- COLUMN nameBusinessGroup -->
        <ng-container id="clientListResultNameBusinessGroup" matColumnDef="nameBusinessGroup">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.nameBusinessGroup' | translate }}
          </th>
          <td mat-cell *matCellDef="let item"> {{ item.nameBusinessGroup }} </td>
        </ng-container>

        <!-- INI BOTONERA DE ACCIONES LISTA PAGINADA-->

        <!-- Button edit Column -->
        <ng-container id="clientListResultEditColumn" matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="editRow(element.id)" matTooltip="{{'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <!-- Button delete Column --> 
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="deleteClient(element.id)" matTooltip="{{'button.delete' | translate }}">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
      
        <!-- FIN BOTONERA DE ACCIONES LISTA PAGINADA-->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
      <!--
      <span *ngIf="!hasFiltered" class="prefilterMessage">
        <p [innerHtml]="'common.listPlaceholder' | translate | safe: 'html'"> </p>
      </span> -->

    </div>
    <!-- FIN TABLA RESULTADO DE LOS FILTROS DE BUSQUEDA -->


    <div class="table-footer-container">
      <div class="actions">
        <button mat-raised-button (click)="exportTable()" class="stroked-primary" *ngIf="data.isList">
          <mat-icon fxHide.lt-sm>cloud_download</mat-icon> {{ 'button.downloadXls' | translate }}
        </button>
        <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top accent-color" *ngIf="data.isList">
          {{ 'button.backToTop' | translate }} </a>
      </div>

      <div class="paginator">
        <mat-paginator [pageSize]="data.isList ? 10 : 5" [pageSizeOptions]="data.isList ? [5, 10, 20] : [5]"
          (page)="onPageChange($event)" showFirstLastButtons [length]="dataSource.totalItems$ | async" lang="es">
        </mat-paginator>
      </div>
    </div>
  </form>
</div>