import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { EquipmentTypeFilter } from "src/app/model/equipment";

@Component({
    selector: 'app-equipmentType-list-filter',
    templateUrl:  './equipmentType-list-filter.component.html'
  })
  
  export class EquipmentTypeFilterComponent implements OnInit {
  
    @Output() filterEmitter = new EventEmitter<EquipmentTypeFilter>();
  
    filter: EquipmentTypeFilter;
    form: FormGroup;
    isList: boolean;
    expandedFilter = true;
    
    private destroy$ = new Subject<void>();
    
    constructor(
      fb: FormBuilder
    ) {
      this.cleanFilter();
  
      this.form = fb.group({
        name: [this.filter.translate]
      });
    }
  
    ngOnInit(): void {
    }
  
    cleanFilter(): void {
      this.filter = new EquipmentTypeFilter();
    }
  
    doFilter(): void {
      this.expandedFilter = false;
  
      this.filterEmitter.emit(this.filter);
    }
    
  }
  