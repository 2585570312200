export class Test {
    id: number;
    idTestTypeEquipmentConfig:number;
    idType: number;
    idEquipment: number;
    idRoom: number;
    idGas: number;
    time:number;
    reference: string;
    cost: number;
}

export class TestType {
    id: number;
    translate: string;
    isChecked: boolean;
}

export enum TestTypeEnum {
    DRAIN = 1,
    REN_RATE_AND_FLOW = 2,
    TEMP_HUMIDITY = 3,
    LIGHT = 4,
    NOISE = 5,
    RECOVERY = 6,
    INDUCTION = 7,
    AIR_MICRO = 8,
    PARTICLE_COUNT = 26,
    PARTICLE_COUNT_WORKING = 27
}