export class StandarConsultancy {
    id: number;

    idEquipment: number;
    idRoom: number;
    idGas: number;
    idEquipmentThermal: number;

    idType: number;

    price: number;
    cost: number;
    hours: number;
}