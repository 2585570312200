import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpUtils } from '../utils/httpUtils';
import { Country } from '../model/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  url = `${environment.quotit}/country`
  constructor(private http: HttpClient) { }

  findById(id: number) {
    return this.http.get(`${this.url}/${id}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  findAll(): Observable<Country[]> {
    return this.http.get(`${this.url}/`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }
}
