import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { UserFilter } from 'src/app/model/user';

@Component({
  selector: 'app-user-list-filter',
  templateUrl: './user-list-filter.component.html'
})
export class UserListFilterComponent implements OnInit {

  private destroy$ = new Subject<void>();
  expandedFilter = true;
  @Output() filterEmitter = new EventEmitter<UserFilter>();
  filter: UserFilter;

  constructor(
    private route: ActivatedRoute) {
    this.cleanFilter();


  }

  ngOnInit(): void {


  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  cleanFilter(): void {
    this.filter = new UserFilter();
  }
}
