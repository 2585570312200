import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CalibrationSite } from '../model/calibrationSite';

@Injectable()
export class CalibrationSiteService {

    currentLang: string;

    private calibratesSubject = new BehaviorSubject<CalibrationSite>(new CalibrationSite());
    private unreadSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public course$ = this.calibratesSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public unread$ = this.unreadSubject.asObservable();

    constructor(private http: HttpClient) {
    }


   
    findAll(): Observable<any> {
        const url = environment.quotit + '/calibrationSite/';

        return this.http.get<any>(url).pipe(
            map(httpRes => httpRes)
        );
    }

    findOne(id: number): Observable<any> {
        const url = environment.quotit + `/calibrationSite/${id}`;

        return this.http.get<any>(url).pipe(
            map(httpRes => httpRes)
        );
    }
}