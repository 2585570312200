export class ArrayUtils {

    static isEmpty(array: any[]): boolean {
        if (array == null) {
          return true;
        }
    
        return Array.isArray(array) && array.length === 0;
      }
}
