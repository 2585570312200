import { Component, OnDestroy, OnInit } from '@angular/core';

import { LoginService } from 'src/app/service/login.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  isLoggedIn: boolean;

  private destroy$ = new Subject<void>();

  private isLoggedInService = false;
  private isLoginRoute = false;

  constructor(
    private loginService: LoginService,
    private router: Router) { }

  ngOnInit(): void {
    this.loginService.isLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.isLoggedInService = data;
      this.recalculateIsLoggedIn();
    });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      if (data.url) {
        this.isLoginRoute = data.url !== '/login';
        this.recalculateIsLoggedIn();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public mailTo(type: string): void {
    const url = `mailto:feedback@qloudapp.com?subject=${type}`;
    window.open(url, '_blank');
  }

  private recalculateIsLoggedIn() {
    this.isLoggedIn = this.isLoggedInService && this.isLoginRoute;
  }

}
