import { ProviderQuote } from "./providerQuote";

export class Calibration {
    id: number;
    equipmentType: number;
    variableType: number;
    calibrationSite: number;
    calibrationRange: number;
    unitType: number;
    time: number;
    price: number;
    cost: number;
    provider: ProviderQuote;
    costProvider: number;
    workCenter: number;
    outsourced: boolean = false;
    maker: string;
    serie: string;
    indicative: string;
    location: string;
    otherEquipment: string;
    model: string;
    particleCounterType: number;
    verification: boolean;
    describeTimeWorked: string;
    pointCalibration: number;
    amountNotIncluded: boolean;
    preassureRange: string;
}

export class CalibrationDataDTO {
    id: number;
    equipmentType: string;
    unit: string;
    site: string;
    range: string;
    variable: string;
}

export class CalibrateTimeConfig {
    id: number;
    idEquipment: number;
    idUnitVariable: number;
    idRange: number;
    time: number;
    price: number;
    outsourcing: boolean;
}