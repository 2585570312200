export class GenericClass {
    id: number;
    name: string;
}

export class GenericClassTranslate extends GenericClass {
    translation: string;
}

export class GenericUpload {
    totalOk: number;
    totalKo: number;

    created: any[];
}

export class GenericWarnError {
    warns: string[] = [];
    errors: string[] = [];
}

export abstract class GenericFilter {
    sortBy: string;
    sortDirection: string;
    pageIndex: number;
    pageSize: number;
}

export abstract class GenericAttachment {
    id: number;
    filename: string;
    date: Date;
    idType: number;
    nameType: string;

    position: number;
    name: string;
}
