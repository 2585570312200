import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Test, TestType } from '../../../model/test';
import { ExcelUtils } from '../../../utils/excelUtils';
import { TranslateService } from '@ngx-translate/core';
import { TestTypeService } from '../../../service/testType.service';
import { EquipmentTypeService } from '../../../service/equipmentType.service';
import { TestTypeEquipmentConfigService } from '../../../service/testTypeEquipmentConfig.service';
import { TestTypeEquipmentConfig } from '../../../model/testTypeEquipmentConfig';
import { CompresedGas } from '../../../model/compresedGas';
import { saveAs } from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { CompressedGasAirService } from 'src/app/service/compressed-gas-air.service';
import { Subject } from 'rxjs';
import { LoadingService } from '../../../service/loading.service';

@Component({
  selector: 'app-quote-edit-dialog-masive-gas',
  templateUrl: './quote-edit-dialog-masive-gas.component.html'
})
export class MasiveGasComponent implements OnInit {

  displayedColumns = ['ngas', 'name', 'location', 'essay'];
  testTypes: TestType[] = [];
  compressGas: CompresedGas[] = [];
  calibrationsErrors: any[] = [];

  private destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService,
    private testTypeService: TestTypeService,
    private testTypeEquipmentConfigService: TestTypeEquipmentConfigService,
    private compressedGasService: CompressedGasAirService,
    private loader: LoadingService,
    private dialogRef: MatDialogRef<MasiveGasComponent>) { }

  ngOnInit(): void {
    this.testTypeService.findAll().subscribe((res: TestType[]) => {
      this.testTypes = res;
    });

    this.testTypeEquipmentConfigService.findByGas(1).subscribe((res1: TestTypeEquipmentConfig[]) => {
      let testsByEquipment =  res1.filter((item) => item.idTestType !== 26);
      testsByEquipment?.forEach(test => {
        this.testTypes.forEach(type => {
          if (test.idTestType === type.id) {
            test.translation = this.getEssayName(type);
          }
        });
      });
    });
  }

  uploadFile(event): void {
    this.loader.show();
    this.calibrationsErrors = [];
    const file = event.target.files[0] as File;

    void ExcelUtils.excelToCompressGastAir(file, this.testTypeService, this.translateService, this.testTypeEquipmentConfigService).then(data => {
      this.compressGas = data.get('data');
      this.loader.hide();

      if (data.get('error')) {
        const resultError = data.get('error') as [];

        if (resultError?.length > 0) {
          resultError.forEach(er => {
            this.calibrationsErrors.push(er);
          })
        }
      }
    }).catch(() => {
      this.calibrationsErrors.push('Error al leer el documento');
      this.loader.hide();
    });

  }

  downloadFile() {
    this.loader.show();
    this.compressedGasService.getExcelTemplate().pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = 'template-compressedGas.xlsx';
      saveAs(res, name);
      this.loader.hide();
    }, error => {
      console.error(error);
      this.loader.hide();
    });
  }

  onOkClick() {
    this.dialogRef.close(this.compressGas);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  convertListToString(essays: Test[]) {
    let list: String[] = [];
    essays.forEach(a => {
      const testType = this.testTypes.find(f => f.id === a.idType);
      list.push(this.translateService.instant(this.getEssayName(testType)));
    })
    return list?.join(' | ');
  }

  private getEssayName(type: TestType): string {
    return "testType.".concat(type.translate);
  }
}
