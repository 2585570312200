import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { EquipmentType, EquipmentTypeFilter } from "./equipment";
import { BehaviorSubject, Observable, catchError, filter, finalize, map, of, switchMap } from "rxjs";
import { EquipmentTypeService } from "../service/equipmentType.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";


export class EquipmentTypeDataSource implements DataSource<EquipmentType> {


  private coursesSubject = new BehaviorSubject<EquipmentType[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalSubject = new BehaviorSubject<number>(0);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public loading$ = this.loadingSubject.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public totalItems$ = this.totalSubject.asObservable();
  sortBy: string;
  sortDirection: string;
  pageIndex: number;
  pageSize: number;
  sort: MatSort;
  paginator: MatPaginator;

  constructor(private service: EquipmentTypeService) { }

  connect(collectionViewer: CollectionViewer): Observable<EquipmentType[]> {
      return this.coursesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.coursesSubject.complete();
      this.loadingSubject.complete();
      this.totalSubject.complete();
  }

  loadProtocols(filter = new EquipmentTypeFilter()): void {

      this.loadingSubject.next(true);
     
      this.service.findAll2(filter).pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
      ).subscribe(data => {
          if (data && data.content) {
              this.coursesSubject.next(data.content as EquipmentType[]);
              this.totalSubject.next(data.totalElements as number);
          } else if (data) {
              this.coursesSubject.next(data as EquipmentType[]);
              this.totalSubject.next(data.length as number);
          } else {
              this.coursesSubject.next([]);
              this.totalSubject.next(0);
          }
      });
  }

}
