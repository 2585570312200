<h1>{{ 'quoteEdit.projectNumber.title' | translate }}</h1>
<form fxLayout="column">
    <div>{{ 'quoteEdit.projectNumber.general' | translate }}</div>
    <div mat-dialog-content>

        <!-- Text input for projectNumber -->
        <div fxFlex="20%" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="projectNumber" name="projectNumber" type="text">
            </mat-form-field>
        </div>
    </div>
   
    <!-- Botonera -->
    <div mat-dialog-actions class="project" fxLayout="row" fxLayoutAlign="end center">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>