import { BehaviorSubject, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ProfileConsultancyType } from '../model/profileConsultancyType';

@Injectable()
export class ProfileConsultancyTypeService {

    /* currentLang: string; */

    private courseSubject = new BehaviorSubject<ProfileConsultancyType>(new ProfileConsultancyType());
    /* private unreadSubject = new BehaviorSubject<number>(0); */


    constructor(private http: HttpClient) {
    }

    findAll(): Observable<any> {
        const url = environment.quotit + '/profileConsultancyType';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
