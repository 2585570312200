interface QueryParam {
    key: string;
    value: any;
}

class ServiceUtil {
    /**
     * @description Devuelve una URI codificada con query params
     * @param url La dirección principal sin '?'
     * @param params Array de un objeto <b>QueryParam</b>
     * @see QueryParam
     * @author Felipe Ariza
     */
    static createQueryParams = (url: string, params: QueryParam[]) => {
        let newUrl = `${url}?`;
        params.forEach((f, i) => {
            if (i == 0)
                newUrl += `${f.key}=${f.value}`;
            else
                newUrl += `&${f.key}=${f.value}`
        });
        return encodeURI(newUrl);
    }

    static createQueryParamsFilter = (url: string, params: QueryParam[]) => {
        let newUrl = `${url}`;
        params.forEach((f, i) => {
            if (f.value) {
                if (i === 0) {
                    newUrl += `?${f.key}=${f.value}`;
                } else {
                    newUrl += `&${f.key}=${f.value}`;
                }
            }
        });
        return encodeURI(newUrl);
    }

    /**
     * @description Crea un FormData con body params
     * @param params Array de un objeto <b>QueryParam</b>
     * @see QueryParam
     * @author Andrés Mtz
     */
    static createFormData = (params: QueryParam[]) => {
        const formData = new FormData();
        params.forEach(param => {
            formData.append(param.key, param.value);
        });
        return formData;
    }
}

export {
    ServiceUtil,
    QueryParam,
};
