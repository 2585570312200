<div mat-dialog-content style="white-space: pre-line;">
    Selecciona las veces que quieres duplicar el equipo
  </div>
  <div mat-dialog-actions >
    <div fxFlex>
        <mat-form-field class="campoFormulario">
            <input matInput type="number" [(ngModel)]="amount" name="time" required
                placeholder="{{ 'duplicate.dialog.amount.label' | translate }}">
            <mat-error>
                {{ 'duplicate.dialog.amount.error' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <button mat-raised-button color="primary" (click)="onOkClick()" >
        {{ 'common.yes' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">
      {{ 'common.no' | translate }}
    </button>
  </div>