import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CAcceptanceCriteria } from '../model/CAcceptanceCriteria';

@Injectable()
export class CAcceptanceCriteriaService {

  constructor(private http: HttpClient) { }

  /**
    * Find all CAcceptanceCriterias
    *
    * @return The List CAcceptanceCriteria
    */
  findAllCAcceptanceCriteria(): Observable<CAcceptanceCriteria[]> {
    const url = environment.quotit + '/acceptanceCriteria/findAll/';

    return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

}
