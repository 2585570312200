import { QuoteAttachmentFilter, QuoteDTO, QuoteFilter } from '../model/quote';

import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class QuoteService {

    baseUrl = environment.quotit + '/quotes';
    constructor(private http: HttpClient) { }

    findOne(id: number): Observable<any> {
        const url = environment.quotit + `/quotes/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAll(filter: QuoteFilter): Observable<any> {
        const url = environment.quotit + '/quotes/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllActive(filter: QuoteFilter): Observable<any> {
        const url = environment.quotit + '/quotes/';
        filter.active = true;
        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getExcel(filter: QuoteFilter): Observable<any> {
        const url = environment.quotit + '/quotes/excel';

        return this.http.post(url, filter, { responseType: 'blob' });
    }

    delete(id: number): Observable<any> {
        const url = environment.quotit + `/quotes/${id}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    deActivate(id: number): Observable<any> {
        const url = environment.quotit + `/quotes/deactivate/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(quote: QuoteDTO): Observable<any> {
        const url = environment.quotit + '/quotes/save';

        return this.http.post(url, quote).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    saveQuoteTotalAmount(idQuote: number): Observable<any> {
        const url = environment.quotit + `/quotes/saveQuoteTotalAmount/${idQuote}`;

        return this.http.post(url, idQuote).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getPdf(id: number): Observable<any> {
        const url = environment.quotit + `/report/try2/${id}`;

         return this.http.get<Blob>(url, {responseType : 
         'blob' as 'json'});
    }

    saveVersion(quoteId: number, newVersionReason: string): Observable<any> {
        const url = `${this.baseUrl}/save-version`;
        const body = { quoteId, newVersionReason };
        return this.http.post(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllByIdVersionControl(idVersionControl: number): Observable<any> {
        const url = `${this.baseUrl}/quote-by-idversion/${idVersionControl}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    saveVersionEnd(quote: QuoteDTO): Observable<any> {
        const url = `${this.baseUrl}/save-version-end`;
        return this.http.post(url, quote).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    countOffertsByStatus(idStatus: number): Observable<any> {
        const url = `${this.baseUrl}/count-offert-by-status/${idStatus}`;
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    sumOffertsByStatus(idStatus: number): Observable<any> {
        const url = `${this.baseUrl}/sum-offerts-by-status/${idStatus}`;
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
    commercialTotalAmountWithOffertsByStatus(idStatus: number): Observable<any> {
        const url = `${this.baseUrl}/commercial-amount-offerts-by-status/${idStatus}`;
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
    commercialCountWithOffertsByStatus(idStatus: number): Observable<any> {
        const url = `${this.baseUrl}/commercial-count-offerts-by-status/${idStatus}`;
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
    quoteCountByMonths(year: number): Observable<any> {
        const url = `${this.baseUrl}/quote-count-by-months/${year}`;
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
    quoteCountByDays(year: number, month: number): Observable<any> {
        let url;
        if (month < 10) {
            url = `${this.baseUrl}/quote-count-by-days/${year}/0${month}`;
        } else {
            url = `${this.baseUrl}/quote-count-by-days/${year}/${month}`;
        }
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    quoteCountByMonthsCurrent(year: number, month: number): Observable<any> {
        let url;
        if (month < 10) {
            url = `${this.baseUrl}/quote-count-month-current/${year}/0${month}`;
        } else {
            url = `${this.baseUrl}/quote-count-month-current/${year}/${month}`;
        }
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAttachments(id: number, filter: QuoteAttachmentFilter): Observable<any> {
        const url = `${this.baseUrl}/${id}/attachment`;

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    moveAttachment(id: number, previousIndex: number, currentIndex: number): Observable<any> {
        const url = `${this.baseUrl}/${id}/attachment/move`;

        return this.http.post(url, { previousIndex, currentIndex }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAttachmentNoScope(id: number): Observable<any> {
        const url = `${this.baseUrl}/${id}/no-scope/attachment`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
