<form>
    <mat-accordion>
        <mat-expansion-panel [expanded]="expandedFilter" (opened)="expandedFilter = true"
            (closed)="expandedFilter = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'comercialList.filter.title' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                <div fxFlex="20" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{ 'comercialList.filter.name.label' | translate }}"
                            name="quoteNumber" [(ngModel)]="filter.name">
                    </mat-form-field>
                </div>

            </div>

            <mat-accordion>
                <mat-expansion-panel expanded="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ 'comercialList.filter.advanced.title' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                       <!-- <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <input matInput placeholder="{{ 'comercialList.filter.registrationUser.label' | translate }}"
                                    name="registrationUser" [(ngModel)]="filter.registrationUser">
                            </mat-form-field>
                        </div>
-->
                    </div>

                </mat-expansion-panel>
            </mat-accordion>

            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
                <button mat-raised-button (click)="doFilter()" color="primary">
                    {{ 'button.filter' | translate }}</button>
                <button mat-button (click)="cleanFilter()">{{ 'button.clean' | translate }}</button>
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</form>