export class TestThermal{
    id: number; 
    type: number;
    amount : number;
    setPoint: number;
    charge: string;
    duration: number;
    durationString: string;
    robeId:number;
    criteria: string;
    numberTrials: number;
    idEquipment:number;
    cost: number;
    price: number;
    hours?: number;
    position: number;
    scopeJourney: number;
   }

   export enum TestTypeDuration {
    ELECTRIC_CUT = 11,
    ALARM_TEST = 12,
    PRESSURE_TEST = 13,
    VACUUM_TEST = 14,
    LEAK_TEST = 15,
    LOADED_PENETRATION_TEST = 10,
    LOADED_DISTRIBUTION_TEST = 18,
    VACUM_DISTRIBUTION_TEST = 17,
    ENDOTOXIN_SUPPLY = 24,
    ENDOTOXIN_SUPPLY_AND_ANALYSIS = 25,
    BIOINDICATORS_SUPPLY = 26,
    DOOR_OPENING = 9,
    BOWIE_DICK=16
  
}