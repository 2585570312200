import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-newVersion-dialog',
    templateUrl: './quote-edit-dialog-newVersion.html'
})

export class NewVersionDialogComponent implements OnInit {
    newVersionReason: string;

    constructor(    
        public dialogRef: MatDialogRef<NewVersionDialogComponent>,
    ) { }

    ngOnInit(): void {
        this.newVersionReason = "";
    }

    onOkClick(): void {
        this.dialogRef.close(this.newVersionReason);
    }

    onNoClick(): void {
        this.dialogRef.close(null);
    }
}