import { GenericFilter } from "./genericClass";

export class Course {

    id: number;
    name: string;
    code: string;
    points: number;
	price: number;
    duration: number;
    description: string;
    objetive: string;
    diary: string;
    onlinePrice: number;
    difficultyId: number;
}

export class CourseFilter extends GenericFilter {

    id: number;
    name: string;
}

export class CourseModality {

    id: number;
    translate: string;
}