import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { ConsultancyType } from 'src/app/model/ConsultancyType';

import { Equipment, EquipmentType, EquipmentTypeEnum } from 'src/app/model/equipment';
import { ProviderQuote } from 'src/app/model/providerQuote';
import { StandarConsultancy } from 'src/app/model/standarConsultancy';
import { Test, TestType } from 'src/app/model/test';
import { TestTypeEquipmentConfig } from 'src/app/model/testTypeEquipmentConfig';
import { ConsultancyTypeService } from 'src/app/service/consultancyType.service';
import { EquipmentTypeService } from 'src/app/service/equipmentType.service';
import { SnackBarService } from 'src/app/service/snackBar.service';
import { TestTypeService } from 'src/app/service/testType.service';
import { TestTypeEquipmentConfigService } from 'src/app/service/testTypeEquipmentConfig.service';
import { Constants } from 'src/app/utils/constants';
import { QuoteEditRoomDialogComponent } from '../quote-edit-room-dialog/quote-edit-room-dialog.component';
import { TestTypeEnum } from '../../../model/test';

export interface DialogDataEquipment {
  equipment: Equipment;
  isEdit: boolean;
  showAir: boolean;
  showQualis: boolean;
}

@Component({
  selector: 'app-quote-edit-equipment-dialog',
  templateUrl: './quote-edit-equipment-dialog.component.html'
})
export class QuoteEditEquipmentDialogComponent implements OnInit {

  tituloDialog: string;
  countEssayCriteriasRest: TestTypeEquipmentConfig[] = [];
  countEssayCriteriasWorking: TestTypeEquipmentConfig[] = [];
  // countEssayCriterias: string[] = [];
  equipmentTypes: EquipmentType[] = [];
  testsByEquipment: TestTypeEquipmentConfig[] = [];
  testTypes: TestType[] = [];
  consultancyTypes: ConsultancyType[];
  
  equipmentNumber = [EquipmentTypeEnum.CPP_FLUJOS];
  equipmentModel = [EquipmentTypeEnum.CABINAS, EquipmentTypeEnum.CLIMATIZADOR, EquipmentTypeEnum.AISLADORES, EquipmentTypeEnum.CPP_FLUJOS, EquipmentTypeEnum.EXTRAC_TALLADO];
  equipmentSerial = [EquipmentTypeEnum.CABINAS, EquipmentTypeEnum.CLIMATIZADOR, EquipmentTypeEnum.AISLADORES, EquipmentTypeEnum.CPP_FLUJOS, EquipmentTypeEnum.EXTRAC_TALLADO];
  equipmentLocation = [EquipmentTypeEnum.CABINAS, EquipmentTypeEnum.CLIMATIZADOR, EquipmentTypeEnum.AISLADORES, EquipmentTypeEnum.CPP_FLUJOS, EquipmentTypeEnum.EXTRAC_TALLADO];
  equipmentCount = [EquipmentTypeEnum.CABINAS, EquipmentTypeEnum.CLIMATIZADOR, EquipmentTypeEnum.AISLADORES, EquipmentTypeEnum.CPP_FLUJOS];
  equipmentSurface = [EquipmentTypeEnum.AISLADORES, EquipmentTypeEnum.CPP_FLUJOS];
  equipmentFilter = [EquipmentTypeEnum.AISLADORES, EquipmentTypeEnum.CPP_FLUJOS];
  equipmentDiffuser = [EquipmentTypeEnum.AISLADORES, EquipmentTypeEnum.CPP_FLUJOS];
  equipmentMicro = [EquipmentTypeEnum.CPP_FLUJOS];
  equipmentCountPoints = [EquipmentTypeEnum.CPP_FLUJOS];
  isWorking: boolean;
  isRest: boolean;
  constructor(
    private equipmentTypeService: EquipmentTypeService,
    private testTypeEquipmentConfigService: TestTypeEquipmentConfigService,
    private testTypeService: TestTypeService,
    public snackBarService: SnackBarService,
    private translate: TranslateService,
    private consultancyTypeService: ConsultancyTypeService,
    private dialogRef: MatDialogRef<QuoteEditRoomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipment
  ) {
   
    if (data.equipment.provider != null) {
      data.equipment.outsourced = true;
    }
  }

  ngOnInit(): void {
    if(this.data.equipment.idType != null) this.findByEquipment(this.data.equipment.idType, this.data.isEdit);

    this.equipmentTypeService.findAllByIdAplication(1).subscribe((res: EquipmentType[]) => {
      this.equipmentTypes = res;
      if (this.data.isEdit) {
        const equipmentType = this.equipmentTypes.find(a => a.id === this.data.equipment.idType);
        if (equipmentType) {
          const nameKey = `equipmentType.${equipmentType.translate}`;
          const name = this.translate.instant(nameKey);
          this.tituloDialog = this.translate.instant('quoteEdit.dialog.equipment.title.edit', { name });
        }
      } else {
        this.tituloDialog = this.translate.instant('quoteEdit.dialog.equipment.title.create');
      }
    });

    this.consultancyTypeService.getStandarType().subscribe((types: ConsultancyType[]) => {
      this.consultancyTypes = types;
      this.consultancyTypes.forEach(type => {
        if (this.data.isEdit) {
          this.data?.equipment?.qualifications.forEach(quali => {
            if (quali.idType === type.id) {
              type.isChecked = true;
            }
          });
        }
      })
    });

    if (this.data.isEdit) {

      this.testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdEquipment(this.data?.equipment.idType, Constants.ID_TYPE_WORKING).subscribe((res: any[]) => {
        this.countEssayCriteriasWorking = res;
          if (this.data?.equipment?.countTestWorking) {
            this.data.equipment.idCountEssayCriteriaWorking = this.countEssayCriteriasWorking.find(e => e.reference === this.data.equipment.countTestWorking.reference)?.id
          }
      });

      this.testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdEquipment(this.data?.equipment.idType, Constants.ID_TYPE_REST).subscribe((res: any[]) => {
        this.countEssayCriteriasRest = res;
          if (this.data?.equipment?.countTestRest) {
            this.data.equipment.idCountEssayCriteria = this.countEssayCriteriasRest.find(e => e.reference === this.data.equipment.countTestRest.reference)?.id
          }
      });
    }
  }

  onOkClick(): void {
    const errs: string[] = [];

    if (this.data.equipment.inventoryNumber == null && this.showNumber) {
      errs.push(this.translate.instant('quoteEdit.dialog.equipment.number.error') as string);
    }

    if (this.data.equipment.model == null && this.showModel) {
      errs.push(this.translate.instant('quoteEdit.dialog.equipment.model.error') as string);
    }
    if (this.data.equipment.location == null && this.showlocation) {
      errs.push(this.translate.instant('quoteEdit.dialog.equipment.location.error') as string);
    }
    if(this.data.equipment.outsourced){
      if (this.data.equipment.provider.cost ==null) {
        errs.push(this.translate.instant('quoteEdit.dialog.equipment.provider.coste.error') as string);
      }
      if (this.data.equipment.provider?.cost <=0) {
        errs.push(this.translate.instant('quoteEdit.dialog.equipment.provider.coste0.error') as string);
      }
      if (this.data.equipment.provider.id==null) {
        errs.push(this.translate.instant('quoteEdit.dialog.equipment.provider.error') as string);
      }
    }

    if (this.data.equipment?.tests) {
      const vRespose = this.data.equipment?.tests.find(e => e.idType === Constants.ID_TYPE_REST);

      if (vRespose) {
        if (!this.data.equipment.idCountEssayCriteria) {
          errs.push(this.translate.instant('quoteEdit.dialog.equipment.particleCount.error') as string);
        } else if(this.data.equipment.idCountEssayCriteria.toString() === "null") {
          errs.push(this.translate.instant('quoteEdit.dialog.equipment.particleCount.error') as string);
        } else if (!vRespose.reference || vRespose.reference=== "null"){
          errs.push(this.translate.instant('quoteEdit.dialog.equipment.particleCountWorking.error') as string);
        }
      }

      const vWork = this.data.equipment?.tests.find(e => e.idType === Constants.ID_TYPE_WORKING);

      if (vWork) {
        if (!this.data.equipment.idCountEssayCriteriaWorking) {
          errs.push(this.translate.instant('quoteEdit.dialog.equipment.particleCountWorking.error') as string);
        } else if(this.data.equipment.idCountEssayCriteriaWorking.toString() === "null") {
          errs.push(this.translate.instant('quoteEdit.dialog.equipment.particleCountWorking.error') as string);
        } else if (!vWork.reference || vWork.reference=== "null"){
          errs.push(this.translate.instant('quoteEdit.dialog.equipment.particleCountWorking.error') as string);
        }
      }
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.equipment);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onTypeChange(event: MatSelectChange, isLoading = false): void {
    this.data.equipment = new Equipment();
    this.data.equipment.idType = event.value;
    this.findByEquipment(event.value, false);
    this.testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdEquipment(event.value, Constants.ID_TYPE_REST).subscribe((res: any[]) => {
      this.countEssayCriteriasRest = res;
    });

    this.testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdEquipment(event.value, Constants.ID_TYPE_WORKING).subscribe((res: any[]) => {
      this.countEssayCriteriasWorking = res;
    });
  }

  private findByEquipment(id: number, isEdit: boolean): void {
    this.testTypeService.findTestTypeAllByEquipment(id).subscribe((res: TestType[]) => {
      this.testTypes = res;

      if (isEdit) {
        res?.forEach(testSave => {
          const resultFind = this.data.equipment.tests.find(t => t.idType === testSave.id);
          if (resultFind) {
            testSave.isChecked = true;
          }
        });
      }
    });
  }

  onCountCriteriaChangeWorking(event: MatSelectChange): void {
    this.data.equipment.tests.forEach(e => {
      if (e.idType === Constants.TEST_TYPE_PARTICLE_COUNT_WORKING) {
        const countResult = this.countEssayCriteriasWorking.find(p => p.id === event.source.value);
       if (countResult) {
        e.reference = countResult.reference;
       }
      }
    });    
  }

  onCountCriteriaChangeRest(event: MatSelectChange): void {
    this.data.equipment.tests.forEach(e => {
      if (e.idType === Constants.TEST_TYPE_PARTICLE_COUNT) {
        const countResult = this.countEssayCriteriasRest.find(p => p.id === event.source.value);
       if (countResult) {
        e.reference = countResult.reference;
       }
      }
    });    
  }

  showNumber(): boolean {
    return this.equipmentModel.includes(this.data.equipment.idType);
  }

  showModel(): boolean {
    return this.equipmentModel.includes(this.data.equipment.idType);
  }

  showSerialNumber(): boolean {
    return this.equipmentSerial.includes(this.data.equipment.idType);
  }

  showlocation(): boolean {
    return this.equipmentLocation.includes(this.data.equipment.idType);
  }

  showCount(): boolean {
    return this.equipmentCount.includes(this.data.equipment.idType);
  }

  showSurface(): boolean {
    return this.equipmentSurface.includes(this.data.equipment.idType);
  }

  showFilter(): boolean {
    return this.equipmentFilter.includes(this.data.equipment.idType);
  }

  showDiffuser(): boolean {
    return this.equipmentDiffuser.includes(this.data.equipment.idType);
  }

  showMicro(): boolean {
    return this.equipmentMicro.includes(this.data.equipment.idType);
  }

  showCountPoints(): boolean {
    return this.equipmentCountPoints.includes(this.data.equipment.idType);
  }

  setOutsourced(completed: boolean) {
    this.data.equipment.outsourced = completed;
    if(completed){
      this.data.equipment.provider = new ProviderQuote();
    }else{
      this.data.equipment.provider = null;
    }
  }

  onCheckTest(event: any): void {
    if (event.checked) {
      let test = new Test();
      test.idEquipment = this.data.equipment.id;
      test.idType = event.idTestType;
      // test.idTestTypeEquipmentConfig = event.id;
      // test.time= event.time
      //test.cost = event.time * event.salePriceHour / 60;
      this.data.equipment.tests.push(test);
    } else {
      const findWorking = this.data.equipment.tests.find(l => l.idType === Constants.TEST_TYPE_PARTICLE_COUNT_WORKING);
      if (!findWorking) {
        this.data.equipment.countTestWorking = null;
      }

      const findRest = this.data.equipment.tests.find(l => l.idType === Constants.TEST_TYPE_PARTICLE_COUNT);
      if (!findRest) {
        this.data.equipment.countTestRest = null;
      }
      this.data.equipment.tests = this.data.equipment.tests.filter(test => test.idType != event.idTestType);
      
    }
    this.showCountRest();
    this.showCountWorking();
  }

  onCheckQualification(event: any): void {

    if (event.checked) {
      let test = new StandarConsultancy();
      test.idEquipment = this.data.equipment.id;
      test.idType = event.id;
      this.data.equipment.qualifications.push(test);
    } else {
      this.data.equipment.qualifications = this.data.equipment.qualifications.filter(test => test.idType != event.id);
    }
  }

  onProviderQuoteEmitter(event: any): void {
    this.data.equipment.provider = event;
  }

  private getEssayName(type: TestType): string {
    return "testType.".concat(type.translate);
  }

  showCountRest(): boolean {
    return this.data.equipment.tests?.find(e => e.idType === Constants.TEST_TYPE_PARTICLE_COUNT_WORKING) ? true : false;
  }

  showCountWorking(): boolean {
    return this.data.equipment.tests?.find(e => e.idType === Constants.TEST_TYPE_PARTICLE_COUNT) ? true : false;
  }

}
