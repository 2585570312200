<div class="main-container">
    <form>
  
      <div class="toolbar-list">
  
        <div class="toolbar-list-title">
          <div fxLayout="row" fxLayoutAlign="start center">
            <h1 class="accent-color">{{ 'quoteList.title' | translate }}</h1>
          </div>
  
          <div class="breadcrumbs">
            <a href="#">{{ 'common.start.label' | translate }}</a> / <span>{{ 'quoteList.title' | translate }}</span>
          </div>
        </div>
  
        <div class="toolbar-list-actions">
        </div>
  
        <div class="toolbar-back-button">
          <a (click)="cancel()" mat-button color="accent">
            <mat-icon color="accent">undo</mat-icon> {{ 'button.back' | translate }}
          </a>
        </div>
      </div>
  
      <app-comercial-list-filter (filterEmitter)="doFilter($event)" class="filter-container" >
      </app-comercial-list-filter>
  
      <div class="table-container">
        <table #quotes mat-table matSort [dataSource]="dataSource">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'comercialList.cols.name' | translate }} </th>
            <td mat-cell *matCellDef="let item" > {{ item.name }} </td>
          </ng-container>

          <!-- Button download Column 
          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef colspan="3" fxHide.lt-lg> {{ 'quoteList.cols.actions' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
                <button mat-icon-button (click)="downloadPdf(element)" matTooltip="{{ 'button.download' | translate }}">
                  <mat-icon>cloud_download</mat-icon>
                </button>
            </td>
          </ng-container>-->
  
          <!-- Button edit Column
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
              <button mat-icon-button (click)="editRow(element.id)" matTooltip="{{ 'button.edit' | translate }}">
                <mat-icon>mode_edit</mat-icon>
              </button>
            </td>
          </ng-container>-->
  
          <!-- Button delete Column 
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
                <button mat-icon-button (click)="deleteRow(element.id)" 
                   matTooltip="{{ 'button.delete' | translate }}">
                  <mat-icon>speaker_notes_off</mat-icon>
                </button>
            </td>
          </ng-container>-->
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        </table>
  
        <span *ngIf="!hasFiltered" class="prefilterMessage">
          <p [innerHtml]="'common.listPlaceholder' | translate | safe: 'html'"> </p>
        </span>
      </div>
  
      <div class="table-footer-container">
        <div class="actions">
          <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top accent-color">
            {{ 'button.backToTop' | translate }} </a>
        </div>
        <div class="paginator">
          <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
            [length]="dataSource.totalItems$ | async" lang="es">
          </mat-paginator>
        </div>
      </div>
    </form>
  </div>