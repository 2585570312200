import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpUtils } from '../utils/httpUtils';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  baseUrl = environment.quotit + '/unit/';
  constructor(private http: HttpClient) { }

  findAll(): Observable<any> {
    return this.http.get(this.baseUrl).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  findById(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/${id}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }
}
