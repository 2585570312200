import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpUtils } from '../utils/httpUtils';
import { VariableType } from '../model/VariableType';

@Injectable({
  providedIn: 'root'
})
export class VariableTypeService {

  baseUrl = environment.quotit + '/variableType/';
  constructor(private http: HttpClient) { }

  findAll(): Observable<any> {
    return this.http.get(this.baseUrl).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  findById(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/${id}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  getAllVariableTypeByEquipment(idEquipment: number): Observable<VariableType[]> {
    const url = `${this.baseUrl}byEquipment/${idEquipment}`;
    return this.http.get<VariableType[]>(url);
  }
}
