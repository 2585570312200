
<mat-dialog-content>
  <ng-container *ngIf="data.message">
    <p>{{ data.message }}</p>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="center">
  <button mat-raised-button color="primary" (click)="onOkClick()">
    {{ data.messageOk }}
  </button>
  <button mat-raised-button color="secondary" (click)="onNoClick()">
    {{ data.messageCancel }}
  </button>
</mat-dialog-actions>
