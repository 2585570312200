import { Component, OnInit } from '@angular/core';
import { Calibration, CalibrationDataDTO } from 'src/app/model/calibration';
import { ExcelUtils } from '../../../utils/excelUtils';
import { VariableType } from '../../../model/VariableType';
import { VariableTypeService } from '../../../service/variableType.service';
import { EquipmentTypeService } from '../../../service/equipmentType.service';
import { UnitService } from '../../../service/unit.service';
import { CalibratesRangeService } from '../../../service/calibratesRange.service';
import { CalibrationSiteService } from 'src/app/service/calibrationSite.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, Subject, firstValueFrom } from 'rxjs';
import { CalibrationService } from 'src/app/service/calibration.service';
import { saveAs } from 'file-saver';
import { MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from 'src/app/service/provider.service';
import { EquipmentType } from '../../../model/equipment';
import { CalibratesRange } from '../../../model/calibratesRange';
import { CalibrationSite } from '../../../model/calibrationSite';
import { Provider } from 'src/app/model/provider';
import { UnitVariable } from '../../../model/unitVariable';
import { Unit } from 'src/app/model/unit';
import { UnitVariableService } from '../../../service/unitVariable.service';
import { SnackBarService } from '../../../services/snackBar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { LoadingService } from 'src/app/service/loading.service';

@Component({
  selector: 'app-quote-edit-dialog-masive-calibration',
  templateUrl: './quote-edit-dialog-masive-calibration.component.html'
})
export class MasiveCalibrationDialogComponent implements OnInit {

  displayedColumns = ['equipment','variable', 'unitType','calibrationSite','calibrateRange', 'nserie', 'indicative', 'location', 'model', 'maker', 'amountNotIncl'];

  displayedColumnsError = ['error'];

  calibrations: Calibration[] = [];
  equipmentTypes: EquipmentType[] = [];
  calibrateRanges: CalibratesRange[] = [];
  calibrationSites: CalibrationSite[] = [];
  providers: Provider[] = [];
  variableTypes: VariableType[] = [];
  unitVariableTypes: UnitVariable[] = [];
  calibrationsData: CalibrationDataDTO[] = [];
  calibrationsErrors: any[] = [];
  private destroy$ = new Subject<void>();
  constructor(private variableTypeService: VariableTypeService,
            private equipmentTypeService: EquipmentTypeService,
            private unitVariableService: UnitVariableService,
            private calibratesRangeService: CalibratesRangeService,
            private calibratesSiteService: CalibrationSiteService,
            private calibrationService: CalibrationService,
            private translateService: TranslateService,
            private providerService: ProviderService,
            private unitService: UnitService,
            private dialogRef: MatDialogRef<MasiveCalibrationDialogComponent>,
            public loader: LoadingService) { }

  ngOnInit(): void { 
    this.equipmentTypeService.findAllByIdAplication(3).subscribe((res: EquipmentType[]) => {
      this.equipmentTypes = res;
    });

    this.calibratesRangeService.findAll().subscribe((res: CalibratesRange[]) => {
      this.calibrateRanges = res;
    });

    this.calibratesSiteService.findAll().subscribe((res: CalibrationSite[]) => {
      this.calibrationSites = res;
    });

    this.providerService.findAll().subscribe((res: Provider[]) => {
      this.providers = res;
    });

    this.variableTypeService.findAll().subscribe((res: VariableType[]) => {
      this.variableTypes = res;
    });

    this.unitVariableService.findAll().subscribe((res: UnitVariable[]) => {
      this.unitVariableTypes = res;
    });
  }

  uploadFile(event): void {
    this.loader.show();
    this.calibrationsErrors = [];
    const file = event.target.files[0] as File;

    void ExcelUtils.excelToCalibration(file, this.equipmentTypeService, this.variableTypeService,
      this.calibratesRangeService, this.unitService, this.calibratesSiteService, this.translateService).then(data => {
        this.calibrations = data.get('data');
        this.loader.hide();

        if (data.get('error')) {
          const resultError = data.get('error') as [];

          if (resultError?.length > 0) {
            resultError.forEach(er => {
              this.calibrationsErrors.push(er);
            })
          }
        }
    }).catch(()=> {
      this.loader.hide();
      this.calibrationsErrors.push('Error al leer el documento');
    });

  }

  downloadFile() {
    this.loader.show();
    this.calibrationService.getExcelTemplate().pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name =  'template-calibration.xlsx';
      this.loader.hide();
      saveAs(res, name);
    }, error => {
      this.loader.hide();
      console.error(error);
    });
  }

  onOkClick() {
    this.dialogRef.close(this.calibrations);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  getNameVariable(id: number) {
    return this.variableTypes.find(v => v.id === id)?.translate;
  }

  getNameEquipment(id: number): string {
    return this.equipmentTypes.find(v => v.id === id)?.translate;
  }

  getNameUnit(id: number): string {
    return this.unitVariableTypes.find(v => v.unitId === id)?.translateUnit;
  }

  getNameSite(id: number) {
    return this.calibrationSites.find(s => s.id === id)?.translate;
  }

  getNameRange(id: number) {
    return this.calibrateRanges.find(s => s.id === id)?.rangeDescription;
  }
}
