 <form fxLayout="column">
        <div mat-dialog-content class="thermal-theme">
          <div fxLayout="row" class="flexwrap">
              <!-- Campo para el nombre del cliente -->
                <div fxFlex="30" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput [(ngModel)]="data.client.name" name="name" type="text"
                      placeholder="{{ 'clientEdit.general.name.label' | translate }}"  />
                    </mat-form-field>
                  </div>
            <!-- Campo para la dirección del cliente -->
         
            <div fxFlex="30" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                  <input matInput [(ngModel)]="data.client.address" name="address" type="text" 
                  placeholder="{{ 'clientEdit.general.address.label' | translate }}" />
                </mat-form-field>
              </div>
      
            <!-- Campo para código postal del cliente -->
            <div fxFlex="30" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                  <input matInput [(ngModel)]="data.client.postalCode" name="postalCode" type="text"
                  placeholder="{{ 'clientEdit.general.postalCode.label' | translate }}" />
                </mat-form-field>
              </div>
           
             <!-- Campo para  la ciudad -->
             <div fxFlex="30" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                  <input matInput [(ngModel)]="data.client.city" name="city" type="text"
                  placeholder="{{ 'clientEdit.general.city.label' | translate }}" />
                </mat-form-field>
              </div>

             <!-- Campo para el estado -->
             <div fxFlex="30" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                  <input matInput [(ngModel)]="data.client.state" name="state" type="text"
                  placeholder="{{ 'clientEdit.general.state.label' | translate }}" />
                </mat-form-field>
              </div>

             <!-- Campo para el país -->
             <div fxFlex="30" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                  <input matInput [(ngModel)]="data.client.country" name="country" type="text"
                  placeholder="{{ 'clientEdit.general.country.label' | translate }}" />
                </mat-form-field>
              </div>

             <!-- Campo para el grupo empresarial -->
             <div fxFlex="30" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                  <input matInput [(ngModel)]="data.client.nameBusinessGroup" name="nameBusinessGroup" type="text"
                  placeholder="{{ 'clientEdit.general.nameBusinessGroup.label' | translate }}" />
                </mat-form-field>
              </div>
            
          </div>
           
        </div>
        <!-- Botonera -->
          <div mat-dialog-actions class="thermal-theme" fxLayout="row" fxLayoutAlign="end center">
            <div fxFlex></div>
            <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
            <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
          </div>
</form>
