import { RouterModule, Routes } from '@angular/router';

import { CourseEditComponent } from './components/course-edit/course-edit.component';
import { CourseListComponent } from './components/course-list/course-list.component';
import { IsLoggedInterceptor } from './interceptors/isLoggedInterceptor';
import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { QuoteListComponent } from './components/quote-list/quote-list.component';
import { QuoteEditComponent } from './components/quote-edit/quote-edit.component';
import { ClientListComponent } from './components/client-list/client-list.component';
import { QuoteDetailComponent } from './components/quote-detail/quote-detail.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { ComercialListComponent } from './components/comercial-list/comercial-list.component';
import { QuoteEssayAirComponent } from './components/quote-edit/quote-essay-air/quote-essay-air.component';
import { EquipmentTypeListComponent } from './components/equipmentType-list/equipmentType-list.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

const routes: Routes = [

  // Login
  { path: 'login', component: LoginComponent, pathMatch: 'prefix' },
  // Login
  { path: 'air', component: QuoteEssayAirComponent, pathMatch: 'prefix' },
  // Course List
  { path: 'courses', component: CourseListComponent, pathMatch: 'prefix', canActivate: [IsLoggedInterceptor] },
  { path: 'course', component: CourseEditComponent, pathMatch: 'prefix', canActivate: [IsLoggedInterceptor] },

  // User detail
  { path: 'user', component: UserEditComponent, pathMatch: 'prefix', canActivate: [IsLoggedInterceptor] },
  // User detail
  { path: 'users', component: UserListComponent, pathMatch: 'prefix', canActivate: [IsLoggedInterceptor] },

  // Quote list
  { path: 'quotes', component: QuoteListComponent, pathMatch: 'prefix', canActivate: [IsLoggedInterceptor] },

  // Quote edit
  { path: 'quote', component: QuoteEditComponent, pathMatch: 'prefix', canActivate: [IsLoggedInterceptor] },
  
  // Quote detail
  { path: 'detail', component: QuoteDetailComponent, pathMatch: 'prefix', canActivate: [IsLoggedInterceptor] },

  // Client list
  { path: 'client', component: ClientListComponent, pathMatch: 'prefix', canActivate: [IsLoggedInterceptor] },

  // Comercial list
  { path: 'comercial', component: ComercialListComponent, pathMatch: 'prefix', canActivate: [IsLoggedInterceptor] },

  // equipmentType list
   { path: 'equipmentType', component: EquipmentTypeListComponent, pathMatch: 'prefix', canActivate: [IsLoggedInterceptor] },

   {
    path: 'dashboard', component: DashboardComponent,pathMatch: 'prefix', canActivate: [IsLoggedInterceptor] 
  },  
  // otherwise redirect to home
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
