export class SnackbarMessage {

    title: string;
    msg: string;

    severity: Severity;

    constructor(title: string | Error, severity: Severity) {
        let tit: string;

        if (title instanceof Error) {
            tit = title.message;
        } else {
            tit = title;
        }

        if (!tit?.startsWith('<!')) {
            this.title = tit;
            this.severity = severity;
        }
    }
}

export enum Severity {
    SUCCESS,
    INFO,
    WARNING,
    ERROR
}
