import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
    providedIn: 'root'
})
export class DrawerService {
    private sidenav: MatSidenav;

    setDrawer(sidenav: MatSidenav): void {
        this.sidenav = sidenav;
    }

    toggle(): void {
        void this.sidenav.toggle();
    }
}
