import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { FormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../../external-components/angular-material.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from '../../pipes/pipes.module';


@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    /* Custom modules */
    AngularMaterialModule,
    /* Otros */
    FlexLayoutModule,
    RouterModule,
    SharedModule,
    PipesModule,
  ],
  exports: [
    DashboardComponent
  ],
})
export class DashboardModule { }
