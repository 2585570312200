import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpUtils } from '../utils/httpUtils';
import { TestThermalDuration } from '../model/testThermalDuration';

@Injectable({
  providedIn: 'root'
})
export class TestThermalDurationService {

  currentLang: string;

  private courseSubject = new BehaviorSubject<TestThermalDuration>(new TestThermalDuration());
  private unreadSubject = new BehaviorSubject<number>(0);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public course$ = this.courseSubject.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public unread$ = this.unreadSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  findAll(): Observable<TestThermalDuration[]> {
      const url = environment.quotit + '/testThermalDuration/';

      return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }
}
