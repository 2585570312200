<form [formGroup]="form">
    <mat-accordion>
      <mat-expansion-panel [expanded]="expandedFilter" (opened)="expandedFilter = true" (closed)="expandedFilter = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'equipmentTypeListt.title' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
  
        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
          <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
            <mat-form-field class="campoFormulario">
              <input matInput placeholder="{{ 'courseList.filter.name.label' | translate }}" formControlName="name">
            </mat-form-field>
          </div>
        </div>
  
        <div fxLayout="row" fxLayout.xs="column" class="filter-buttons">
          <button mat-flat-button color="primary" (click)="doFilter()">{{ 'common.filter.label' | translate }}</button>
          <button mat-flat-button color="warn" (click)="cleanFilter()">{{ 'common.clear.label' | translate }}</button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>