<mat-sidenav-container>

  <mat-sidenav #sidenav mode="over">

    <mat-nav-list>

      <a routerLink="/profile" (click)="sidenav.close()" class="userDivToolbar" *ngIf="isLoggedIn$ | async"
        fxLayout="row" fxLayoutAlign="start center">
        <div class="user-pic">
          <span class="circle" fxLayout="row" fxLayoutAlign="center center">
            <span class="initialLetters">{{ getInitials() }}</span>
          </span>
        </div>

        <div class="user-info">
          <h2>{{ user.fullName }}</h2>
          <p>{{ user.position != null ? user.position : 'role.' + user.roleTranslation | translate }}</p>
        </div>
      </a>

      <a class="first-level" mat-list-item routerLink="/dashboard" (click)="sidenav.close()">
        {{ 'menu.home' | translate }}
      </a>

      <a class="first-level" mat-list-item routerLink="/quotes" (click)="sidenav.close()">
        {{ 'menu.offerts' | translate }}
      </a>


      <!-- CLIENTES Solo para Usurios con Rol: Admin -->
      <a *ngIf="roleUsers.ADMIN===user.roleTranslation" class="first-level" mat-list-item routerLink="/client"
        (click)="sidenav.close()">
        {{'ge-clientList' | translate }}
      </a>
      <a *ngIf="roleUsers.ADMIN===user.roleTranslation" class="first-level" mat-list-item routerLink="/equipmentType"
        (click)="sidenav.close()">
        {{'ge-equipmentType' | translate }}
      </a>
      <a *ngIf="roleUsers.ADMIN===user.roleTranslation" class="first-level" mat-list-item routerLink="/comercial"
        (click)="sidenav.close()">
        {{'ge-comercials' | translate }}
      </a>
      <a *ngIf="roleUsers.ADMIN===user.roleTranslation" class="first-level" mat-list-item routerLink="/courses"
        (click)="sidenav.close()">
        {{'ge-courses' | translate }}
      </a>
      <a *ngIf="roleUsers.ADMIN===user.roleTranslation" class="first-level" mat-list-item matTooltip="soon"
       >
        {{'ge-suppliers' | translate }}
      </a>
      <a *ngIf="roleUsers.ADMIN===user.roleTranslation" class="first-level" mat-list-item routerLink="/users"
        >
        {{'ge-userList' | translate }}
      </a>
      <a *ngIf="roleUsers.ADMIN===user.roleTranslation && false" class="first-level" mat-list-item matTooltip="soon"
        >
        {{'ge-rate' | translate }}
      </a>
    </mat-nav-list>

  </mat-sidenav>

  <mat-sidenav-content fxLayout="column" fxLayoutAlign="space-between" style="overflow-x: inherit;">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </mat-sidenav-content>

</mat-sidenav-container>