import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class IsLoggedInterceptor implements CanActivate {

  constructor(private router: Router, private msalService: MsalService) { }

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {

    const canActivate = this.msalService.instance.getActiveAccount() != null;

    if (!canActivate) {
      void this.router.navigate(['/']);
    }

    return canActivate;
  }
}
