import { AuditDTO } from "./auditDTO";
import { DietDTO } from "./dietDTOs";
import { InterArea } from "./interArea";

export class InterAreaResult {
	name: string;
	id: number;
	idWorkcenter: number;
	dietDTOs: DietDTO[];
	areas : InterArea[] = [];
	sumDiet: number;
	auditDTOs: AuditDTO[]; 
	sumPriceCalibrations: number;
	sumCostCalibrations: number;
	sumTimeCalibrations: number;

	totalSaleThermal: number;
	totalCostThermal: number;
	totalTimeThermal: number;

	totalSaleProfile: number;
	totalCostProfile: number;
	totalTimeProfile: number;

	totalSaleQualificationThermal: number;
	totalCostQualificationThermal: number;
	totalTimeQualificationThermal: number;
}