export class TestTypeEquipmentConfig {

    id: number;
    time: number;
    idEquipmentType: number;
    idRoomType: number;
    idTestType: number;
    idCompressedGasType: number;
    idConsultancy: number;
    salePriceHour: number;
    reference: string;
    
    // 
    idEquipment: number;
    idRoom: number;
    idCompressedGas: number;    
    translation: string;
    isChecked: boolean;
   
}