import { ProviderQuote } from "./providerQuote";
import { StandarConsultancy } from "./standarConsultancy";
import { Test } from "./test";

export class Room {
    id: number;
    roomNumber: number;
    name: string;
    classification: string;
    roomSurface: number;
    terms: string;
    countPoints: number;
    // Contaje Rest
    countTestRest: Test;
     // Contaje Working
    countTestWorking: Test;
    microPoints: number;
    totalFilters: number;
    numberDiffusers: number;
    outsourced: boolean;
    
    idQuote: number;
    idType: number = 1;

    tests: Test[] = [];
    qualifications: StandarConsultancy[] = [];
    
    provider: ProviderQuote;

    idCountEssayCriteria: number;
    idCountEssayCriteriaWorking: number;
  
}

