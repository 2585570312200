// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  development: true,
  versionCheckURL : `https://${location.hostname}/version.json`,
  quotit: `https://${location.hostname}/quotit`,
  //quotit: 'http://localhost:8321/quotit',
  timeStamp: '',
  broadcastChannel: 'quotit-local',
  msalClientId: 'a71eb13d-acdd-4aa1-b083-d56e11646821',
  msalTenantId: '3243a1ba-03e3-48ad-8ffb-fdc157f420a8',
  redirectUri: '/',
  postLogoutRedirectUri: '/',
  msalScope: ['user.read'],
};
/*

export const environment = {
  production: false,
  development: true,
  versionCheckURL : `https://${location.hostname}/version.json`,
  quotit: 'https://quotitdev.qualipharmagroup.com:8321/quotit',
  quotit: 'http://localhost:8080/quotit',
  timeStamp: '',
  broadcastChannel: 'quotit-local',
  msalClientId: 'a71eb13d-acdd-4aa1-b083-d56e11646821',
  msalTenantId: '3243a1ba-03e3-48ad-8ffb-fdc157f420a8',
  redirectUri: '/',
  postLogoutRedirectUri: '/'
};

export const environment = {
  production: false,
  development: true,
  versionCheckURL : `https://${location.hostname}/version.json`,
  quotit: 'https://quotitpre.qualipharmagroup.com:8321/quotit',
  quotit: 'http://localhost:8080/quotit',
  timeStamp: '',
  broadcastChannel: 'quotit-local',
  msalClientId: 'a71eb13d-acdd-4aa1-b083-d56e11646821',
  msalTenantId: '3243a1ba-03e3-48ad-8ffb-fdc157f420a8',
  redirectUri: '/',
  postLogoutRedirectUri: '/'
>>>>>>> development
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
