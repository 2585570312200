<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.workcenter.name" name="name" required
            placeholder="{{ 'quoteEdit.dialog.workcenter.name.label' | translate }}">
          <mat-error>
            {{ 'quoteEdit.dialog.area.name.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%" >
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.workcenter.address" name="address" required
            placeholder="{{ 'quoteEdit.dialog.workcenter.address.label' | translate }}">
        </mat-form-field>
      </div>
    </div>



  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>