import { StandarConsultancy } from "./standarConsultancy";
import { TestThermal } from "./testThermal";
import { ThermalProbe } from "./thermalProbe";

export class EquipmentThermal {

    id: number;
    type: number;
    maker: string;
    equipment: string;
    model: string;
    serialNumber: string;
    identificator: string;
    location: string;
    workcenterId: string;
    calibrationInstrumental: boolean;
    intervention: number;
    
    qualifications: StandarConsultancy[] = [];
    thermalProbe: ThermalProbe[] = [];
    test: TestThermal[] = [];
}