import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ConsultancyType } from 'src/app/model/ConsultancyType';
import { StandarConsultancy } from 'src/app/model/standarConsultancy';
import { ConsultancyTypeService } from 'src/app/service/consultancyType.service';

@Component({
  selector: 'app-quote-consultory-qualifications',
  templateUrl: './quote-consultory-qualifications.component.html'
})
export class QuoteConsultoryQualificationsComponent implements OnInit {

  @Input() qualificationsConfig: ConsultancyType[];
  @Input() qualificationsSaved: StandarConsultancy[];
  
  @Output() consultoryCheckEmitter = new EventEmitter<any>();

  consultancyTypes: ConsultancyType[];
  constructor(
    private consultancyTypeService: ConsultancyTypeService,
  ) { }

  ngOnInit(): void {
    this.consultancyTypeService.getStandarType().subscribe((types: ConsultancyType[]) => {
      this.consultancyTypes = types;
    });
  }

  onCheckQualification(event: MatCheckboxChange, consultancy: ConsultancyType): void {
    this.consultoryCheckEmitter.emit({
      checked: event.checked,
      id:consultancy.id,
    });
  }

  isChecked(config: ConsultancyType): boolean {
    this.qualificationsSaved?.forEach(saved => {
      if (saved.idType === config.id) {
        return true;
      }
    });

    return false;
  }

  showQualiName(idType: number):string {
    let name = '';
    name = this.consultancyTypes.find(eq => eq.id == idType).translate;
    return 'consultancy.type.'.concat(name);
  }
}
