import { AuditComplement } from "./auditComplement";
import { AuditProfile } from "./auditProfile";
import { ConsultancyProfile } from "./consultancyProfile";

export class Audit {
    id: number;
    auditType: number;
    workCenter: number;
    hours: number;
    journey: number;
    price: number;
    idAuditSite: number;
    idProduct: number;
    idCountry: number;
    profiles: ConsultancyProfile[];
    name: string;
    supplier: string;
    auditComplements: AuditComplement[] = [];
}