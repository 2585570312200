<div class="main-container">

    
  
    <mat-card class="loginBox">
      <mat-card-content>
        <div class="appImageContainer">
            <img src="assets/img/quotit_logo_claim.png" class="appImage" alt="Logo de Quotit">
          </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button (click)="login()" color="primary">
            {{ 'button.login' | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  
  </div>