<h1 mat-dialog-title>{{ 'quote.testThermal.title' | translate }}</h1>
<form fxLayout="column">
    <div mat-dialog-content class="quotit-theme">

        <!-- Type select -->
            <div fxFlex="20%" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <mat-select required placeholder="{{ 'quoteEdit.dialog.testThermal.type.label' | translate }}"
                        [(ngModel)]="data.testThermal.type" name="testType" (selectionChange)="onTypeChange($event)">
                        <mat-option *ngFor="let config of testEquipemtConfig" [value]="config.idTestType">
                            {{ getTestName(config.idTestType) | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'quoteEdit.dialog.test.name.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- numbertrials -->
            <div fxFlex="10%" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input required matInput name="numberTrials" type="number" [(ngModel)]="data.testThermal.numberTrials"
                        placeholder="{{ 'quoteEdit.dialog.testThermal.numbertrials.label' | translate }}">
                </mat-form-field>
            </div>
            <!-- Set Point -->
            <div fxFlex="10%" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput name="setPoint" type="number" [(ngModel)]="data.testThermal.setPoint"
                        placeholder="{{ 'quoteEdit.dialog.testThermal.setPoint.label' | translate }}">
                </mat-form-field>
            </div>
            <!-- Charge -->
            <div fxFlex="25%" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput name="charge" type="text" [(ngModel)]="data.testThermal.charge"
                        placeholder="{{ 'quoteEdit.dialog.testThermal.charge.label' | translate }}">
                </mat-form-field>
            </div>
            <!-- Duration -->
            <div fxFlex="10%" fxFlex.lt-md="100%" *ngIf="showDuration">
                <mat-form-field class="campoFormulario">
                    <mat-select placeholder="{{ 'quoteEdit.dialog.testThermal.duration.label' | translate }}"
                        [(ngModel)]="data.testThermal.duration" name="duration">
                        <mat-option *ngFor="let config of testThermalDuration" [value]="config.id">
                            {{config.duration}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'quoteEdit.dialog.test.name.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- DurationString -->
            <div fxFlex="10%" fxFlex.lt-md="100%" *ngIf="!showDuration">
                <mat-form-field class="campoFormulario">
                    <input required matInput name="durationString" type="text" [(ngModel)]="data.testThermal.durationString"
                        placeholder="{{ 'quoteEdit.dialog.testThermal.duration.label' | translate }}">
                </mat-form-field>
            </div>
            <!-- Amount -->
            <div fxFlex="10%" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input required matInput name="amount" type="number" [(ngModel)]="data.testThermal.amount"
                        placeholder="{{ 'quoteEdit.dialog.testThermal.amount.label' | translate }}">
                </mat-form-field>
            </div>
            <!-- Criteria -->
            <div fxFlex="15%" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput name="criteria" type="text" [(ngModel)]="data.testThermal.criteria"
                        placeholder="{{ 'quoteEdit.dialog.testThermal.criteria.label' | translate }}">
                        <mat-error>
                            {{ 'quoteEdit.dialog.test.criteria.error' | translate }}
                        </mat-error>
                </mat-form-field>
            </div>


    </div>


    <!-- Botonera -->
    <div mat-dialog-actions class="thermal-theme" fxLayout="row" fxLayoutAlign="end center">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate
            }}
        </button>
    </div>

</form>