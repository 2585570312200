import { CommonModule } from '@angular/common';
import {  NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { ComercialListFilterComponent } from './comercial-list-filter.component';
import { ComercialListComponent } from './comercial-list.component';

@NgModule({

  declarations: [ComercialListComponent, ComercialListFilterComponent],

  imports: [
    CommonModule,
    FormsModule,
    /* Custom modules */
    AngularMaterialModule,
    /* Otros */
    FlexLayoutModule,
    RouterModule,
    SharedModule,
    PipesModule,

  ],

  exports: [

    ComercialListComponent

  ],

})

export class ComercialListModule { }


