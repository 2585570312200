import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class RoleService {

    constructor(private http: HttpClient) { }

    findAllRoles(): Observable<any> {
        const url = environment.quotit + '/roles/all' ;

        return this.http.get<any[]>(url).pipe(
            map(roles => roles.map(role => ({ id: role.id, name: role.translation })))
          );
  
    }    
}