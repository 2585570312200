import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDataRoleUser } from '../../quote-edit-confirmationHours-dialog/quote-edit-confirmationHours-dialog';

@Component({
  selector: 'app-test-thermal-confirm-journey',
  templateUrl: './test-thermal-confirm-journey.component.html'
})
export class TestThermalConfirmJourneyComponent implements OnInit {

  hours = 0;
  constructor(
      public dialogRef: MatDialogRef<TestThermalConfirmJourneyComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogDataRoleUser
  ) { 
      
   }

  ngOnInit(): void {
 
  }

  onOkClick(): void {
      this.dialogRef.close(this.hours);
  }

  onNoClick(): void {
      this.dialogRef.close(null);
  }

}
