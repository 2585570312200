import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';

import { of } from 'rxjs';
import { QuoteAttachment, QuoteAttachmentFilter } from './quote';
import { QuoteService } from '../service/quote.service';

export class AttachmentDataSource implements DataSource<QuoteAttachment> {

    private quoteSubject = new BehaviorSubject<QuoteAttachment[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: QuoteService) { }

    connect(_collectionViewer: CollectionViewer): Observable<QuoteAttachment[]> {
        return this.quoteSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.quoteSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadAttachment(id: number, filter = new QuoteAttachmentFilter()): void {

        this.loadingSubject.next(true);

        this.service.getAttachments(id, filter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.quoteSubject.next(data.content as QuoteAttachment[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.quoteSubject.next(data as QuoteAttachment[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.quoteSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }
}
