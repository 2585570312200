<h1 mat-dialog-title>{{ 'quoteEdit.attachments.dialog.title'| translate }}</h1>
<form>
  <div mat-dialog-content class="calibrates-theme" *ngIf="data != null && data.attachment != null">

    {{ 'quoteEdit.attachments.cols.filename' | translate }}: {{ data.attachment.filename }}

    <mat-form-field class="campoFormulario">
      <input matInput placeholder="{{ 'quoteEdit.attachments.dialog.name' | translate }}" name="attName"
        [(ngModel)]="data.attachment.name">
    </mat-form-field>

    <mat-form-field class="campoFormulario">
      <mat-label>{{ 'quoteEdit.attachments.dialog.type' | translate }}</mat-label>
      <mat-select name="idType" [ngModel]="data.attachment.idType" (selectionChange)="onTypeChange($event)">
        <mat-option *ngFor="let type of data.types" [value]="type.id">
          {{ 'attachmentType.' + type.translation | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>
  <div mat-dialog-actions class="getTheme()">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>
      {{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate}}</button>
  </div>

</form>