<h1 mat-dialog-title>{{ 'quote.calibration.title' | translate }}</h1>
<form fxLayout="column">

    <div mat-dialog-content class="quotit-theme">
        <div fxFlex="100%" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
                <mat-select required placeholder="{{ 'quoteEdit.dialog.calibration.type.label' | translate }}"
                    [(ngModel)]="data.calibration.equipmentType" name="equipmentType"
                    (selectionChange)="loadVariableTypesByEquipmentType()">
                   <div class="campoFormulario" style="margin:10px">
                        <input class="myInput" #myInput matInput focused="'true'" type="text" (keyup)="filterEquipmentType($event.target.value)" autocomplete="off"
                        [placeholder]="'common.selectFilterPlaceholder' | translate">
                   </div>
                    <mat-divider></mat-divider>
                    <mat-option *ngFor="let equipment of equipmentTypes" [value]="equipment.id">
                        {{ 'equipmentType.' + equipment.translate | translate }}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{ 'quoteEdit.dialog.calibration.equipmentType.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div fxFlex="20%" fxFlex.lt-md="100%" *ngIf="data.calibration.equipmentType === EQUIPMENT_TYPE_OTHER">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="data.calibration.otherEquipment" name="other" type="text"
                    placeholder="{{ 'quoteEdit.dialog.calibrates.other.equipment.label' | translate }}">
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-content class="quotit-theme">
        <div fxFlex="100" *ngIf="data.calibration.equipmentType === isParticleCounter">
            <div fxFlex="40">
                <mat-form-field class="campoFormulario">
                    <mat-select required
                        placeholder="{{ 'quoteEdit.dialog.calibration.particleType.label' | translate }}"
                        [(ngModel)]="data.calibration.particleCounterType" name="particleType">
                        <mat-select-filter [displayMember]="'name'" [array]="particleCountTypes"
                        (filteredReturn)="particleCountFiltered= $event"
                        [noResultsMessage]="'common.noResults' | translate"
                        [placeholder]="'common.selectFilterPlaceholder' | translate">
                        </mat-select-filter>
                        <mat-option *ngFor="let particle of particleCountFiltered" [value]="particle.id" >
                            {{particle.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'quoteEdit.dialog.calibration.variableType.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxFlex="30" fxFlex.lt-md="100%" fxLayoutAlign="start center">
                <mat-checkbox matTooltip="{{ 'quoteEdit.dialog.calibration.verification.label' | translate }}"
                    [(ngModel)]="data.calibration.verification" name="verificationParticle">
                    {{ 'quoteEdit.dialog.calibration.verification.label' | translate }}
                </mat-checkbox>
            </div>

            <div fxFlex="30" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="data.calibration.time" name="time" type="number"
                        placeholder="{{ 'quoteEdit.dialog.calibration.timeWorking.label' | translate }}">
                </mat-form-field>
            </div>
        </div>
        <!-- Type select -->
        <div fxFlex="100" *ngIf="data.calibration.equipmentType != isParticleCounter">
            <div fxFlex="20%" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <mat-select [required]="data.calibration.equipmentType !== EQUIPMENT_TYPE_TEXTURE_ANALYZER" 
                        placeholder="{{ 'quoteEdit.dialog.calibration.variableType.label' | translate }}"
                        [(ngModel)]="data.calibration.variableType" name="variableType"
                        (selectionChange)="onVariableTypeChange()">
                        <mat-option *ngFor="let variable of selectedEquipmentVariables" [value]="variable.id">
                            {{ 'variableType.' + variable.translate | translate }}

                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'quoteEdit.dialog.calibration.variableType.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        
            <!-- Type select -->
            <div fxFlex="20%" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <mat-select [required]="data.calibration.equipmentType !== EQUIPMENT_TYPE_TEXTURE_ANALYZER" 
                        placeholder="{{ 'quoteEdit.dialog.calibration.unitType.label' | translate }}"
                        [(ngModel)]="data.calibration.unitType" name="unitType">
                        <mat-option *ngFor="let unit of unitVariableTypes" [value]="unit.unitId">
                            {{ 'unitType.' + unit.translateUnit | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'quoteEdit.dialog.calibration.unitType.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
           
            <!-- Type select -->
            <div fxFlex="20%" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <mat-select [required]="data.calibration.equipmentType !== EQUIPMENT_TYPE_TEXTURE_ANALYZER"
                        placeholder="{{ 'quoteEdit.dialog.calibration.calibrationSite.label' | translate }}"
                        [(ngModel)]="data.calibration.calibrationSite" name="calibrationSite">
                        <mat-option *ngFor="let site of calibrationSites" [value]="site.id">
                            {{ 'calibrationSite.' + site.translate | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'quoteEdit.dialog.calibration.calibrationSite.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Type select -->
            <div fxFlex="20%" fxFlex.lt-md="100%">
                <ng-container *ngIf="!selectedEquipmentTypeAllowsInput()">
                    <mat-form-field class="campoFormulario">
                        <mat-select [required]="data.calibration.equipmentType !== EQUIPMENT_TYPE_TEXTURE_ANALYZER"
                            placeholder="{{ 'quoteEdit.dialog.calibration.calibrateRange.label' | translate }}"
                            [(ngModel)]="data.calibration.calibrationRange" name="calibrationRange"
                            (selectionChange)="onEquipmentIdVariableIdUnitId()">
                            <mat-option *ngFor="let range of calibrateRanges" [value]="range.id">
                                {{ 'calibrateRange.' + range.rangeDescription | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{ 'quoteEdit.dialog.calibration.calibrateRange.error' | translate }}
                        </mat-error>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="selectedEquipmentTypeAllowsInput()">
                    <mat-form-field class="campoFormulario">
                        <input matInput [(ngModel)]="data.calibration.preassureRange" name="calibrationRangeInput" type="text"
                          placeholder="{{ 'quoteEdit.dialog.calibration.calibrateRange.label' | translate }}">
                      </mat-form-field>
                </ng-container>
            </div>
        </div>
    </div>
    <div mat-dialog-content class="quotit-theme">
        <!-- Type input -->
        <div fxFlex="20%" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="data.calibration.maker" name="maker" type="text"
                    placeholder="{{ 'quoteEdit.dialog.calibrates.maker.label' | translate }}">
            </mat-form-field>
        </div>

        <!-- Type input -->
        <div fxFlex="20%" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="data.calibration.model" name="model" type="text"
                    placeholder="{{ 'quoteEdit.dialog.calibrates.model.label' | translate }}">
            </mat-form-field>
        </div>

        <!-- Type input -->
        <div fxFlex="20%" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="data.calibration.serie" name="serie" type="text"
                    placeholder="{{ 'quoteEdit.dialog.calibrates.serialNumber.label' | translate }}">
            </mat-form-field>
        </div>
        <!-- Type input -->
        <div fxFlex="20%" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="data.calibration.indicative" name=" indicativo" type="text"
                    placeholder="{{ 'quoteEdit.dialog.calibrates.internalIdentifier.label' | translate }}">
            </mat-form-field>
        </div>

        <!-- Type input -->
        <div fxFlex="20%" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="data.calibration.location" name="ubication" type="text"
                    placeholder="{{ 'quoteEdit.dialog.calibrates.ubication.label' | translate }}">
            </mat-form-field>
        </div>

        <div fxFlex="30" fxFlex.lt-md="100%" fxLayoutAlign="start center">
            <mat-checkbox matTooltip="{{ 'quoteEdit.dialog.calibration.amountNotIncluded.label' | translate }}"
                [(ngModel)]="data.calibration.amountNotIncluded" name="amountNotIncluded">
                {{ 'quoteEdit.dialog.calibration.amountNotIncluded.label' | translate }}
            </mat-checkbox>
        </div>
    </div>

    <div fxFlex="100" *ngIf="data.calibration.equipmentType === isParticleCounter">
        <div fxFlex="100">
            <mat-form-field class="campoFormulario">
                <mat-label>{{ 'quoteEdit.dialog.calibration.describeTimeWorked.label' | translate }}</mat-label>
                <textarea matInput name="message"
                #message='ngModel' [(ngModel)]="data.calibration.describeTimeWorked"></textarea>
            </mat-form-field>
        </div>
    </div>
    <mat-toolbar color="secondary">
        {{ 'quoteEdit.dialog.equipment.outsoruced.title' | translate }}
        <div fxFlex="5" fxFlex.lt-md="100%">
            <mat-checkbox style="margin-left: 1em;" [(ngModel)]="data.calibration.outsourced" name="eqOutsource">
            </mat-checkbox>
        </div>
    </mat-toolbar>
    <div fxLayout="row" class="flexwrap">
        <div fxFlex="90" fxFlex.lt-md="100%" *ngIf="data.calibration.outsourced">
            <app-quote-edit-outsources [providerQuote]="data.calibration.provider"
                (providerQuoteEmitter)="onProviderQuoteEmitter($event)"></app-quote-edit-outsources>
        </div>
    </div>
    <!-- Botonera -->
    <div mat-dialog-actions class="thermal-theme" fxLayout="row" fxLayoutAlign="end center">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate
            }}
        </button>
    </div>

</form>