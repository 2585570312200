export class Contact {
    id: number;
    
    fullName: string;
    email:string;
    position: string;
    phone: string;
    idClient: number;

}

