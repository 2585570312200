<h1 >{{ 'quote.confirmationHours.title' | translate }}</h1>
<form fxLayout="column">
    <div>{{ 'quote.confirmHours.general' | translate }}</div>
    <div mat-dialog-content class="quotit-hours">

        <!-- Type input -->
        <div fxFlex="20%" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="hourResponse.hours" name="hours" type="number">
            </mat-form-field>
        </div>
    </div>
   
    <!-- Botonera -->
    <div mat-dialog-actions class="hours" fxLayout="row" fxLayoutAlign="end center">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate
            }}
        </button>
    </div>

</form>