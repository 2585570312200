import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from 'src/app/utils/constants';

@Pipe({ name: 'quoteStatus' })
export class QuoteStatusPipe implements PipeTransform {
  transform(idType: number, type: string) {
      switch(type){
        case Constants.QUOTE_STATUS_PREPARATION: 
        return idType === Constants.QUOTE_ID_STATUS_PREPARATION;
        case Constants.QUOTE_STATUS_SIGNED: 
        return idType === Constants.QUOTE_ID_STATUS_SIGNED;
        case Constants.QUOTE_STATUS_SENT: 
        return idType === Constants.QUOTE_ID_STATUS_SENT;
        case Constants.QUOTE_STATUS_WON: 
        return idType === Constants.QUOTE_ID_STATUS_WON;
        case Constants.QUOTE_STATUS_LOST:
        return idType === Constants.QUOTE_ID_STATUS_LOST;
        case Constants.QUOTE_STATUS_ARCHIVED:
        return idType === Constants.QUOTE_ID_STATUS_ARCHIVED;

        default: return false;
      }
  
  }
}