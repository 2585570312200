import * as XLSX from 'xlsx';
import * as moment from 'moment';

import { ArrayUtils } from './arrayUtils';
import { Client } from '../model/client';
import { NumberUtils } from './numberUtils';
import { StringUtils } from './stringUtils';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { getJsDateFromExcel } from 'excel-date-to-js';
import { Calibration } from '../model/calibration';
import { EquipmentTypeService } from '../service/equipmentType.service';
import { VariableTypeService } from '../service/variableType.service';
import { CalibratesRangeService } from '../service/calibratesRange.service';
import { DateUtils } from './dateUtils';
import { VariableType } from '../model/VariableType';
import { Equipment, EquipmentType } from '../model/equipment';
import { UnitService } from '../service/unit.service';
import { CalibrationSiteService } from '../service/calibrationSite.service';
import { CalibratesRange } from '../model/calibratesRange';
import { Unit } from '../model/unit';
import { CalibrationSite } from '../model/calibrationSite';
import { TestTypeService } from '../service/testType.service';
import { Test, TestType } from '../model/test';
import { TestTypeEquipmentConfig } from '../model/testTypeEquipmentConfig';
import { TestTypeEquipmentConfigService } from '../service/testTypeEquipmentConfig.service';
import { Constants } from './constants';
import { Room } from '../model/room';
import { CompresedGas } from '../model/compresedGas';

const daysToSec = 24 * 60 * 60;

export interface ConvertedFile {
    file: File;
    raw: boolean;
}

export class ExcelUtils {

    public static particlesCounts = [Constants.INFORMATIVE, Constants.GRADE_A, Constants.GRADE_B, Constants.GRADE_C, Constants.GRADE_D, Constants.ISO_5, Constants.ISO_6, Constants.ISO_7, Constants.ISO_8, Constants.ISO_9];

    public static async excelToCalibration(file: File, equipmentTypeService: EquipmentTypeService,
        variableTypeService: VariableTypeService, calibratesRangeService: CalibratesRangeService, unitTypeService: UnitService,
        calibrationSiteService: CalibrationSiteService, translate: TranslateService):
        Promise<Map<string, any[]>> {
        const data = await this.loadExcel(file, false);
        return this.loadCalibrations(data, equipmentTypeService, variableTypeService, calibratesRangeService, unitTypeService, calibrationSiteService, translate);
    }

    public static async excelToEquipmentAir(file: File, testTypeService: TestTypeService, equipmentTypeService: EquipmentTypeService, translate: TranslateService, testTypeEquipmentConfigService: TestTypeEquipmentConfigService):
        Promise<Map<string, any[]>> {
        const data = await this.loadExcel(file, false);
        return this.loadEquipmentAir(data, testTypeService, equipmentTypeService, translate, testTypeEquipmentConfigService);
    }

    public static async excelToRoomAir(file: File, testTypeService: TestTypeService, translate: TranslateService, testTypeEquipmentConfigService: TestTypeEquipmentConfigService):
        Promise<Map<string, any[]>> {
        const data = await this.loadExcel(file, false);
        return this.loadRoomAir(data, testTypeService, translate, testTypeEquipmentConfigService);
    }

    public static async excelToCompressGastAir(file: File, testTypeService: TestTypeService, translate: TranslateService, testTypeEquipmentConfigService: TestTypeEquipmentConfigService):
        Promise<Map<string, any[]>>  {
        const data = await this.loadExcel(file, false);
        return this.loadCompressGas(data, testTypeService, translate, testTypeEquipmentConfigService);
    }

    private static loadExcel(file: File, raw: boolean): Promise<any[][]> {
        return new Promise((resolve, reject) => {

            if (typeof file === 'string') {
                resolve(this.stringToWb(file, raw));
            } else {
                const reader: FileReader = new FileReader();

                reader.onload = (e: any) => resolve(this.stringToWb(e.target.result as string, raw));

                reader.onerror = (error: any) => reject(error);

                reader.readAsBinaryString(file);
            }
        });
    }

    private static stringToWb(str: string, raw: boolean): any[][] {
        let data: any[][];

        /* read workbook */
        const bstr: string = str;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', raw });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        const options: XLSX.Sheet2JSONOpts = { header: 1, raw: true };

        /* save data */
        data = (XLSX.utils.sheet_to_json(ws, options));

        data = data.map((s: any[]) => {
            if (s.length === 1 && typeof s[0] === 'string') {
                const dat = s[0];
                s = dat.split(';');
            }

            return s as any[][];
        });

        return data;
    }

    private static async loadCalibrations(data: any[][], equipmentTypeService: EquipmentTypeService,
        variableTypeService: VariableTypeService, calibratesRangeService: CalibratesRangeService, unitTypeService: UnitService,
        calibrationSiteService: CalibrationSiteService, translate: TranslateService):
        Promise<Map<string, any[]>> {
        const res: Calibration[] = [];
        const error: String[] = [];
        let resultMap = new Map();

        const mapVariables: Map<string, number> = new Map();
        const mapEquipmentType: Map<string, number> = new Map();
        const mapCalibrateRange: Map<string, number> = new Map();
        const maplistUnitType: Map<string, number> = new Map();
        const maplistSite: Map<string, number> = new Map();

        const listVariables = await firstValueFrom(variableTypeService.findAll()) as VariableType[];
        const listEquipmentType = await firstValueFrom(equipmentTypeService.findAll()) as EquipmentType[];
        const listCalibrateRange = await firstValueFrom(calibratesRangeService.findAll()) as CalibratesRange[];
        const listUnitType = await firstValueFrom(unitTypeService.findAll()) as Unit[];
        const listSites = await firstValueFrom(calibrationSiteService.findAll()) as CalibrationSite[];

        listVariables.forEach(v => mapVariables.set((translate.instant('variableType.'.concat(v.translate)) as string).toLowerCase(), v.id));
        listEquipmentType.forEach(v => mapEquipmentType.set((translate.instant('equipmentType.'.concat(v.translate)) as string).toLowerCase(), v.id));
        listCalibrateRange.forEach(v => mapCalibrateRange.set((translate.instant('calibrateRange.'.concat(v.rangeDescription)) as string).toLowerCase(), v.id));
        listUnitType.forEach(v => maplistUnitType.set((translate.instant('unitType.'.concat(v.translate)) as string).toLowerCase(), v.id));
        listSites.forEach(v => maplistSite.set((translate.instant('calibrationSite.'.concat(v.translate)) as string)?.toLowerCase(), v.id));

        // Empezamos en 1 para saltarnos la primera fila, que es de cabeceras
        for (let i = 1; i < data.length; i++) {
            const rowNum = i + 1;
            const row = data[i];

            const eq = new Calibration();
            const nameEquipment: string = this.getDataFromCell(row, 0);
            let equipmentType: number;
            if (nameEquipment != null) {
                equipmentType = mapEquipmentType.get(nameEquipment.toLowerCase());
            }
            eq.equipmentType = equipmentType;

            eq.maker = this.getDataFromCell(row, 1);
            eq.model = this.getDataFromCell(row, 2);
            eq.serie = this.getDataFromCell(row, 3);
            eq.indicative = this.getDataFromCell(row, 4);
            eq.location = this.getDataFromCell(row, 5);

            const nameVariable: string = this.getDataFromCell(row, 6);
            let variableType: number;
            if (nameVariable) {
                if (typeof nameVariable === "string") {
                    variableType = mapVariables.get(nameVariable.toLowerCase());
                }
            }

            if (variableType) {
                eq.variableType = variableType;
            }

            const nameUnit: string = this.getDataFromCell(row, 7);
            let unitType: number;
            if (nameUnit) {
                if (typeof nameUnit === "string") {
                    unitType = maplistUnitType.get(nameUnit.toLowerCase());
                }
            }

            if (unitType) {
                eq.unitType = unitType;
            }

            const nameSite: string = this.getDataFromCell(row, 8);
            let siteType: number;
            if (nameSite) {
                if (typeof nameSite === "string") {
                    siteType = maplistSite.get(nameSite.toLowerCase());
                }
            }
            eq.calibrationSite = siteType;

            const nameRange: string = this.getDataFromCell(row, 9);
            let range: number;
            if (nameRange != null) {
                if (typeof nameRange === "string") {
                    range = mapCalibrateRange.get(nameRange?.toLowerCase());
                }
                
                if (!range) {
                    eq.preassureRange = nameRange;
                }
            }

            if (range) {
                eq.calibrationRange = range;
            }

            const amountIncluded = this.getDataFromCell(row, 10);
            if (amountIncluded && amountIncluded?.toLowerCase() === 'si') {
                eq.amountNotIncluded = true;  
            }

            if (eq.equipmentType == null) {
                error.push(`No se reconoce el tipo de equipo para la fila ${rowNum}`);
                console.error(`El tipo de equipo es nulo para la fila ${rowNum}`);
            } else if (eq.unitType == null) {
                error.push(`No se reconoce la unidad para la fila ${rowNum}`);
                console.error(`La unidad es nulo para la fila ${rowNum}`);
            } else if (eq.calibrationSite == null) {
                error.push(`No se reconoce el lugar para la fila ${rowNum}`);
                console.error(`El lugar es nulo para la fila ${rowNum}`);
            } else if (eq.variableType == null) {
                error.push(`No se reconoce el tipo de variable para la fila ${rowNum}`);
                console.error(`El tipo de variable es nulo para la fila ${rowNum}`);
            } else if (eq.calibrationRange == null && eq.preassureRange == null) {
                error.push(`No se reconoce el rango de la calibración para la fila ${rowNum}`);
                console.error(`El rango de la calibración es nulo para la fila ${rowNum}`);
            } else if (eq.serie == null) {
                error.push(`No se reconoce número de serie para la fila ${rowNum}`);
                console.error(`El número de serie de la calibración es nulo para la fila ${rowNum}`);
            } else if (eq.indicative == null) {
                error.push(`No se reconoce el indicativo interno para la fila ${rowNum}`);
                console.error(`El indicativo interno de la calibración es nulo para la fila ${rowNum}`);
            } else {
                res.push(eq);
            }
        }

        resultMap.set('data', res);
        resultMap.set('error', error);

        return resultMap;
    }

    private static async loadEquipmentAir(data: any[][], testTypeService: TestTypeService, equipmentTypeService: EquipmentTypeService, translate: TranslateService, testTypeEquipmentConfigService: TestTypeEquipmentConfigService):
        Promise<Map<string, any[]>> {
        const res: Equipment[] = [];
        const error: String[] = [];
        let resultMap = new Map();

        const mapTestType: Map<string, number> = new Map();
        const mapEquipmentType: Map<string, number> = new Map();

        let listEquipmentConfig: TestTypeEquipmentConfig[] = [];
        const listTestType = await firstValueFrom(testTypeService.findAll()) as TestType[];
        const listEqType = await firstValueFrom(equipmentTypeService.findAllByIdAplication(1)) as EquipmentType[];

        // let listParticleCount: TestTypeEquipmentConfig[] = [];
        let listParticleCountReponse: TestTypeEquipmentConfig[] = [];
        let listParticleCountWorking: TestTypeEquipmentConfig[] = [];

        listTestType.forEach(v => mapTestType.set((translate.instant('testType.'.concat(v.translate)) as string).toLowerCase(), v.id));
        // listParticleCount = await firstValueFrom(testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdEquipment(Constants.ID_GAS, Constants.ID_TYPE_PARTICLE_GAS)) as TestTypeEquipmentConfig[];
        listTestType.forEach(v => mapTestType.set((translate.instant('testType.'.concat(v.translate)) as string).toLowerCase(), v.id));
        listEqType.forEach(v => mapEquipmentType.set((translate.instant('equipmentType.'.concat(v.translate)) as string).toLowerCase(), v.id));

        // Empezamos en 1 para saltarnos la primera fila, que es de cabeceras
        for (let i = 1; i < data.length; i++) {
            const rowNum = i + 1;
            const row = data[i];

            const eq = new Equipment();
            let column = 0;
            eq.inventoryNumber = this.getDataFromCell(row, column);
            column += 1;
            const nameEquipment: string= this.getDataFromCell(row, column);
            let eqType: number;
            if (nameEquipment != null) {
                eqType = mapEquipmentType.get(nameEquipment.toLowerCase());
            }
            eq.idType = eqType;
            column += 1;
            eq.model = this.getDataFromCell(row, column);
            column += 1;
            eq.maker = this.getDataFromCell(row, column);
            column += 1;
            eq.location = this.getDataFromCell(row, column);
            column += 1;
            eq.serialNumber = this.getDataFromCell(row, column);

            if (eq.idType) {
                listEquipmentConfig= await firstValueFrom(testTypeEquipmentConfigService.findByEquipment(eq.idType)) as TestTypeEquipmentConfig[];
                listParticleCountReponse = await firstValueFrom(testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdEquipment(eq.idType, Constants.ID_TYPE_REST)) as TestTypeEquipmentConfig[];
                listParticleCountWorking = await firstValueFrom(testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdEquipment(eq.idType, Constants.ID_TYPE_WORKING)) as TestTypeEquipmentConfig[];
            }

            listTestType.forEach(e => {

                column += 1;
                let essay = this.getDataFromCell(row, column);
                const colEssay = data[0][column]?.toLowerCase();

                if (essay) {
                    const resultCol = colEssay?.toLowerCase();
                    const resultRepose = (translate.instant('testType.particleCount') as string).toLowerCase();
                    const resultWorking = (translate.instant('testType.particleCountWorking') as string).toLowerCase();
                    if (resultCol === resultRepose || resultCol === resultWorking) {
                        const essatParticle = essay?.toLowerCase().replace(" ", "");

                        const testType = mapTestType.get(colEssay.toLowerCase());

                        if (this.particlesCounts.includes(essatParticle)) {
                            let resultTest;
                            if (testType === Constants.ID_TYPE_WORKING) {
                                resultTest = listParticleCountWorking.find(eqTest => eqTest.idTestType === testType && eqTest.reference === essatParticle);
                            } else if(testType === Constants.ID_TYPE_REST) {
                                resultTest = listParticleCountReponse.find(eqTest => eqTest.idTestType === testType && eqTest.reference === essatParticle);
                            }

                            if (resultTest) {
                                let test = new Test();
                                test.idTestTypeEquipmentConfig = resultTest.id;
                                test.time = resultTest.time;
                                test.idType = testType;
                                test.reference = essatParticle;
                                if (!eq.tests) {
                                    eq.tests = [];
                                }
                                if (testType === Constants.ID_TYPE_WORKING) {
                                    eq.countTestWorking = test;
                                } else if (testType === Constants.ID_TYPE_REST) {
                                    eq.countTestRest = test;
                                }
                                eq.tests.push(test);
                            }
                        }
                    } else {
                        if (typeof essay === 'string') {
                            if (essay.toLowerCase().includes('x')) {
                                const testType = mapTestType.get(colEssay.toLowerCase());

                                if (testType) {
                                    const resultTest = listEquipmentConfig.find(eqTest => eqTest.idTestType === testType);
                                    if (resultTest) {
                                        let test = new Test();
                                        test.idType = testType;
                                        test.idEquipment = eq.idType;
                                        test.idTestTypeEquipmentConfig=resultTest.id;
                                        if (!eq.tests) {
                                            eq.tests = [];
                                        }
                                        if (testType === Constants.ID_TYPE_WORKING) {
                                            eq.countTestWorking = test;
                                        } else if (testType === Constants.ID_TYPE_REST) {
                                            eq.countTestRest = test;
                                        }
                                        eq.tests.push(test);

                                    }
                                }
                            }
                        }
                    }
                }
            });

            if (eq.inventoryNumber == null) {
                error.push(`No se reconoce el número del equipo para la fila ${rowNum}`);
                console.error(`El numero del equipo es nulo para la fila ${rowNum}`);
            } else if (eq.idType == null) {
                error.push(`No se reconoce el tipo de equipo para la fila ${rowNum}`);
                console.error(`El tipo de equipo es nulo para la fila ${rowNum}`);
            } else if (eq.maker == null) {
                error.push(`No se reconoce el fabricante del equipo para la fila ${rowNum}`);
                console.error(`El fabricante del equipo es nulo para la fila ${rowNum}`);
            } else if (eq.serialNumber == null) {
                error.push(`No se reconoce la ubicación del equipo para la fila ${rowNum}`);
                console.error(`La ubicación es nulo para la fila ${rowNum}`);
            } else if (eq.location == null) {
                error.push(`No se reconoce la ubicación del equipo para la fila ${rowNum}`);
                console.error(`La ubicación es nulo para la fila ${rowNum}`);
            } else if (eq.model == null) {
                error.push(`No se reconoce el modelo del equipo para la fila ${rowNum}`);
                console.error(`El modelo del equipo es nulo para la fila ${rowNum}`);
            } else if (eq.tests == null || eq.tests.length === 0) {
                error.push(`Debe ingresar al menos un ensayo para la fila  ${rowNum}`);
                console.error(`Debe ingresar al menos un ensayo para la fila ${rowNum}`);
            } else {
                res.push(eq);
            }
        }

        resultMap.set('data', res);
        resultMap.set('error', error);

        return resultMap;
    }

    private static async loadRoomAir(data: any[][], testTypeService: TestTypeService, translate: TranslateService, testTypeEquipmentConfigService: TestTypeEquipmentConfigService):
        Promise<Map<string, any[]>> {
        const res: Room[] = [];
        const error: String[] = [];
        let resultMap = new Map();

        const mapTestType: Map<string, number> = new Map();
        const mapEquipmentType: Map<string, number> = new Map();

        let listEquipmentConfig: TestTypeEquipmentConfig[] = [];
        let listParticleCount: TestTypeEquipmentConfig[] = [];
        let listParticleCountReponse: TestTypeEquipmentConfig[] = [];
        let listParticleCountWorking: TestTypeEquipmentConfig[] = [];
        const listTestType = await firstValueFrom(testTypeService.findAll()) as TestType[];

        listTestType.forEach(v => mapTestType.set((translate.instant('testType.'.concat(v.translate)) as string).toLowerCase(), v.id));
        listParticleCountReponse = await firstValueFrom(testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdRoom(Constants.ID_GAS, Constants.ID_TYPE_REST)) as TestTypeEquipmentConfig[];
        listParticleCountWorking = await firstValueFrom(testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdRoom(Constants.ID_GAS, Constants.ID_TYPE_WORKING)) as TestTypeEquipmentConfig[];
        listParticleCount = await firstValueFrom(testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdRoom(Constants.ID_GAS, Constants.ID_TYPE_PARTICLE_GAS)) as TestTypeEquipmentConfig[];

        listEquipmentConfig = await firstValueFrom(testTypeEquipmentConfigService.findByRoom(1)) as TestTypeEquipmentConfig[];
        // Empezamos en 1 para saltarnos la primera fila, que es de cabeceras
        for (let i = 1; i < data.length; i++) {
            const rowNum = i + 1;
            const row = data[i];

            const eq = new Room();
            let column = 0;
            eq.roomNumber = this.getNumberFromCell(row, column);
            column += 1;
            eq.name = this.getDataFromCell(row, column);
            column += 1;
            eq.roomSurface = this.getNumberFromCell(row, column);
            column += 1;
            eq.totalFilters = this.getNumberFromCell(row, column);
            eq.countPoints =this.calculateCountPoints(eq.roomSurface);
            column += 1;
            eq.microPoints = this.getNumberFromCell(row, column);
            column += 1;
            eq.numberDiffusers = this.getNumberFromCell(row, column);

            listTestType.forEach(e => {
                column += 1;
                let essay = this.getDataFromCell(row, column);
                const colEssay = data[0][column]?.toLowerCase();

                if (essay) {
                    const resultCol = colEssay?.toLowerCase();
                    const resultRepose = (translate.instant('testType.particleCount') as string).toLowerCase();
                    const resultWorking = (translate.instant('testType.particleCountWorking') as string).toLowerCase();
                    if (resultCol === resultRepose || resultCol === resultWorking) {
                        const essatParticle = essay?.toLowerCase().replace(" ", "");

                        const testType = mapTestType.get(colEssay.toLowerCase());

                        if (this.particlesCounts.includes(essatParticle)) {
                            let resultTest;
                            if (testType === Constants.ID_TYPE_WORKING) {
                                resultTest = listParticleCountWorking.find(eqTest => eqTest.idTestType === testType && eqTest.reference === essatParticle);
                            } else if (testType === Constants.ID_TYPE_REST) {
                                resultTest = listParticleCountReponse.find(eqTest => eqTest.idTestType === testType && eqTest.reference === essatParticle);
                            }

                            if (resultTest) {
                                let test = new Test();
                                test.idTestTypeEquipmentConfig = resultTest.id;
                                test.time = resultTest.time;
                                test.idType = testType;
                                test.reference = essatParticle;
                                if (!eq.tests) {
                                    eq.tests = [];
                                }
                                if (testType === Constants.ID_TYPE_WORKING) {
                                    eq.countTestWorking = test;
                                } else if (testType === Constants.ID_TYPE_REST) {
                                    eq.countTestRest = test;
                                }
                                eq.tests.push(test);
                            }
                        }
                    } else {
                        if (typeof essay === 'string') {
                            if (essay.toLowerCase().includes('x')) {
                                const testType = mapTestType.get(colEssay.toLowerCase());

                                if (testType) {
                                    const resultTest = listEquipmentConfig.find(eqTest => eqTest.idTestType === testType);
                                    if (resultTest) {
                                        let test = new Test();
                                        test.idType = testType;
                                        test.idTestTypeEquipmentConfig = resultTest.id;
                                        test.time = resultTest.time;
                                        if (!eq.tests) {
                                            eq.tests = [];
                                        }
                                        eq.tests.push(test);

                                    }
                                }
                            }
                        }
                    }
                }
            });

            if (eq.roomNumber == null) {
                error.push(`No se reconoce numero de la sala para la fila ${rowNum}`);
                console.error(`El numero de la sala es nulo para la fila ${rowNum}`);
            } else if (eq.name == null) {
                error.push(`No se reconoce el nombre de la sala para la fila ${rowNum}`);
                console.error(`El nombre es nulo para la fila ${rowNum}`);
            } else if (eq.roomSurface == null) {
                error.push(`No se reconoce la superficie de la sala para la fila ${rowNum}`);
                console.error(`La superficie de la sala es nulo para la fila ${rowNum}`);
            } else if (eq.totalFilters == null) {
                error.push(`No se reconoce el total de filtros de la sala para la fila ${rowNum}`);
                console.error(`El total de filtros es nulo para la fila ${rowNum}`);
            } else if (eq.numberDiffusers == null) {
                error.push(`No se reconoce el numero de difusores de la sala para la fila ${rowNum}`);
                console.error(`El numero de difusores de la sala es nulo para la fila ${rowNum}`);
            } else if (eq.countPoints == null) {
                error.push(`No se reconoce el número de puntos para la fila ${rowNum}`);
                console.error(`El numero de puntos es nulo para la fila ${rowNum}`);
            } else if (eq.microPoints == null) {
                error.push(`No se reconoce el numero de puntos micros para la fila ${rowNum}`);
                console.error(`El numero de puntos micros es nulo para la fila ${rowNum}`);
            } else if (eq.tests == null || eq.tests.length === 0) {
                error.push(`Debe ingresar al menos un ensayo para la fila ${rowNum}`);
                console.error(`Debe ingresar al menos un ensayo para la fila ${rowNum}`);
            } else {
                res.push(eq);
            }
        }

        resultMap.set('data', res);
        resultMap.set('error', error);

        return resultMap;
    }

    private static async loadCompressGas(data: any[][], testTypeService: TestTypeService, translate: TranslateService, testTypeEquipmentConfigService: TestTypeEquipmentConfigService):
        Promise<Map<string, any[]>> {
        const res: CompresedGas[] = [];
        const error: String[] = [];
        let resultMap = new Map();

        const mapTestType: Map<string, number> = new Map();

        let listEquipmentConfig: TestTypeEquipmentConfig[] = [];
        let listParticleCount: TestTypeEquipmentConfig[] = [];
        const listTestType = await firstValueFrom(testTypeService.findTestTypeAllByGas(Constants.ID_GAS)) as TestType[];

        listTestType.forEach(v => mapTestType.set((translate.instant('testType.'.concat(v.translate)) as string).toLowerCase(), v.id));
        listParticleCount = await firstValueFrom(testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdGas(Constants.ID_GAS, Constants.ID_TYPE_PARTICLE_GAS)) as TestTypeEquipmentConfig[];
        listEquipmentConfig = await firstValueFrom(testTypeEquipmentConfigService.findByGas(1)) as TestTypeEquipmentConfig[];
        // Empezamos en 1 para saltarnos la primera fila, que es de cabeceras
        for (let i = 1; i < data.length; i++) {
            const rowNum = i + 1;
            const row = data[i];

            const eq = new CompresedGas();
            let column = 0;
            eq.gasPoints = this.getDataFromCell(row, column);
            column += 1;
            eq.name = this.getDataFromCell(row, column);
            column += 1;
            eq.location = this.getDataFromCell(row, column);
            eq.order = i;

            listTestType.forEach(e => {
                column += 1;
                let essay = this.getDataFromCell(row, column);
                const colEssay = data[0][column]?.toLowerCase() as String;

                if (essay) {
                    const result1 = colEssay?.toLowerCase();
                    const result2 = (translate.instant('testType.particleCountGasCompressed') as string).toLowerCase();
                    if (result1 === result2) {
                        const essatParticle = essay?.toLowerCase().replace(" ", "");

                        const testType = mapTestType.get(colEssay.toLowerCase());

                        if (this.particlesCounts.includes(essatParticle)) {
                            const resultTest = listParticleCount.find(eqTest => eqTest.idTestType === testType && eqTest.reference === essatParticle);
                            if (resultTest) {
                                let test = new Test();
                                test.idTestTypeEquipmentConfig = resultTest.id;
                                test.time = resultTest.time;
                                test.idType = testType;
                                test.reference = essatParticle;
                                if (!eq.tests) {
                                    eq.tests = [];
                                }

                                else if (testType === Constants.ID_TYPE_PARTICLE_GAS) {
                                    eq.countTestRest = test;
                                }
                                eq.tests.push(test);
                            }
                        }
                    } else {
                        if (typeof essay === 'string') {
                            if (essay.toLowerCase().includes('x')) {
                                const testType = mapTestType.get(colEssay.toLowerCase());

                                if (testType) {
                                    const resultTest = listEquipmentConfig.find(eqTest => eqTest.idTestType === testType);
                                    if (resultTest) {
                                        let test = new Test();
                                        test.idTestTypeEquipmentConfig = resultTest.id;
                                        test.time = resultTest.time;
                                        test.idType = testType;
                                    // test.idEquipment = eq.idType;
                                        if (!eq.tests) {
                                            eq.tests = [];
                                        }
                                        eq.tests.push(test);

                                    }
                                }
                            }
                        }
                    }
                }
            });

            if (eq.gasPoints == null) {
                error.push(`El numero del gas es nulo para la fila ${rowNum}`);
                console.error(`El numero del gas es nulo para la fila ${rowNum}`);
            } else if (eq.name == null) {
                error.push(`El nombre del gas es nulo para la fila ${rowNum}`);
                console.error(`El nombre del gas es nulo para la fila ${rowNum}`);
            } else if (eq.location == null) {
                error.push(`La ubicación del gas es nulo para la fila ${rowNum}`);
                console.error(`La ubicación del gas es nulo para la fila ${rowNum}`);
            } else if (eq.tests == null || eq.tests.length === 0) {
                error.push(`Debe ingresar al menos un ensayo para la fila ${rowNum}`);
                console.error(`Debe ingresar al menos un ensayo para la fila ${rowNum}`);
            } else {
                res.push(eq);
            }
        }

        resultMap.set('data', res);
        resultMap.set('error', error);

        return resultMap;
    }

    private static getDataFromCell(row: any[], index: number): string {
        let res: string = null;

        try {
            res = row[index] as string;
        } catch (ex) {
            res = null;
        }

        return res;
    }

    private static getNumberFromCell(row: any[], index: number): number {
        let res: number = null;

        try {
            res = row[index] as number;
        } catch (ex) {
            res = null;
        }

        return res;
    }

    private static getDateFromCell(row: any[], index: number): Date {
        let res: Date = null;
        const cell = row[index] as string;

        let colDay = 0;
        const colMonth = 1;
        let colYear = 2;

        try {
            // Si tiene el formato normal de fecha (dd/MM/yyyy), calculamos la fecha con ese formato,
            // Si por el contrario es un número, se trata del formato especial de Excel.
            if (cell.toString().includes('/')) {
                const isDateTime = cell.toString().includes(':');

                const dateStr = isDateTime ? cell.split(' ')[0] : cell;

                const dateSplit: string[] = dateStr.split('/');

                // Obtenemos los campos individuales para todas las partes de la fecha
                let dd = +dateSplit[colDay];

                if (dd > 100) {
                    colDay = 2;
                    colYear = 0;

                    dd = +dateSplit[colDay];
                }

                const mm = +dateSplit[colMonth] - 1;
                const yyyy = +dateSplit[colYear];

                // Creamos la fecha con Javascript
                if (isDateTime) {
                    const hourSplit: string[] = cell.split(' ')[1].split(':');

                    const hh = hourSplit[0] ? +hourSplit[0] : 0;
                    const min = hourSplit[1] ? +hourSplit[1] : 0;
                    const sec = hourSplit[2] ? +hourSplit[2] : 0;

                    res = new Date(yyyy, mm, dd, hh, min, sec);
                } else {
                    res = new Date(yyyy, mm, dd);
                }

            } else if (!isNaN(+cell)) {
                // Convertimos el dato Excel a fecha normal
                res = new Date(((+cell - (25567 + 1)) * 86400 * 1000) - 86400000);
            }
        } catch (ex) {
            res = null;
        }

        return res;
    }

    private static excelDatesToDate(dateOnly: Date | number | string, timeOnly: Date | number | string, rowIndex: number): Date {

        if (Object.prototype.toString.call(dateOnly) === '[object Date]') {
            // Caso tipo Date
            dateOnly = this.dateToDateStr(DateUtils.anyToDate(dateOnly));
        } else if (!isNaN(+dateOnly)) {
            // Caso número de Excel
            dateOnly = this.excelNumberToDateStr(+dateOnly);
        } else if (typeof dateOnly === 'string') {
            // Caso string
            dateOnly = this.excelStringToDateStr(dateOnly);
        }

        let dateOnlyStr: string = null;

        if (dateOnly) {
            dateOnlyStr = dateOnly.toString().trim();
        } else {
            return null;
        }

        if (Object.prototype.toString.call(timeOnly) === '[object Date]') {
            timeOnly = this.dateToHourStr(DateUtils.anyToDate(timeOnly));
        } else if (!isNaN(+timeOnly)) {
            timeOnly = this.excelNumberToTimeStr(+timeOnly);
        } else if (typeof timeOnly === 'string') {
            // Caso string
            timeOnly = this.excelStringToTimeStr(timeOnly);
        }

        let timeOnlyStr: string = null;

        if (timeOnly) {
            timeOnlyStr = timeOnly.toString().trim();
        } else {
            return null;
        }

        const dateStr = `${dateOnlyStr}  ${timeOnlyStr}` + ' +0:00';
        let date = moment(dateStr, 'MM/DD/YYYY HH:mm:ss ZZ').toDate();

        if (isNaN(date.getTime())) {
            console.error(`No se ha reconocido la fecha ${dateOnlyStr}, hora ${timeOnlyStr}. Fila: ${(rowIndex + 1)}`);
            date = null;
        }

        return date;
    }

    private static stringToDate(str: string): Date {
        let date = moment(str, 'DD/MM/YYYY HH:mm:ss').toDate();

        if (isNaN(date.getTime())) {
            date = moment(str, 'DD/MM/YYYY HH:mm:ss ZZ').toDate();
        }

        if (isNaN(date.getTime())) {
            date = moment(str, 'MM/DD/YYYY HH:mm:ss').toDate();
        }

        if (isNaN(date.getTime())) {
            date = moment(str, 'MM/DD/YYYY HH:mm:ss ZZ').toDate();
        }

        if (isNaN(date.getTime())) {
            date = moment(str, 'DD-MMM-YYYY HH:mm:ss').toDate();
        }

        if (isNaN(date.getTime())) {
            date = null;
        }

        return date;
    }

    private static excelNumberToDateStr(num: number): string {
        const rawDate: Date = new Date((num - 25569) * 86400 * 1000);

        const date: Date = new Date(rawDate.toDateString());

        return this.dateToDateStr(date);
    }

    private static excelStringToDateStr(str: string): string {
        let isDDMM = false;
        try {
            isDDMM = isDDMM || moment(str, 'DD/MM/YYYY', true).isValid();
            isDDMM = isDDMM || moment(str, 'DD/MM/YY', true).isValid();
        } catch (e) {

        }

        const data = str.split('/');

        if (data.length < 2) {
            return null;
        }

        let day = String(data[isDDMM ? 0 : 1]);
        let month = String(data[isDDMM ? 1 : 0]);
        let year = String(data[2]);

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        if (year.length < 3) {
            year = '20' + year;
        }

        return `${month}/${day}/${year}`;
    }

    private static excelNumberToDateTimeStr(num: number): string {
        const date = getJsDateFromExcel(num);
        return this.dateTimeToHourStr(new Date(date));
    }

    private static excelNumberToTimeStr(num: number): string {
        num = num * daysToSec;

        const date = new Date(1900, 0, 1);
        date.setSeconds(date.getSeconds() + num);

        return this.dateToHourStr(date);
    }

    private static dateToDateStr(date: Date): string {
        let month = String(date.getMonth() + 1);
        let day = String(date.getDate());
        const year = String(date.getFullYear());

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return `${day}/${month}/${year}`;
    }

    private static dateToHourStr(date: Date): string {
        let hour = `${date.getHours()}`;
        let minutes = `${date.getMinutes()}`;
        let seconds = `${date.getSeconds()}`;

        if (hour.length < 2) {
            hour = '0' + hour;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        return `${hour}:${minutes}:${seconds}`;
    }

    private static excelStringToTimeStr(str: string): string {
        str = str.replace(' AM', '');

        const isAM = str.includes(' PM') && !str.startsWith('12:');

        str = str.replace(' PM', '');

        const data = str.split(':');

        if (data.length < 2) {
            return null;
        }

        let hour = data[0];
        let minutes = data[1];
        let seconds = data[2];

        if (isAM) {
            hour = ((+hour) + 12).toString();
        }

        if (hour.includes('24')) {
            hour = '00';
        }

        if (hour.length < 2) {
            hour = '0' + hour;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        return `${hour}:${minutes}:${seconds}`;
    }

    private static dateTimeToHourStr(date: Date): string {
        return this.dateToDateStr(date) + ' ' + this.dateToHourStr(date);
    }

    private static cellToData(row: string[], column: number): string {
        if (column < 0) {
            return null;
        }

        return this.getDataFromCell(row, column);
    }

    private static calculateCountPoints(surface:number) : number {
        let points = 0;
        if(surface <= 2){
          return 1;
        } else if (surface <= 4) {
          return 2;
        }else if (surface <= 6) {
          return 3;
        } else if (surface <= 8) {
          return 4;
        } else if (surface <= 10) {
          return 5;
        } else if (surface <= 24) {
          return 6;
        } else if (surface <= 28) {
          return 7;
        }else if (surface <= 32) {
          return 8;
        } else if (surface <= 36) {
          return 9;
        } else if (surface <= 52) {
          return 10;
        } else if (surface <= 56) {
          return 11;
        } else if (surface <= 64) {
          return 12;
        } else if (surface <= 68) {
          return 13;
        } else if (surface <= 72) {
          return 14;
        } else if (surface <= 76) {
          return 15;
        } else if (surface <= 104) {
          return 16;
        } else if (surface <= 108) {
          return 17;
        } else if (surface <= 116) {
          return 18;
        } else if (surface <= 148) {
          return 19;
        } else if (surface <= 156) {
          return 20;
        } else if (surface <= 192) {
          return 21;
        } else if (surface <= 232) {
          return 22;
        } else if (surface <= 276) {
          return 23;
        } else if (surface <= 352) {
          return 24;
        } else if (surface <= 436) {
          return 25;
        } else if (surface <= 636) {
          return 26;
        } else if (surface <= 1000) {
          return 27;
        }
        return points;
      }

}
