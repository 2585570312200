import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { HttpUtils } from '../utils/httpUtils';

@Injectable({
  providedIn: 'root'
})
export class CalibrateTimeConfigService {

  baseUrl = environment.quotit + '/calibrate-time-config';
  constructor(private http: HttpClient) { }

  findAll(): Observable<any> {
    return this.http.get(this.baseUrl).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  findById(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/${id}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  findALlByEquipmentIdVariableIdUnitId(eqId: number, varId: number, unitId: number, rangeId: number) {
    return this.http.get( `${this.baseUrl}/equipmentId-variableId-unitId/${eqId}/${varId}/${unitId}/${rangeId}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }
}
