import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-duplicate-dialog',
  templateUrl: './duplicate-dialog.component.html'
})
export class DuplicateDialogComponent implements OnInit {

  amount: number;

  constructor(
    public dialogRef: MatDialogRef<DuplicateDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  onOkClick(): void {
    this.dialogRef.close(this.amount);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
