import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { UserEditComponent } from './user-edit.component';
import { UserListComponent } from '../user-list/user-list.component';

const routes: Routes = [
    { path: 'users', component: UserListComponent },
    { path: 'user', component: UserEditComponent },
    { path: '', redirectTo: '/users', pathMatch: 'full' },
    { path: '**', redirectTo: '/users' }
  ];
@NgModule({
    declarations: [UserEditComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        /* Custom modules */
        AngularMaterialModule,
        /* Otros */
        FlexLayoutModule,
        RouterModule,
        SharedModule
    ],
    exports: [
        UserEditComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class EditUserModule { }
