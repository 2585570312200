<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
    <div mat-dialog-content class="quotit-theme" *ngIf="!data.showQualis">

        <div fxLayout="row" class="flexwrap">
            <!-- Number -->
            <div fxFlex="10" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput type="number" [(ngModel)]="data.room.roomNumber" name="roomNumber" required
                        placeholder="{{ 'quoteEdit.dialog.room.number.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.room.number.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Name -->
            <div fxFlex="30" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="data.room.name" name="roomName" required
                        placeholder="{{ 'quoteEdit.dialog.room.name.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.room.name.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Surface -->
            <div fxFlex="10" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput type="number" required [(ngModel)]="data.room.roomSurface" name="roomSurface"
                        placeholder="{{ 'quoteEdit.dialog.room.surface.label' | translate }}" (ngModelChange)="calculatePoints()">
                    <mat-error>
                        {{ 'quoteEdit.dialog.room.surface.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Filter -->
            <div fxFlex="5" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput type="number" required [(ngModel)]="data.room.totalFilters" name="roomFilters"
                        placeholder="{{ 'quoteEdit.dialog.room.totalFilters.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.room.totalFilters.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- CountPoints -->
            <div fxFlex="5" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput type="number" required [(ngModel)]="data.room.countPoints" name="roomPoints"
                        placeholder="{{ 'quoteEdit.dialog.room.countPoints.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.room.countPoints.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- MicroPOints -->
            <div fxFlex="5" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput type="number" [(ngModel)]="data.room.microPoints" name="roomCPOints"
                        placeholder="{{ 'quoteEdit.dialog.room.microPoints.label' | translate }}">
                </mat-form-field>
            </div>
            <!-- Diffuser -->
            <div fxFlex="5" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput type="number" [(ngModel)]="data.room.numberDiffusers" name="roomMPoints"
                        placeholder="{{ 'quoteEdit.dialog.room.totalDiffusers.label' | translate }}">
                </mat-form-field>
            </div>

                        <!-- Count -->
                        <div fxFlex="15" fxFlex.lt-md="100%" *ngIf="showCountWorking()">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'quoteEdit.dialog.room.count.label' | translate }}</mat-label>
                                <mat-select name="equnit" required (selectionChange)="onCountCriteriaChangeRest($event)"
                                    [(ngModel)]="data.room.idCountEssayCriteria">
                                    <mat-option value="null">--</mat-option>
                                    <mat-option *ngFor="let countEssayCriteria of countEssayCriteriasRest"
                                        [value]="countEssayCriteria.id">
                                        {{'testType.particleCount.' + countEssayCriteria.reference | translate}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    {{ 'quoteEdit.dialog.equipment.count.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="15" fxFlex.lt-md="100%" *ngIf="showCountRest()">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'quoteEdit.dialog.room.countWorking.label' | translate }}</mat-label>
                                <mat-select name="equnitWorking" required (selectionChange)="onCountCriteriaChangeWorking($event)"
                                    [(ngModel)]="data.room.idCountEssayCriteriaWorking" >
                                    <mat-option value="null">--</mat-option>
                                    <mat-option *ngFor="let countEssayCriteria of countEssayCriteriasWorking"
                                        [value]="countEssayCriteria.id">
                                        {{'testType.particleCount.' + countEssayCriteria.reference | translate}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    {{ 'quoteEdit.dialog.equipment.count.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>

        </div>
    </div>
    <!-- Pantalla subcontrata -->
    <!-- Outsourced -->
    <mat-toolbar color="secondary" *ngIf="!data.showQualis">
        {{ 'quoteEdit.dialog.equipment.outsoruced.title' | translate }}
        <div fxFlex="5" fxFlex.lt-md="100%">
            <mat-checkbox style="margin-left: 1em;" (change)="setOutsourced($event.checked)"
                [(ngModel)]="data.room.outsourced" name="roomOutsourced">
            </mat-checkbox>
        </div>
    </mat-toolbar>
    <div fxLayout="row" class="flexwrap"  *ngIf="!data.showQualis">
        <div fxFlex="90" fxFlex.lt-md="100%" *ngIf="data.room.outsourced">
            <app-quote-edit-outsources [providerQuote]="data.room.provider"
                (providerQuoteEmitter)="onProviderQuoteEmitter($event)"></app-quote-edit-outsources>
        </div>
    </div>

    <!-- Pantalla ensayos -->
    <mat-toolbar color="secondary" *ngIf="data.showAir && !data.showQualis" >
        {{ 'quoteEdit.dialog.equipment.essays.title' | translate }}
    </mat-toolbar>
    <app-quote-essay-air *ngIf="data.showAir && !data.showQualis" [testsConfig]="testTypes"
    (testCheckEmitter)="onCheckTest($event)"></app-quote-essay-air>
    <mat-toolbar color="secondary" *ngIf="data.showQualis">
        {{ 'quoteEdit.dialog.equipment.qualifications.title' | translate }}
    </mat-toolbar>
    <app-quote-consultory-qualifications *ngIf="data.showQualis" [qualificationsConfig]="consultancyTypes"
    (consultoryCheckEmitter)="onCheckQualification($event)">
    </app-quote-consultory-qualifications>
    <div mat-dialog-actions class="quotit-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>