import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TestThermal } from 'src/app/model/testThermal';
import { EquipmentType } from 'src/app/model/equipment';
import { ThermalProbe } from 'src/app/model/thermalProbe';
import { QuoteEditDialogDietComponent } from '../quote-edit-dialog-diet/quote-edit-dialog-diet.component';
import { EquipmentThermal } from 'src/app/model/equipmentThermal';
import { SnackBarService } from 'src/app/service/snackBar.service';

export interface DialogThermalProbeData {
  equipmentThermal: EquipmentThermal;
  isEdit: boolean;
  thermalProbe: ThermalProbe;
}

@Component({
  selector: 'app-quote-dialog-probe-thermal',
  templateUrl: './quote-dialog-probe-thermal.html'
})
export class QuoteDialogProbeThermal implements OnInit {

  tituloDialog: string;
  equipmentTypes: EquipmentType[];
  showProbes = false;
  testThermal: TestThermal[];
  thermalProbe: ThermalProbe;

  constructor(
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<QuoteEditDialogDietComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogThermalProbeData) {

    if (this.data.isEdit) {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.thermal.probe.title.edit', 
      { model: data.thermalProbe.type, serial: data.thermalProbe.type }) as string;
    } else {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.thermal.probe.title.create') as string;
    }
  }

  ngOnInit(): void {
    
  }

  onOkClick(): void {
    const errs: string[] = [];

    if (this.data.thermalProbe.type == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
    }
    if (this.data.thermalProbe.points == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
    }
    if (this.data.thermalProbe.values == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.thermalProbe);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
