import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";
import { AuditComplement } from "../model/auditComplement";
import { HttpUtils } from "../utils/httpUtils";

@Injectable({
    providedIn: 'root'
  })
  export class AuditProductService {
    url = `${environment.quotit}/audit`
    constructor(private http: HttpClient) { }
  
    // findById(id: number) {
    //   return this.http.get(`${this.url}/${id}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    // }
  
    // findById(ids: number[]): Observable<AuditProduct[]> {
    //     const url = `${this.url}/${ids.join(',')}`;
    //     return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    //   }
    findById(productId: number): Observable<AuditComplement> {
        const url = `${this.url}/${productId}`;
        return this.http.get<AuditComplement>(url);
      }
    findAll(): Observable<AuditComplement[]> {
      return this.http.get(`${this.url}/`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
  }
  