import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {  User } from 'src/app/model/user';
import { LoadingService } from 'src/app/service/loading.service';
import { ResponsabilityCenterService } from 'src/app/service/responsabilityCenter.service';
import { RoleService } from 'src/app/service/role.service';
import { SnackBarService } from 'src/app/service/snackBar.service';
import { UserService } from 'src/app/service/user.service';
import { Constants } from 'src/app/utils/constants';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html'
})
export class UserEditComponent implements OnInit {

  form: FormGroup;
  user: User
  roles: [] = [];
  offices: [] = [];

  constructor(
    public snackBarService: SnackBarService,
    private translate: TranslateService,
    public loader: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private responsabilityService: ResponsabilityCenterService,
    private roleService: RoleService,
    private userService: UserService) {}

  ngOnInit(): void {
    this.loader.show();

    this.route.queryParams.subscribe(params => {
      const username = params[Constants.USER_USERNAME];
      if (username) {
        this.userService.findByUsername(username).subscribe((res: User) => {
          this.user = res;
          this.loader.hide();
        }, error => {
          console.error('Error al recuperar los datos del usuario', error);
          this.loader.hide();
        });
      }
    });
    this.loadRoles();
    this.loadOffices();
  }

  loadRoles(): void {
    this.roleService.findAllRoles().subscribe(
      (roles) => {
        this.roles = roles;
      },
      error => {
        console.error('userEdit.general.role.error', error);
        this.snackBarService.sendError(this.translate.instant('userEdit.general.role.error') as string);
      }
    );
  }

  loadOffices(): void {
    this.responsabilityService.findAllCenter().subscribe(
      (offices) => {
      this.offices = offices;
      },             
      error => {
        console.error('userEdit.general.office.error', error);
        this.snackBarService.sendError(this.translate.instant('userEdit.general.office.error') as string);
      }
    );
  }

  saveUser(): void {
    this.loader.show();
    this.userService.updateUser(this.user).subscribe(
        () => {
            this.loader.hide();
            this.snackBarService.sendSuccess(this.translate.instant('userEdit.general.edit.success') as string);
            this.router.navigate(['/users/']);
        },
        (error) => {
            this.snackBarService.sendError(this.translate.instant('userEdit.general.edit.error') as string);
            console.error('Error al actualizar el usuario', error);
            this.loader.hide();
        }
    );
}

  cancel(): void {
    void this.router.navigateByUrl(`/users`);
  }
 }