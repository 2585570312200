import { Component, OnInit } from '@angular/core';
import { Test } from 'src/app/model/test';
import { TestType } from '../../../model/test';
import { TranslateService } from '@ngx-translate/core';
import { TestTypeService } from '../../../service/testType.service';
import { TestTypeEquipmentConfigService } from '../../../service/testTypeEquipmentConfig.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TestTypeEquipmentConfig } from '../../../model/testTypeEquipmentConfig';
import { Room } from 'src/app/model/room';
import { Subject, takeUntil } from 'rxjs';
import { ExcelUtils } from '../../../utils/excelUtils';
import { saveAs } from 'file-saver';
import { RoomAirService } from '../../../service/room-air.service';
import { LoadingService } from '../../../service/loading.service';

@Component({
  selector: 'app-quote-edit-dialog-masive-room',
  templateUrl: './quote-edit-dialog-masive-room.component.html'
})
export class MasiveRoomComponent implements OnInit {

  displayedColumns = ['nroom', 'room', 'surface', 'filters', 'point', 'pointmicro', 'diffuser', 'essay'];
  testTypes: TestType[] = [];
  room: Room[] = [];
  calibrationsErrors: any[] = [];

  private destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService,
    private testTypeService: TestTypeService,
    private testTypeEquipmentConfigService: TestTypeEquipmentConfigService,
    private roomAirService: RoomAirService,
    private loader: LoadingService,
    private dialogRef: MatDialogRef<MasiveRoomComponent>) { }

  ngOnInit(): void {
    this.testTypeService.findAll().subscribe((res: TestType[]) => {
      this.testTypes = res;
    });

    this.testTypeEquipmentConfigService.findByRoom(1).subscribe((res1: TestTypeEquipmentConfig[]) => {
      let testsByEquipment =  res1.filter((item) => item.idTestType !== 26);
      testsByEquipment =  testsByEquipment.filter((item) => item.idTestType !== 27);
      testsByEquipment?.forEach(test => {
        this.testTypes.forEach(type => {
          if (test.idTestType === type.id) {
            test.translation = this.getEssayName(type);
          }
        });
      });
    });
  }

  onOkClick() {
    this.dialogRef.close(this.room);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  convertListToString(essays: Test[]) {
    let list: String[] = [];
    essays.forEach(a => {
      const testType = this.testTypes.find(f => f.id === a.idType);
      list.push(this.translateService.instant(this.getEssayName(testType)));
    })
    return list?.join(' | ');
  }

  uploadFile(event): void {
    this.loader.show();

    this.calibrationsErrors = [];
    const file = event.target.files[0] as File;

    void ExcelUtils.excelToRoomAir(file, this.testTypeService, this.translateService, this.testTypeEquipmentConfigService).then(data => {
      this.room = data.get('data');
      this.loader.hide();

      if (data.get('error')) {
        const resultError = data.get('error') as [];

        if (resultError?.length > 0) {
          resultError.forEach(er => {
            this.calibrationsErrors.push(er);
          })
        }
      }
    }).catch(() => {
      this.calibrationsErrors.push('Error al leer el documento');

      this.loader.hide();
    });

  }

  downloadFile() {
    this.loader.show();
    this.roomAirService.getExcelTemplate().pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = 'template-roomAir.xlsx';
      saveAs(res, name);
      this.loader.hide();
    }, error => {
      console.error(error);
      this.loader.hide();
    });
  }

  private getEssayName(type: TestType): string {
    return "testType.".concat(type.translate);
  }

}
