<h1 mat-dialog-title>{{ 'quote.EquipmentThermal.title' | translate }}</h1>
<form fxLayout="column">
    <div mat-dialog-content class="quotit-theme" fxLayout="column">
         <!-- Type select -->
         <div fxFlex="100">
            <div fxFlex="12" fxFlex.lt-md="100" (click)="uploadEquipment.click()">
                <button mat-raised-button color="primary">{{ 'button.upload' | translate  }}</button>
            </div>

            <input accept=".xlsx, .xls, .csv" hidden type="file" (change)="uploadFile($event)"
                #uploadEquipment>
            <div fxFlex="12" fxFlex.lt-md="100">
                <button mat-raised-button (click)="downloadFile()" color="primary">{{ 'button.download-template' | translate  }}</button>
            </div>
         </div>

         <div fxFlex="100" *ngIf="calibrationsErrors?.length>0" class="table-masive-error">
          <div id="error" class="table-masive-error-header">
            <span> {{ 'general.dialog.uloadp.cols.error' | translate }} </span>
          </div>
          <div *ngIf="calibrationsErrors.length <= 15">
            <div class="table-masive-error-body" *ngFor="let calError of calibrationsErrors" >
              <span>{{calError}}</span>
            </div>
          </div>
          <div *ngIf="calibrationsErrors.length > 15" class="table-masive-error-body">
            <span> {{ 'general.dialog.uloadp.cols.maxError' | translate }} </span>
          </div>
        </div>
    
        <div *ngIf="calibrationsErrors?.length > 0" class="table-masive-error-subtitle">
          <span> {{ 'general.dialog.uloadp.cols.remember' | translate }} </span>
        </div>

         <div fxFlex="100">
            <table #protocols mat-table matSort [dataSource]="equipments">

                <!-- COLUMN  -->
                <ng-container id="nequipment" matColumnDef="nequipment">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'equipmentAir.dialog.uloadp.cols.nequipment' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{item.inventoryNumber}} </td>
                </ng-container>

                <!-- COLUMN  -->
                <ng-container id="equipment" matColumnDef="equipment">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'equipmentAir.dialog.uloadp.cols.equipment' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{ 'equipmentType.'+getNameEquipment(item.idType) | translate }} </td>
                </ng-container>
                
                <!-- COLUMN  -->
                <ng-container id="model" matColumnDef="model">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'equipmentAir.dialog.uloadp.cols.model' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{item.model}} </td>
                </ng-container>
        
                 <!-- COLUMN  -->
                 <ng-container id="maker" matColumnDef="maker">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'equipmentAir.dialog.uloadp.cols.maker' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{item.maker}} </td>
                </ng-container>

                 <!-- COLUMN  -->
                <ng-container id="nserie" matColumnDef="nserie">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'equipmentAir.dialog.uloadp.cols.nserie' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> {{item.serialNumber}} </td>
                  </ng-container>

                <!-- COLUMN  -->
                <ng-container id="location" matColumnDef="location">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'equipmentAir.dialog.uloadp.cols.location' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{item.location}} </td>
                </ng-container>
                 
                <!-- COLUMN  -->
                <ng-container id="essay" matColumnDef="essay">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'equipmentAir.dialog.uloadp.cols.essays' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> {{convertListToString(item.tests)}} </td>
                  </ng-container>

                <!-- FIN BOTONERA DE ACCIONES LISTA PAGINADA-->
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
              </table>
         </div>
    </div>

     <!-- Botonera -->
     <div mat-dialog-actions class="thermal-theme" fxLayout="row" fxLayoutAlign="end center">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate
            }}
        </button>
    </div>
</form>

