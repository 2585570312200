
import { ViewportScroller } from "@angular/common";
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router, Scroll } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject, filter, takeUntil } from "rxjs";
import { Equipment, EquipmentTypeFilter } from "src/app/model/equipment";
import { EquipmentTypeDataSource } from "src/app/model/equipmentTypeDataSource";
import { User } from "src/app/model/user";
import { EquipmentTypeService } from "src/app/service/equipmentType.service";
import { UserService } from "src/app/service/user.service";
import { EquipmentTypeFilterComponent } from "./equipmentType-list-filter.component";

export interface DialogequipmentTypeListConfig {
  isList: boolean;
}

@Component({
  selector: 'app-equipmentType-list',
  templateUrl: './equipmentType-list.component.html'
})
export class EquipmentTypeListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('filterComponent', { static: true }) filterComponent: EquipmentTypeFilterComponent;
  currentUser: User;
  dataSource: EquipmentTypeDataSource;
  filter: EquipmentTypeFilter = new EquipmentTypeFilter();
  hasFiltered = false;
  displayedColumns = ['name', 'code'];
  private destroy$ = new Subject<void>();
 
  constructor(
    private router: Router,
    private courseService: EquipmentTypeService,
    private translate: TranslateService,
    private userService: UserService,
    private viewportScroller: ViewportScroller,
    @Inject(MAT_DIALOG_DATA) public data: DialogequipmentTypeListConfig,
    public dialogRef: MatDialogRef<EquipmentTypeListComponent>
  ) {
    this.router.events
      .pipe(filter(e => e instanceof Scroll))
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: Scroll) => {
        setTimeout(() => {
          if (e.position) {
            this.viewportScroller.scrollToPosition(e.position);
          } else if (e.anchor) {
            this.viewportScroller.scrollToAnchor(e.anchor);
          } else {
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        });
      });
  }

  get pageTitle(): string {
    return this.translate.instant('protocolList.title') as string;
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    if (this.data.isList) {
      this.displayedColumns.push('download', 'edit', 'delete');
    } else {
      // this.filterComponent.isList = this.data.isList;
    }
    this.loadCourseList();
  }

  ngAfterViewInit(): void {
    this.loadCourseList();
    
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadCourseList(): void {
    this.dataSource = new EquipmentTypeDataSource(this.courseService);

    

    this.filter.sortBy = this.sort.active || 'translate';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator?.pageIndex || 0;
    this.filter.pageSize = this.paginator?.pageSize || 5;

     
      this.dataSource.loadProtocols(this.filter); 
    
  }
  doFilter(event: EquipmentTypeFilter): void {
    this.filter = event;


    this.loadCourseList();
  }
  
  canDelete(course: Equipment): boolean {
    return true;
  }
 
}