<div class="main-container">
  <div class="toolbar-list">
    <div class="toolbar-list-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <h1 class="accent-color">{{ 'equipmentTypeListt.title' | translate }}</h1>
      </div>
      <div class="breadcrumbs">
        <a href="#">{{ 'common.start.label' | translate }}</a> / <span>{{ 'equipmentTypeListt.title' | translate }}</span>
      </div>
    </div>
  </div>
  
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'equipmentTypeList.cols.name' | translate }} </th>
        <td mat-cell *matCellDef="let item"> {{ item.translate }} </td>
      </ng-container>

      <!-- ID Application Column -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'equipmentTypeList.cols.id' | translate }} </th>
        <td mat-cell *matCellDef="let item"> {{ item.idApplication }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>

  <mat-paginator [pageSize]="5" [pageSizeOptions]="data.isList ? [5, 10, 20] : [5]"
  showFirstLastButtons [length]="dataSource.totalItems$ | async" lang="es">
</mat-paginator>
  
</div>