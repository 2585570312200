<form [formGroup]="form">
    <mat-accordion>
        <mat-expansion-panel [expanded]="expandedFilter" (opened)="expandedFilter = true"
            (closed)="expandedFilter = false">
            <!-- TITULO FILTROS -->
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'clientList.filter.title' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <!-- INI FILTROS -->
            <div id="clientListFilters" fxLayout="row" fxLayout.xs="column" class="flexwrap">

                <!-- FILTRO name -->
                <div id="clientListNameFilter" fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{'clientList.filter.name.label' | translate }}"
                            formControlName="name" [(ngModel)]="filter.name">
                    </mat-form-field>
                </div>
                <!-- FILTRO address -->
                <div id="clientListAddressFilter" fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{'clientList.filter.address.label' | translate }}"
                            formControlName="address" [(ngModel)]="filter.address">
                    </mat-form-field>
                </div>
                <!-- FILTRO postalCode -->
                <div id="clientListPostalCodeFilter" fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{'clientList.filter.postalCode.label' | translate }}"
                            formControlName="postalCode" [(ngModel)]="filter.postalCode">
                    </mat-form-field>
                </div>
                <!-- FILTRO city -->
                <!-- <div id="clientListCityFilter" fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{'clientList.filter.city.label' | translate }}"
                            formControlName="city" [(ngModel)]="filter.city">
                    </mat-form-field>
                </div> -->
                <!-- FILTRO state -->
                <!-- <div id="clientListStateFilter" fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{'clientList.filter.state.label' | translate }}"
                            formControlName="state" [(ngModel)]="filter.state">
                    </mat-form-field>
                </div> -->
                <!-- FILTRO country -->
                <!-- <div id="clientListCountryFilter" fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{'clientList.filter.country.label' | translate }}"
                            formControlName="country" [(ngModel)]="filter.country">
                    </mat-form-field>
                </div> -->

                 <!-- FILTRO city -->
                <div id="clientListCityFilter" fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <mat-select placeholder="{{'clientList.filter.city.label' | translate }}" formControlName="city" [(ngModel)]="filter.city">>
                            <mat-option *ngFor="let city of city" [value]="city">{{ city }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
               <!-- FILTRO state -->
                <div id="clientListStateFilter" fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <mat-select placeholder="{{'clientList.filter.state.label' | translate }}" formControlName="state" [(ngModel)]="filter.state">>
                            <mat-option *ngFor="let state of state" [value]="state">{{ state }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- FILTRO country -->
                <div id="clientListCountryFilter" fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <mat-select placeholder="{{'clientList.filter.country.label' | translate }}" formControlName="country" [(ngModel)]="filter.country">
                            <mat-option *ngFor="let country of countries" [value]="country">{{ country }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <!-- FILTRO nameBusinessGroup -->
                <div id="clientListNameBusinessGroupFilter" fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{'clientList.filter.nameBusinessGroup.label' | translate }}"
                            formControlName="nameBusinessGroup" [(ngModel)]="filter.nameBusinessGroup">
                    </mat-form-field>
                </div>

            </div>
            <!-- FIN FILTROS -->

            <!-- BOTONERA DE ACCIONES-->
            <div id="clientListActionsButtonPanel" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
                <button mat-raised-button (click)="doFilter()" color="primary">{{ 'button.filter' | translate
                    }}</button>
                <button mat-button (click)="cleanFilter()">{{ 'button.clean' | translate }}</button>
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</form>