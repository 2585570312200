import { Component, Inject, OnDestroy } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { GenericClass } from 'src/app/model/genericClass';
import { QuoteAttachment } from 'src/app/model/quote';
import { AttachmentType } from '../../../model/attachment';

export interface DialogDataEditAttachmentEdit {
  attachment: QuoteAttachment;
  types: GenericClass[];
}

@Component({
  selector: 'app-edit-attachment-edit',
  templateUrl: './edit-attachment-edit.component.html'
})
export class EditAttachmentEditComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<EditAttachmentEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEditAttachmentEdit) {
      this.data.types = this.data.types?.filter(e => e.id != AttachmentType.OFFERT_MANUAL_PDF && e.id != AttachmentType.OFFERT_MANUAL_WORD);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onTypeChange(event: MatOptionSelectionChange): void {
    const idType = event.source.value as number;

    const type = this.data.types.find(t => t.id === idType);

    this.data.attachment.idType = type.id;
    this.data.attachment.nameType = type.name;
  }

  onOkClick(): void {
    this.dialogRef.close(this.data.attachment);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
