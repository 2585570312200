import { HoursResponse } from 'src/app/model/HoursResponse';
import { Component, Inject, OnInit, Provider } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";



export interface DialogDataRoleUser {
    response: HoursResponse;
    isEdit: boolean;
}

@Component({
    selector: 'app-confirmationHours-dialog',
    templateUrl: './quote-edit-confirmationHours-dialog.html'
})

export class ConfirmationHoursDialogComponent implements OnInit {
    tituloDialog: string
    hourResponse: HoursResponse = new HoursResponse();

    constructor(
       
        public dialogRef: MatDialogRef<ConfirmationHoursDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogDataRoleUser
    ) { 
        
     }

    ngOnInit(): void {
        this.hourResponse.hours = 0;
        this.hourResponse.ok = false;
    }

    onOkClick(): void {
        if (this.hourResponse.hours > 0) {
            this.hourResponse.ok = true;
        } else {
            this.hourResponse.ok = false;
        }

        this.dialogRef.close(this.hourResponse);
    }

    onNoClick(): void {
        this.dialogRef.close(null);
    }

}