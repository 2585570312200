import { Injectable } from '@angular/core';
import { CompresedGas } from '../model/compresedGas';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompressedGasAirService {

  private roomSubject = new BehaviorSubject<CompresedGas>(new CompresedGas());
  private unreadSubject = new BehaviorSubject<number>(0);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public course$ = this.roomSubject.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public unread$ = this.unreadSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  getExcelTemplate(): Observable<any> {
    const url = environment.quotit + '/compressGas/excel-template';

    return this.http.get(url, { responseType: 'blob' });
  }
}
