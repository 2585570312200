import * as _ from 'lodash-es';
import { saveAs } from 'file-saver';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, forkJoin, merge, takeUntil, tap } from 'rxjs';
import { GenericClass } from 'src/app/model/genericClass';
import { QuoteAttachment } from 'src/app/model/quote';
import { SnackBarService } from 'src/app/service/snackBar.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { EditAttachmentEditComponent } from '../../shared/edit-attachment-edit/edit-attachment-edit.component';
import { QuoteAttachmentFilter } from '../../../model/quote';
import { AttachmentDataSource } from 'src/app/model/attachmentDataSource';
import { QuoteService } from '../../../service/quote.service';
import { AttachmentService } from 'src/app/service/attachment.service';
import { Constants } from 'src/app/utils/constants';
import { LoadingService } from '../../../service/loading.service';

@Component({
  selector: 'app-quote-edit-attachments',
  templateUrl: './quote-edit-attachments.component.html',
  styleUrls: ['./quote-edit-attachments.component.scss']
})
export class QuoteEditAttachmentsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input() isNoScope: boolean = false;

  idStatus: number;

  @ViewChild('attachmentUpload') attachmentUpload: ElementRef;

  dataSource: AttachmentDataSource;
  idQuote: number;

  public displayedColumns = ['name', 'filename', 'date', 'type', 'edit', 'download', 'delete'];
  filter: QuoteAttachmentFilter = new QuoteAttachmentFilter();
  showButtonAdd: boolean;

  private destroy$ = new Subject<void>();

  constructor(
    private quoteService: QuoteService,
    private attachmentService: AttachmentService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    public loader: LoadingService) { }

  ngOnInit(): void {
    this.dataSource = new AttachmentDataSource(this.quoteService);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadAttachments())).pipe(takeUntil(this.destroy$)).subscribe();
  }


  setIdQuote(id: number): void {
    this.idQuote = id;

    this.loadAttachments();
  }

  setStatus(id: number): void {
    this.idStatus = id;

    if (this.idStatus === Constants.QUOTE_ID_STATUS_PREPARATION) {
      this.showButtonAdd = true;
    } else {
      this.showButtonAdd = false;
    }
  }

  uploadAttachment(event): void {
    this.loader.show();
    if (this.idQuote == null) {
      this.snackBarService.sendError(this.translate.instant('quoteEdit.attachments.dialog.form.error.generic') as string);
    }

    const files = Array.from(event.target.files as FileList);

    const promises: Observable<void>[] = [];

    if (!ArrayUtils.isEmpty(files)) {
      this.loader.show();
      files.forEach(file => promises.push(this.attachmentService.uploadAttachmentToQuote(this.idQuote, file) as Observable<void>));

      forkJoin(promises).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.loadAttachments();

        this.loader.hide();

        this.snackBarService.sendSuccess(this.translate.instant('quoteEdit.attachments.dialog.form.upload.ok') as string);
      }, () => {
        this.loader.hide();

        this.snackBarService.sendError(this.translate.instant('quoteEdit.attachments.dialog.form.upload.error.generic') as string);
      });
    }

    this.attachmentUpload.nativeElement.value = '';
  }

  drop(event: CdkDragDrop<QuoteAttachment[]>): void {
    this.quoteService.moveAttachment(this.idQuote, event.previousIndex, event.currentIndex)
      .subscribe(() => this.loadAttachments(), () => this.loadAttachments());
  }

  editAttachment(att: QuoteAttachment): void {
    this.attachmentService.getAllTypesForQuote().pipe(takeUntil(this.destroy$)).subscribe((types: GenericClass[]) => {

      const dialogRef = this.dialog.open(EditAttachmentEditComponent, {
        minWidth: '50%',
        maxHeight: '95vh',
        data: {
          attachment: _.cloneDeep(att),
          types
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: QuoteAttachment) => {
        if (result != null) {

          this.attachmentService.updateAttachmentToQuote(result.idQuote, result.id, result.idType, result.name)
            .pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.snackBarService.sendSuccess(this.translate.instant('quoteEdit.attachments.dialog.form.upload.ok') as string);
              this.loadAttachments();
            }, () => {
              this.snackBarService.sendError(this.translate.instant('quoteEdit.attachments.dialog.form.upload.error.generic') as string);
              this.loadAttachments();
            });
        }
      });
    });
  }

  downloadAttachment(idAtt: number, filename: string, idQuote: number): void {
    this.loader.show();
    this.attachmentService.downloadAttachmentToQuote(idQuote, idAtt).pipe(takeUntil(this.destroy$)).subscribe((item: Blob) => {
      saveAs(item, filename);

      this.loader.hide();
    }, () => {
      this.loader.hide();

      this.snackBarService.sendError(this.translate.instant('quoteEdit.dialog.generateReport.form.error.generic') as string);
    }
    );
  }

  deleteAttachment(idAtt: number, idQuote: number): void {
    this.loader.show();
    this.attachmentService.deleteAttachmentToQuote(idQuote, idAtt).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadAttachments();

      this.loader.hide();

      this.snackBarService.sendSuccess(this.translate.instant('quoteEdit.attachments.dialog.form.delete.ok') as string);
    }, () => {
      this.loader.hide();

      this.snackBarService.sendError(this.translate.instant('quoteEdit.attachments.dialog.form.delete.error.generic') as string);
    });
  }

  private loadAttachments(): void {
    const id = this.idQuote;

    if (id == null) {
      this.dataSource = null;
      return;
    }

    this.filter.sortBy = this.sort.active;
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 5;

    this.dataSource.loadAttachment(id, this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe();
  }

}


