import { BehaviorSubject, Observable } from 'rxjs';

import { EquipmentType, EquipmentTypeFilter } from '../model/equipment';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class EquipmentTypeService {

    private courseSubject = new BehaviorSubject<EquipmentType>(new EquipmentType());
    private unreadSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public course$ = this.courseSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public unread$ = this.unreadSubject.asObservable();

    constructor(private http: HttpClient) {
    }

    findAllByIdAplication(id: number): Observable<any> {
        const url = environment.quotit + `/equipmentType/application/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAll(): Observable<any> {
        const url = environment.quotit + `/equipmentType/`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));

    }
    findAll2(filter: EquipmentTypeFilter): Observable<any> {
        const url = environment.quotit + `/equipmentType/`;
        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findByDescription(name: string): Observable<any> {
        const url = environment.quotit + `/equipmentType/`;
        return this.http.post(url, name).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
