import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Course, CourseFilter } from './course';
import { catchError, finalize } from 'rxjs/operators';

import { CourseService } from '../service/course.service';
import { of } from 'rxjs';

export class CourseDataSource implements DataSource<Course> {

    private coursesSubject = new BehaviorSubject<Course[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: CourseService) { }

    connect(collectionViewer: CollectionViewer): Observable<Course[]> {
        return this.coursesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.coursesSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadProtocols(filter = new CourseFilter()): void {

        this.loadingSubject.next(true);

        this.service.findAllFilter(filter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.coursesSubject.next(data.content as Course[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.coursesSubject.next(data as Course[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.coursesSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }

}
