<div fxFlex>
    <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
        <div fxFlex="30" fxFlex.lt-md="100%" *ngFor="let test of qualificationsConfig; let indexEquipment = index">

            <mat-checkbox (change)="onCheckQualification($event, test)" style="margin-left: 1em;" [value]="test"
            [checked]="test.isChecked">
                <span style="white-space: pre-line;">
                    {{ showQualiName(test.id) | translate }}
                </span>
            </mat-checkbox>

        </div>
    </div>
</div>