import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Area } from 'src/app/model/area';
import { SnackBarService } from 'src/app/service/snackBar.service';


export interface DialogDataArea {
  area:Area;
  isEdit: boolean;
}

@Component({
  selector: 'app-quote-edit-dialog-area',
  templateUrl: './quote-edit-dialog-area.component.html'
})
export class QuoteEditDialogAreaComponent {

  tituloDialog: string;

  constructor(
    public snackBarService: SnackBarService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<QuoteEditDialogAreaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataArea) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.area.title.edit', { name: data.area.name }) as string;
    } else {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.area.title.create') as string;
    }


  }


  onOkClick(): void {
    const errs: string[] = [];

    if (this.data.area.name == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.area);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
