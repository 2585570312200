import { Observable, from, of } from 'rxjs';

import { AttachmentType } from '../model/attachment';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AttachmentService {
    private baseUrl = `${environment.quotit}/attachment`

    constructor(private http: HttpClient) {
    }

    uploadGeneric(file: File, idType: number = AttachmentType.OTHERS): Observable<any> {
        const url = this.baseUrl + '/';
        const form = new FormData();
        form.append('file', file, file.name);
        form.append('idType', idType.toString());

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadAttachmentToQuote(idQuote: number, file: File): Observable<any> {
        const url = this.baseUrl + `/quote/${idQuote}/attachment/`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadAttachmentToQuote(idQuote: number, idAttachment: number): Observable<any> {
        const url = this.baseUrl + `/quote/${idQuote}/attachment/${idAttachment}`;

        return this.http.get(url, { responseType: 'blob' });
    }

    deleteAttachmentToQuote(idQuote: number, idAttachment: number): Observable<any> {
        const url = this.baseUrl + `/quote/${idQuote}/attachment/${idAttachment}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAllTypesForQuote(): Observable<any> {
        const url = this.baseUrl + '/types';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    updateAttachmentToQuote(idQuote: number, idAttachment: number, idType: number, name: string): Observable<any> {
        const url = this.baseUrl + `/quote/attachment/${idAttachment}`;

        const body = {
            idQuote,
            idAttachment,
            idType, 
            name
        };

        return this.http.post(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadAttachmentToQuoteWithTypeFile(idQuote: number, file: File, idType: number): Observable<any> {
        const url = this.baseUrl + `/quote/${idQuote}/type/${idType}/attachment`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
