import { GenericFilter } from "./genericClass";

export class Client {

    id: number;
    name: string;
    address: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
    nameBusinessGroup: string;
    idQuote: number;
    cif: string;
    active: boolean;
    special: boolean;
    idBusinessGroup: number;
    idPaymentMethod: number;
    contactName : string;
    phone: number;
    reponsabilityCenter: string;
    email :string;
    workAddress: string;
    clientContact: ClientContact[] = [];
}

export class ClientContact {

    id: number;

    fullName: string;
    position: string;
    email: string;
    phone: string;

    idClient: number;
    clientName: string;

}

export class ClientFilter extends GenericFilter {

    name: string;
    address: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
    nameBusinessGroup: string;
}
