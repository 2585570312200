import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SpinnerService {

    private messageSubject = new BehaviorSubject<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public message$ = this.messageSubject.asObservable();

    show(): void {
        this.messageSubject.next(true);
    }

    hide(): void {
        this.messageSubject.next(false);
    }

    next(show: boolean): void {
        if (show === true) {
            this.show();
        } else {
            this.hide();
        }
    }

}
