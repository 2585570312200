import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { ComercialFilter, commercial } from './commercial';
import { CommercialService } from '../service/commercial.service';

export class ComercialDataSource implements DataSource<commercial> {

    private quotesSubject = new BehaviorSubject<commercial[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: CommercialService) { }

    connect(_collectionViewer: CollectionViewer): Observable<commercial[]> {
        return this.quotesSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.quotesSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadComercials(filter = new ComercialFilter()): void {

        this.loadingSubject.next(true);

        this.service.findAllCommercialFilter(filter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.quotesSubject.next(data.content as commercial[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.quotesSubject.next(data as commercial[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.quotesSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }

}
