<div fxLayout="column">
    <h1 class="title_noscope">{{ 'quoteEdit.noScope.offert_manual.title' | translate }}</h1>
    <span>{{ 'quoteEdit.noScope.offert_manual.subtitle' | translate }}</span>
    <br />
    <div fxLayout="row" >
        <button  mat-raised-button color="primary"  (click)="attachmentUploadPdf.click()" [disabled]="isCheckPdf">{{ 'PDF' }}</button>

        <input hidden type="file" multiple (change)="uploadAttachment($event, 1)" #attachmentUploadPdf accept="application/pdf">

        <div fxFlex="15px"></div>
        <button mat-raised-button color="primary" (click)="attachmentUploadWord.click()" [disabled]="isCheckWord">{{ 'WORD' }}</button>

        <input hidden type="file" multiple (change)="uploadAttachment($event, 2)" #attachmentUploadWord  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
        <div fxFlex="15px"></div>
    </div>
</div>
<br />
<mat-card class="card">
    <mat-card-content>
        <table mat-table matSort [dataSource]="dataSource" style="width: 100%;">
    
            <ng-container id="filename" matColumnDef="filename">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>  {{ 'quoteEdit.attachments.cols.filename' | translate }} </th>
                <td mat-cell *matCellDef="let item"> {{item?.file.name}} </td>
            </ng-container>
           
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                <td mat-cell *matCellDef="let item">
                 <button mat-icon-button (click)="deleteDoc(item.type)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
        </table>
  

    </mat-card-content>

      <!-- Botonera -->
      <div mat-dialog-actions class="thermal-theme" fxLayout="row" fxLayoutAlign="end center">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
      </div>
  
  </mat-card>