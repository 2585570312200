import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Quote } from 'src/app/model/quote';

export interface DialogDataEquipmentThermal {
  quote : Quote;
}

@Component({
  selector: 'app-quote-edit-dialog-thermal-intervention',
  templateUrl: './quote-edit-dialog-thermal-intervention.component.html'
})
export class QuoteEditDialogThermalInterventionComponent implements OnInit {

  message: string = this.translate.instant('quoteEdit.dialog.equipmentThermal.intervetion.message') as string;
  messageMax: string = this.translate.instant('quoteEdit.dialog.equipmentThermal.intervetion.max.message') as string;
  showEquipments: boolean;
  showMaxEquipments: boolean;
  showSave: boolean;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<QuoteEditDialogThermalInterventionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipmentThermal,
  ) { }

  ngOnInit(): void {
    this.showSave = false;
  }

  onOkClick(): void {
    this.data.quote.workcenters.forEach(wc => wc.equipmentThermal.forEach(e => e.intervention = 1));
    if(!this.checkMaxEquipments()) {
      this.dialogRef.close(this.data.quote.workcenters);
    } else {
      this.showMaxEquipments = true;
    }
  }

  onSaveClick(): void {
    if(!this.checkMaxEquipments()) {
      this.dialogRef.close(this.data.quote.workcenters);
    } else {
      this.showMaxEquipments = true;
    }
  }

  onSaveMaxClick(): void {
      this.dialogRef.close(this.data.quote.workcenters);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.showEquipments = true;
    this.showSave = true;
    this.message = this.translate.instant('quoteEdit.dialog.equipmentThermal.intervetion.message.select') as string;
  }

  private checkMaxEquipments(): boolean {
    var hasMore = false;
    this.data.quote.workcenters.forEach( wc => {
     var max = Math.max.apply(Math, wc.equipmentThermal.map(eqT =>  eqT.intervention));
      for(let i = 0; i <= max; i++) {
        if (wc.equipmentThermal.filter(eq => eq.intervention === max).length > 5) {
          hasMore = true;
          this.showSave = true;
          this.message = this.translate.instant('quoteEdit.dialog.equipmentThermal.intervetion.max.message') as string;
        }
      }
    });
    return hasMore;
  }
}
