export class FileInfo {
    id: number;
    filename: string;
    contentType: string;
    content: any;
    contentString: any;
}

export enum AttachmentType {
    OTHERS = 99,
    OFFERT_MANUAL_PDF= 1,
    OFFERT_MANUAL_WORD= 2
}
