export class StringUtils {

  static isEmpty(str: string): boolean {
    return str == null || str.toString().trim() === '' || str.toString().trim() === 'null' || str.toString().trim() === 'undefined';
  }

  static isNotEmpty(str: string): boolean {
    return !this.isEmpty(str);
  }

  static getInitials(str: string): string {
    if (this.isEmpty(str)) {
      return '';
    }

    const split = str.trim().split(' ');
    let res = '';

    for (const word of split) {
      const letter = word[0];


      if (letter !== letter?.toLowerCase()) {
        res = res?.concat(letter);
      }
    }

    return res?.toUpperCase();
  }
  static capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

}
